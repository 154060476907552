import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { BsCart4 } from 'react-icons/bs';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import {
  CreditCardPaymentMethod,
  PaymentSlipMethod,
  PixIcon,
  PixPaymentMethod,
  Success,
} from '../..';

import { Container, FielButtons } from './styles';

export default function DialogShoppingCartPayment(props) {
  const {
    credits,
    selectedProjects,
    projectsAwaitingPayment,
    totalProjectPrice,
  } = props.data;
  const [open, setOpen] = useState(true);
  const [controlColorIcon, setControlColorIcon] = useState('#000');
  const [selectedMethod, setSelectedMethod] = useState('credit_card');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInitiated, setPaymentInitiated] = useState(false);
  const [installments, setInstallments] = useState('1');

  // controle para boleto
  const [paymentBoleto, setPaymentBoleto] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.setClose(), 100);
  };

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  function additionalPayment(final_price, payment_type, installments) {
    let flat_fee = 0;
    let additional_interest = 0;

    if (payment_type === 'payment_slip') flat_fee = 3.49;

    if (payment_type === 'credit_card') {
      switch (installments) {
        case '1':
          flat_fee = 0.99;
          additional_interest = 4.79;
          break;
        case '2':
          flat_fee = 0.99;
          additional_interest = 7.31;
          break;
        case '3':
          flat_fee = 0.99;
          additional_interest = 8.57;
          break;
        case '4':
          flat_fee = 0.99;
          additional_interest = 9.83;
          break;
        case '5':
          flat_fee = 0.99;
          additional_interest = 11.09;
          break;
        case '6':
          flat_fee = 0.99;
          additional_interest = 12.35;
          break;
        case '7':
          flat_fee = 0.99;
          additional_interest = 13.61;
          break;
        case '8':
          flat_fee = 0.99;
          additional_interest = 14.87;
          break;
        case '9':
          flat_fee = 0.99;
          additional_interest = 16.13;
          break;
        case '10':
          flat_fee = 0.99;
          additional_interest = 17.39;
          break;
        case '11':
          flat_fee = 0.99;
          additional_interest = 18.65;
          break;
        case '12':
          flat_fee = 0.99;
          additional_interest = 19.91;
          break;
        default:
          return '(Erro)';
      }
    }

    const final_price_transaction =
      final_price + flat_fee + final_price * (additional_interest / 100);
    const final_increment =
      flat_fee + final_price * (additional_interest / 100);

    const price_divider =
      final_price_transaction /
      (selectedMethod === 'credit_card' ? installments : 1);

    return [currency(price_divider), currency(final_increment)];
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>
                  <BsCart4 /> Método de pagamento
                </h1>
                <span>
                  Verifique atentamente seus projetos selecionados, o valor
                  total e escolha o método de pagamento desejado.
                </span>
                {!paymentSuccess ? (
                  <content>
                    <h4>Prosseguir com pagamento</h4>
                    <div className="div__oneRow">
                      <div style={{ flex: 1.25, flexDirection: 'column' }}>
                        <small>
                          Selecione abaixo: <br /> <br />
                        </small>
                        <div className="div__paymentMethodBody">
                          <div className="div__methodPaymentSelect">
                            <button
                              className={
                                selectedMethod === 'credit_card' && 'selected'
                              }
                              id="creditcardpayment"
                              onClick={() => {
                                setSelectedMethod('credit_card');
                                setControlColorIcon('#000');
                                setTimeout(
                                  () =>
                                    (window.location = '#creditcardpayment'),
                                  1
                                );
                              }}
                              disabled={paymentInitiated}
                            >
                              <CreditCardIcon /> Cartão de crédito
                            </button>
                            <button
                              id="slippayment"
                              onClick={() => {
                                setInstallments('1');
                                setSelectedMethod('payment_slip');
                                setControlColorIcon('#000');
                                setTimeout(
                                  () => (window.location = '#slippayment'),
                                  1
                                );
                              }}
                              className={
                                selectedMethod === 'payment_slip' && 'selected'
                              }
                              disabled={paymentInitiated}
                            >
                              {' '}
                              <div>
                                <HorizontalSplitIcon className="icon_boleto" />
                                <HorizontalSplitIcon className="icon_boleto2" />
                              </div>
                              Boleto
                            </button>
                            <button
                              id="pixpayment"
                              onMouseMove={() =>
                                selectedMethod === 'pix'
                                  ? setControlColorIcon('#fff')
                                  : setControlColorIcon('var(--primary2)')
                              }
                              onMouseOut={() =>
                                selectedMethod === 'pix'
                                  ? setControlColorIcon('#fff')
                                  : setControlColorIcon('#000')
                              }
                              onClick={() => {
                                setInstallments('1');
                                setSelectedMethod('pix');
                                setControlColorIcon('#fff');
                                setTimeout(
                                  () => (window.location = '#pixpayment'),
                                  1
                                );
                              }}
                              className={selectedMethod === 'pix' && 'selected'}
                              disabled={paymentInitiated}
                            >
                              <PixIcon color={controlColorIcon} /> PIX
                            </button>
                          </div>
                          <div className="div__payment_body_in">
                            {selectedMethod === 'credit_card' && (
                              <CreditCardPaymentMethod
                                selectedProjects={selectedProjects}
                                setPaymentSuccess={setPaymentSuccess}
                                credits={credits}
                                setPaymentInitiated={setPaymentInitiated}
                                setInstallments={setInstallments}
                              />
                            )}
                            {selectedMethod === 'payment_slip' && (
                              <PaymentSlipMethod
                                selectedProjects={selectedProjects}
                                credits={credits}
                                setPaymentInitiated={setPaymentInitiated}
                                setPaymentBoleto={setPaymentBoleto}
                              />
                            )}
                            {selectedMethod === 'pix' && (
                              <PixPaymentMethod
                                selectedProjects={selectedProjects}
                                setPaymentSuccess={setPaymentSuccess}
                                credits={credits}
                                setPaymentInitiated={setPaymentInitiated}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          flexDirection: 'column',
                          padding: '0.5rem 1.25rem',
                          marginTop: '4rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="div__oneColumn">
                          <div
                            className="div__table_price"
                            style={{ marginTop: '1rem' }}
                          >
                            <b>Resumo da compra</b>
                            <b>Preço</b>
                          </div>
                          {selectedProjects.length === 0 ? (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <small>Selecione o(s) projeto(s)</small>
                            </div>
                          ) : (
                            selectedProjects.map((id_project) => (
                              <div className="div__table_price">
                                <span>
                                  - Projeto fotovoltáico{' '}
                                  {
                                    projectsAwaitingPayment.find(
                                      (proj) => proj.id === id_project
                                    ).project.total_project_power
                                  }{' '}
                                  kWp
                                </span>
                                <span>
                                  {currency(
                                    projectsAwaitingPayment.find(
                                      (proj) => proj.id === id_project
                                    ).original_price
                                  )}
                                </span>
                              </div>
                            ))
                          )}

                          <div className="div__table_price">
                            <span>- Adicional método de pagamento: </span>
                            <span>
                              {
                                additionalPayment(
                                  totalProjectPrice -
                                    (credits?.show === false
                                      ? parseFloat(credits.value)
                                      : 0),
                                  selectedMethod,
                                  installments
                                )[1]
                              }
                            </span>
                          </div>

                          <div className="div__table_price">
                            <div className="div__table_sub_total">
                              Total{' '}
                              {selectedMethod === 'credit_card'
                                ? installments
                                : 1}
                              x{' '}
                              <b>
                                {
                                  additionalPayment(
                                    totalProjectPrice -
                                      (credits?.show === false
                                        ? parseFloat(credits.value)
                                        : 0),
                                    selectedMethod,
                                    installments
                                  )[0]
                                }
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </content>
                ) : (
                  <content className="content__success">
                    <Success
                      animationUrl="./assets/animations/success.json"
                      width={'200px'}
                      height={'auto'}
                      position=""
                    />
                    <h2>Pagamento confirmado com sucesso!</h2>
                  </content>
                )}
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            {!paymentSuccess && !paymentBoleto && (
              <button
                onClick={handleClose}
                className="btn__retornar"
                color="primary"
                disabled={paymentInitiated}
                style={{
                  pointerEvents: paymentInitiated && 'none',
                }}
              >
                Voltar página
              </button>
            )}
            {paymentBoleto && (
              <button
                onClick={() => (window.location = '/comprovantes')}
                className="btn__retornar"
                color="primary"
              >
                Comprovantes
              </button>
            )}
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
