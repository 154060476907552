import React from 'react';
import { mask } from 'remask';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Imgtrocarsenha from '../../../assets/images/icons/trocar_senha.png';
import { Container, FielButtons, Conteudo } from './styles';

export default function DialogNewUser(props) {
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(props.dados ? props.dados : {});
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const handleChange = (e) => {
    if (e.target.name === 'number') {
      setDados({ ...dados, [e.target.name]: mask(e.target.value, '9999999') });
    } else if (e.target.name === 'cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'landline') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 9999-9999'),
      });
    } else if (e.target.name === 'telephone') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 99999-9999'),
      });
    } else if (e.target.name === 'cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-­99'),
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999999999999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>{props.edit ? 'Editar' : 'Novo'} Usuário</h1>
                <span>
                  Preencha corretamente os campos abaixo para
                  {props.edit
                    ? 'editar os dados do usuário'
                    : 'adicionar um novo usuário'}
                  .
                </span>
                <content>
                  <h4>Dados pessoais e acesso</h4>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="name"
                      value={dados.name}
                      placeholder="Nome completo"
                      style={{
                        flex: 2,
                      }}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="cpf"
                      value={dados.cpf}
                      placeholder="CPF"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="role"
                      value={dados.role}
                      placeholder="Cargo"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="telephone"
                      value={dados.telephone}
                      placeholder="Celular/Whatsapp"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChange}
                    />

                    <input
                      type="text"
                      name="email"
                      value={dados.email}
                      placeholder="E-mail"
                      style={{
                        flex: 2,
                      }}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {!props.edit && (
                    <Conteudo>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 3,
                        }}
                      >
                        <img src={Imgtrocarsenha} alt='Trocar senha' />
                        <div className="div__one_row">
                          <input
                            autoFocus
                            type={show ? 'text' : 'password'}
                            placeholder="Digite a nova senha"
                            value={dados.password}
                            onChange={(a) =>
                              setDados({ ...dados, password: a.target.value })
                            }
                          />{' '}
                          <button onClick={() => setShow(!show)}>
                            {!show ? (
                              <VisibilityOffIcon />
                            ) : (
                              <RemoveRedEyeIcon />
                            )}
                          </button>
                        </div>
                        <div className="div__one_row">
                          <input
                            type="password"
                            placeholder="Confirme a senha"
                            value={dados.re_password}
                            onChange={(a) =>
                              setDados({
                                ...dados,
                                re_password: a.target.value,
                              })
                            }
                          />{' '}
                        </div>
                      </div>
                      <ul>
                        <li>Senha deve conter no mínimo 6 caracteres</li>
                        <li>
                          Para uma senha forte, é aconselhável que contenha
                          números, letras e caracteres especiais
                        </li>
                        <li>
                          Sua senha e credenciais de acesso são de uso pessoal,
                          evite informar a terceiros
                        </li>
                      </ul>
                    </Conteudo>
                  )}
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button onClick={handleClose} className="btn__register">
              {props.edit ? 'Aplicar alterações' : 'Cadastrar novo usuário'}
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
