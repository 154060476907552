import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mask } from "remask";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import api from "../../../services/api";
import apiCEP from "../../../services/apiCEP";
import apiCNPJ from "../../../services/apiCNPJ";
import { Container, FielButtons } from "./styles";
import { useAuth } from "../../../contexts/AuthContext";

export default function DialogChangeData(props) {
  const { user, refreshUser } = useAuth();

  const company = user?.company;
  const userAddress = user?.address;
  const phoneNumbers = user?.phone_numbers;
  const companyAddress = user?.company?.address;

  const [open, setOpen] = React.useState(true);

  const [userData, setUserData] = useState({
    name: user?.name,
    rg: user?.rg,
  });
  const [userAddressData, setUserAddressData] = useState(
    userAddress
      ? { ...userAddress }
      : {
          street: undefined,
          number: undefined,
          district: undefined,
          city: undefined,
          state: undefined,
          zip_code: undefined,
          complement: undefined,
        },
  );
  const [companyData, setCompanyData] = useState({
    fantasy_name: company?.fantasy_name,
    social_reason: company?.social_reason,
    cnpj: company?.cnpj,
    municipal_registration: company?.municipal_registration,
    state_registration: company?.state_registration,
  });
  const [companyAddressData, setCompanyAddressData] = useState(
    companyAddress
      ? { ...companyAddress }
      : {
          street: undefined,
          number: undefined,
          district: undefined,
          city: undefined,
          state: undefined,
          zip_code: undefined,
          complement: undefined,
        },
  );
  const [phoneNumbersData, setPhoneNumbersData] = useState([...phoneNumbers]);

  const handleChangeUser = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeUserAddress = (e) => {
    if ([e.target.name][0] === "zip_code") {
      setUserAddressData({
        ...userAddressData,
        zip_code: mask(e.target.value, "99999-999"),
      });
    } else {
      setUserAddressData({
        ...userAddressData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeCompany = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCompanyAddress = (e) => {
    if ([e.target.name][0] === "zip_code") {
      setCompanyAddressData({
        ...companyAddressData,
        zip_code: mask(e.target.value, "99999-999"),
      });
    } else {
      setCompanyAddressData({
        ...companyAddressData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getZipCode = async (e, type) => {
    if (e.target.value.length <= 8) return;

    await apiCEP
      .get(`/${e.target.value.replace(/\D/g, "")}/json`)
      .then((res) => {
        if (type === "company") {
          setCompanyAddressData({
            ...companyAddressData,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        } else {
          setUserAddressData({
            ...userAddressData,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        if (type === "company") {
          setCompanyAddressData({
            ...companyAddressData,
            street: "",
            district: "",
            city: "",
            state: "",
            complement: "",
          });
        } else {
          setUserAddressData({
            ...userAddressData,
            street: "",
            district: "",
            city: "",
            state: "",
            complement: "",
          });
        }
        return toast.warn("CEP inválido!");
      });
  };

  const getCnpjData = async (e) => {
    let aux = window
      .encodeURIComponent(
        e.target.value
          .replace(".", "")
          .replace(".", "")
          .replace("/", "")
          .replace("-", ""),
      )
      .replace("%C2%AD", "");

    if (aux.length > 13) {
      await apiCNPJ
        .get(aux)
        .then((response) => {
          setCompanyData({
            ...companyData,
            fantasy_name: response.data.fantasia,
            social_reason: response.data.nome,
          });

          setCompanyAddressData({
            ...companyAddressData,
            street: response.data.logradouro,
            number: response.data.numero,
            district: response.data.bairro,
            city: response.data.municipio,
            state: response.data.uf,
            zip_code: response.data.cep,
            complement: response.data.complemento,
          });
        })
        .catch((error) => {
          console.error("Erro: ", error);
          toast.error(
            "Falha ao validar CNPJ. Mas você pode preencher os dados abaixo normalmente",
          );
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const onSubmitUpdateData = async (event) => {
    event.preventDefault();

    // TODO: REFACTOR REQUEST
    await api.put("/users/profile", userData).catch((error) => {
      return toast.warn(error.response.data.error);
    });

    await api
      .put(`/companies/${user.company_id}`, companyData)
      .catch((error) => {
        return toast.warn(error.response.data.error);
      });

    if (userAddressData.zip_code) {
      if (user.address_id) {
        await api
          .put(`/addresses/${user.address_id}`, userAddressData)
          .catch((error) => {
            return toast.warn(error.response.data.error);
          });
      } else {
        await api.post(`/addresses/of-user`, userAddressData).catch((error) => {
          return toast.warn(error.response.data.error);
        });
      }
    }

    if (companyAddressData?.zip_code) {
      if (company.address_id) {
        await api
          .put(`/addresses/${company.address_id}`, companyAddressData)
          .catch((error) => {
            return toast.warn(error.response.data.error);
          });
      } else {
        await api
          .post(`/addresses/of-company/${user.company_id}`, companyAddressData)
          .catch((error) => {
            return toast.warn(error.response.data.error);
          });
      }
    }

    if (phoneNumbersData.length > 0) {
      if (user?.phone_numbers?.length > 0) {
        await api
          .put(`/numbers/${user.phone_numbers[0]?.id}`, phoneNumbersData[0])
          .catch((error) => {
            return toast.warn(error.response.data.error);
          });
      } else {
        await api.post(`/numbers/`, phoneNumbersData[0]).catch((error) => {
          return toast.warn(error.response.data.error);
        });
      }
    }

    props.fechar();
    await refreshUser();
  };

  const handleChangeNumbersContact = (e, index) => {
    let newNumber = phoneNumbersData[index];

    let newNumbers = phoneNumbersData.map((number, i) => {
      if (index === i) {
        if ([e.target.name][0] === "number") {
          return {
            ...newNumber,
            number: mask(e.target.value, ["(99) 9999-9999", "(99) 99999-9999"]),
          };
        } else if ([e.target.name][0] === "is_whatsapp") {
          return {
            ...newNumber,
            is_whatsapp: !number.is_whatsapp,
          };
        } else {
          return {
            ...newNumber,
            [e.target.name]: e.target.value,
          };
        }
      } else {
        return number;
      }
    });

    setPhoneNumbersData([...newNumbers]);
  };

  useEffect(() => {
    if (phoneNumbersData.length < 1) {
      const newNumbers = [...phoneNumbersData];

      let newNumber = {
        type_number: "fixo",
        number: "",
        is_whatsapp: false,
      };
      newNumbers.push(newNumber);

      setPhoneNumbersData(newNumbers);
    }
  }, [phoneNumbersData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        scroll='body'
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999999999999,
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <form onSubmit={onSubmitUpdateData}>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Container>
                <header>
                  <h1>Alterar dados</h1>
                  <span>
                    Preencha corretamente os campos abaixo para alterar suas
                    informações.
                  </span>
                  <content>
                    <h4>Dados pessoais*</h4>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='name'
                        value={userData?.name}
                        placeholder='Nome completo*'
                        style={{
                          flex: 2,
                        }}
                        onChange={handleChangeUser}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='cpf'
                        value={user?.cpf}
                        placeholder='CPF*'
                        style={{ flex: 1 }}
                        disabled
                        // onChange={handleChangeUser}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='rg'
                        value={userData?.rg}
                        placeholder='RG'
                        style={{ flex: 1 }}
                        onChange={handleChangeUser}
                      />
                    </div>

                    <h4>Dados de contatos</h4>
                    <div className='div__oneRow'>
                      <table className='tableContact'>
                        <thead>
                          <tr>
                            <th className='title_type'>Tipo de Número*</th>
                            <th className='title_number'>Número*</th>
                            <th className='title_wpp'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {phoneNumbersData.length > 0 &&
                            phoneNumbersData.map((number, index) => (
                              <tr>
                                <td className='item_type'>
                                  <select
                                    className='input-custom-style'
                                    required
                                    name='type_number'
                                    value={number.type_number}
                                    onChange={(e) =>
                                      handleChangeNumbersContact(e, index)
                                    }
                                  >
                                    <option disabled selected>
                                      Tipo de Número*
                                    </option>
                                    <option value='fixo'>Fixo</option>
                                    <option value='celular'>Celular</option>
                                  </select>
                                </td>
                                <td className='item_number'>
                                  <input
                                    className='input-custom-style'
                                    type='text'
                                    name='number'
                                    value={number.number}
                                    placeholder='Número'
                                    onChange={(e) =>
                                      handleChangeNumbersContact(e, index)
                                    }
                                    required
                                  />
                                </td>
                                <td className='item_wpp'>
                                  <input
                                    type='checkbox'
                                    name='is_whatsapp'
                                    value={number.is_whatsapp}
                                    checked={number.is_whatsapp}
                                    onChange={(e) =>
                                      handleChangeNumbersContact(e, index)
                                    }
                                  />{" "}
                                  É whatsapp
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <h4>Endereço*</h4>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='zip_code'
                        value={userAddressData?.zip_code}
                        placeholder='CEP*'
                        style={{
                          flex: 2,
                        }}
                        onChange={handleChangeUserAddress}
                        onKeyUp={(e) => getZipCode(e, "person")}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='state'
                        value={userAddressData?.state}
                        placeholder='Estado*'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeUserAddress}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='city'
                        value={userAddressData?.city}
                        placeholder='Cidade*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeUserAddress}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='street'
                        value={userAddressData?.street}
                        placeholder='Rua*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeUserAddress}
                        required
                      />
                    </div>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='number'
                        value={userAddressData?.number}
                        placeholder='Número*'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeUserAddress}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='district'
                        value={userAddressData?.district}
                        placeholder='Bairro*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeUserAddress}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='complement'
                        value={userAddressData?.complement}
                        placeholder='Complemento'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeUserAddress}
                      />
                    </div>
                    <div className='div__oneRow'></div>

                    <h4>Dados da empresa*</h4>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='cnpj'
                        value={companyData?.cnpj}
                        placeholder='CNPJ'
                        style={{
                          flex: 1,
                          maxWidth: window.innerWidth > 675 && "280px",
                        }}
                        onChange={handleChangeCompany}
                        onKeyUp={getCnpjData}
                      />
                    </div>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='fantasy_name'
                        value={companyData?.fantasy_name}
                        placeholder='Nome Fantasia*'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeCompany}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='social_reason'
                        value={companyData?.social_reason}
                        placeholder='Razão Social'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeCompany}
                      />
                    </div>
                    <div className='div__oneRow'></div>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='state_registration'
                        value={companyData?.state_registration}
                        placeholder='Registro estadual'
                        style={{
                          flex: 1,
                          maxWidth: window.innerWidth > 675 && "280px",
                        }}
                        onChange={handleChangeCompany}
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='municipal_registration'
                        value={companyData?.municipal_registration}
                        placeholder='Registro municipal'
                        style={{
                          flex: 1,
                          maxWidth: window.innerWidth > 675 && "280px",
                        }}
                        onChange={handleChangeCompany}
                      />
                    </div>

                    <h4>Endereço da empresa*</h4>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='zip_code'
                        value={companyAddressData?.zip_code}
                        placeholder='CEP*'
                        style={{
                          flex: 2,
                        }}
                        onChange={handleChangeCompanyAddress}
                        onKeyUp={(e) => getZipCode(e, "company")}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='state'
                        value={companyAddressData?.state}
                        placeholder='Estado*'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeCompanyAddress}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='city'
                        value={companyAddressData?.city}
                        placeholder='Cidade*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeCompanyAddress}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='street'
                        value={companyAddressData?.street}
                        placeholder='Rua*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeCompanyAddress}
                        required
                      />
                    </div>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='number'
                        value={companyAddressData?.number}
                        placeholder='Número*'
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChangeCompanyAddress}
                        required
                      />
                      <input
                        className='input-custom-style'
                        type='text'
                        name='district'
                        value={companyAddressData?.district}
                        placeholder='Bairro*'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeCompanyAddress}
                        required
                      />

                      <input
                        className='input-custom-style'
                        type='text'
                        name='complement'
                        value={companyAddressData?.complement}
                        placeholder='Complemento'
                        style={{
                          flex: 4,
                        }}
                        onChange={handleChangeCompanyAddress}
                      />
                    </div>

                    <h4>Meu acesso na plataforma:</h4>
                    <div className='div__oneRow'>
                      <input
                        className='input-custom-style'
                        type='text'
                        name='role'
                        disabled
                        value={user?.role}
                        placeholder='Cargo'
                        style={{
                          flex: 1,
                          maxWidth: window.innerWidth > 675 && "280px",
                        }}
                        onChange={handleChangeUser}
                        required
                      />
                    </div>
                  </content>
                </header>
              </Container>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <FielButtons>
              <button
                onClick={handleClose}
                className='secondary-button-style'
                color='primary'
              >
                Cancelar
              </button>
              <button className='primary-button-style' type='submit'>
                Aplicar alterações
              </button>
            </FielButtons>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
