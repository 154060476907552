import styled from 'styled-components';
import tagBack from '../../assets/images/icons/desconto_acumulado_icon.svg';

export const Conteudo = styled.div`
  /* background-color: red !important; */
`;

export const Divdesconto = styled.div`
  background-image: url(${tagBack});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 90px;
  display: flex;
  flex-direction: column;
  margin: 0rem;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin: auto;
  small {
    margin-bottom: -0.4rem;
  }
`;
