import { useEffect, useRef, useState } from 'react';

export default function Success(props) {
  const ref = useRef(null);
  const [lottie, setLottie] = useState(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: props.animationUrl,
      });

      return () => animation.destroy();
    }
  }, [lottie]); // eslint-disable-line

  return (
    <div
      ref={ref}
      style={{
        width: props.width,
        height: props.height,
        position: props.position,
        zIndex: 30,
      }}
    />
  );
}
