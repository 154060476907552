import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  background-size: cover;
  background-position: top center;

  .div__gradient {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background-image: linear-gradient(
      to right bottom,
      rgba(var(--primary2_rgb), 0.9) 40%,
      rgba(var(--primary2_rgb), 0.6)
    );
    width: 100%;
    height: 100%;
  }

  .div__corpo {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    max-width: 1920px;
    margin: auto;

    .div__part1 {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: sticky !important;
      top: 0;
      /* background: red; */

      h3 {
        color: var(--white);
        width: min(100%, 450px);
        font-weight: 600;
        font-size: 2rem;
      }
      img {
        width: 100%;
        max-width: 450px;
        margin: 1rem auto;
      }
      p {
        color: var(--white);
        max-width: 450px;
        padding: 1rem;
      }
    }
    .div__part2 {
      flex: 3;
      /* background-color: var(--gray); */
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    form {
      background-color: var(--white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      width: 100%;
      min-height: 50%;

      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.1);
      padding: 2rem 1rem;
      margin: 2rem 0rem;

      .img__logo_mobile {
        display: none;
      }

      .div__etapas {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 130px;

        span {
          display: flex;
          background: red;
          width: 1.5rem;
          height: 1.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
        }

        .etp_fut {
          background: rgba(var(--black_rgb), 0.1);
          color: rgba(var(--black_rgb), 0.5);
        }
        .etp_pass {
          background: var(--primary2);
          color: var(--white);
        }
        .etp_atual {
          border: 1px solid var(--primary2);
          color: var(--primary2);
          background: transparent;
          margin-top: -1px;
        }
      }

      h1 {
        /* text-transform: uppercase; */
        font-weight: bold;
        margin: 0rem;
        color: var(--primary2);
      }
      span {
        font-size: 0.8rem;
        max-width: 280px;
        color: rgba(var(--black_rgb), 0.7);
        margin-bottom: 1rem;
        margin-top: 0.5rem;
      }

      .div__functions {
        display: flex;
        width: 100%;
        max-width: 320px;
        justify-content: space-between;

        label,
        a {
          display: flex;
          font-size: 0.8rem;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);

          input {
            width: 15px;
            margin: 0rem;
            margin-right: 0.2rem;
          }
        }

        a {
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }

      .btn__login {
        font-family: "Poppins", sans-serif;
        text-transform: uppercase;
        width: 100%;
        max-width: 320px;
        height: 45px;
        background-color: var(--primary2);
        border: 0px;
        border-radius: var(--border-radius);
        color: var(--white);
        margin-top: 1rem;
        margin-bottom: 0.75rem;
        cursor: pointer;
        :hover,
        :focus {
          box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
          filter: brightness(1.4);
        }
      }
      .div__subtext {
        display: flex;
        width: 100%;
        max-width: 320px;
        label {
          display: flex;
          font-size: 0.8rem;
          text-align: center;
          margin: 0.5rem auto;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);
        }
      }
      .div__line {
        width: 100%;
        border-bottom: 1px solid rgba(var(--black_rgb), 0.3);
        max-width: 320px;
        margin: 0.5rem;
      }
      .btn__register {
        font-family: "Poppins", sans-serif;
        text-transform: uppercase;
        width: 100%;
        max-width: 220px;
        height: 35px;
        background-color: transparent;
        border: 1px solid var(--primary2);
        border-radius: var(--border-radius);
        color: var(--primary2);
        /* margin-top: 1rem; */
        margin-bottom: 0.75rem;
        cursor: pointer;
        :hover,
        :focus {
          box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
        }
      }
      .div__navigators {
        display: flex;
        flex: 1;
        width: 90%;

        button {
          flex: 1;
          margin: 1rem 0.5rem;
          /* outline-color: var(--primary); */
        }
        .btn__voltar {
          border: 1px solid var(--primary2);
          color: var(--primary2);
          background: transparent;
        }
        .btn__avancar {
          flex: 3;
        }
        .btn__disable {
          border: 1px solid rgba(var(--black_rgb), 0.2);
          background: transparent;
          color: rgba(var(--black_rgb), 0.4);
          pointer-events: none;
        }
      }
      .div__session1 {
        display: flex;
        flex-direction: column;
        width: 100%;

        .btn__disable {
          border: 1px solid rgba(var(--black_rgb), 0.2);
          background: transparent;
          color: rgba(var(--black_rgb), 0.4);
          pointer-events: none;
        }

        .div__one_row {
          button {
            /* display: flex; */
            /* justify-content: center; */
            /* align-items: center; */
            position: relative;
            margin-left: -4.2rem;
            top: 0.7rem;
            cursor: pointer;
            border: 0rem;
            background-color: transparent;
            padding: 0rem 1rem;
            svg {
              font-size: 2rem;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }

        input[disabled],
        select[disabled] {
          opacity: 0.4;
        }

        p {
          color: rgba(var(--black_rgb), 0.6);
          padding: 0rem 2.5rem;
          margin: 0.25rem auto;
        }

        label {
          /* width: 100%; */
          /* background: lightgreen; */
          display: flex;
          text-align: left !important;
          align-items: center;
          justify-content: flex-start;
          padding: 0rem 2.5rem;
          color: rgba(var(--black_rgb), 0.6);

          input[type="checkbox"] {
            margin: 0rem;
            margin-right: 0.5rem;
            padding: 0rem;
            width: 1rem;
          }
        }
        input {
          margin: 0.25rem auto;
        }

        select {
          margin: 0.25rem auto;
          max-width: 325px;
          width: 100%;
          height: 30px;
          /* margin: 0.25rem 0rem; */
          outline-color: var(--primary);
          padding: 0.8rem 0.7rem;
        }

        h4 {
          /* background-color: red; */
          margin: 0rem;
          font-size: 0.9rem;
          color: rgba(var(--black_rgb), 0.5);
          font-weight: 400;
          padding: 0rem 2rem;
          flex: 1;
          /* margin-left: 0rem; */
          text-align: left;

          ::after {
            display: block;
            content: "";
            margin: 0.5rem 0rem;
            border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
          }
        }
      }
    }
  }

  ul {
    margin: 1rem 1rem;
    /* background: red; */
    font-size: 0.8rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
  }

  @media only screen and (max-width: 675px) {
    .div__gradient {
      display: flex;
      justify-content: center;
    }
    .div__corpo {
      flex-direction: column;
      flex: 1;
      justify-content: center;

      button {
        font-size: 1rem;
      }

      .div__part1 {
        display: none;
      }
      .div__part2 {
        flex: 1;
        margin: auto;
        width: 100%;
        form {
          width: 100%;
          flex: 1;
          margin: 0rem;
          max-width: none;

          .img__logo_mobile {
            display: block;
            max-width: 250px;
            height: auto;

            padding: 1rem 0rem;
          }
          select,
          input[type="text"],
          input[type="password"],
          input[type="email"] {
            padding: 1rem;
            font-size: 1rem;
            margin: 0.25rem auto;
            -webkit-appearance: searchfield;
          }
        }
      }
    }
  }
`;
