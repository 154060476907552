import React, { useState } from 'react';

import { LuFolders } from 'react-icons/lu';
import { FiFolderPlus } from 'react-icons/fi';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import { FaCalculator, FaFileAlt, FaUserCog } from 'react-icons/fa';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';

import CustomLink from './CustomLink';
import { DialogShoppingCart } from '../dialogs';
import { useAuth } from '../../contexts/AuthContext';
import { SITE_NAME } from '../../config';
import Logo from '../../assets/images/logo/LOGO - LUMEN PRO.png';
import { Container, NewResource } from './styles';

const routes = [
  {
    path: '/solicitar-projeto',
    Icon: <FiFolderPlus />,
    title: 'Solicitar Projeto',
    new: false,
  },
  {
    path: '/meus-projetos',
    Icon: <LuFolders />,
    title: 'Meus Projetos',
    new: false,
  },
  {
    path: '/orcamento',
    Icon: <FaCalculator />,
    title: 'Orçamento Online',
    new: false,
  },
  {
    path: '/comprovantes',
    Icon: <FaFileAlt />,
    title: 'Comprovantes',
    new: false,
  },
  {
    path: '/gerar-procuracao',
    Icon: <BsFileEarmarkArrowDownFill />,
    title: 'Gerar Procuração',
    new: true,
  },
  {
    path: '/configuracoes',
    Icon: <FaUserCog />,
    title: 'Configurações',
    new: false,
  },
];

export default function Menu({ open, handleChangeMenu }) {
  const { user, signOut } = useAuth();
  const [showShoppingCart, setShowShoppingCart] = useState(false);

  const handleChangeMenuIsMobile = () => {
    if (window.innerWidth <= 600) {
      handleChangeMenu();
    }
  };

  return (
    <Container open={open}>
      <button className="seta">
        <div className="containerImg2">
          <img src={Logo} alt={`Logo ${SITE_NAME}`} />
        </div>
        {open ? (
          <ChevronLeftIcon onClick={() => handleChangeMenu()} />
        ) : (
          <MenuIcon onClick={() => handleChangeMenu()} />
        )}
      </button>

      <div className="contentTexts">
        <p>
          Olá, <strong>{user && user?.name}</strong>
        </p>

        <button className="btn__logout" onClick={signOut}>
          Sair
        </button>
      </div>

      <div className="containerImg">
        <img src={Logo} alt={`Logo ${SITE_NAME}`} />
      </div>

      <p className="p__description">
        Confira nosso <b>menu de opções</b> para solicitar seu projeto e
        acompanhar de perto cada etapa de desenvolvimento
      </p>
      <nav>
        {routes.map((rota) => (
          <CustomLink to={rota.path} onClick={() => handleChangeMenuIsMobile()}>
            {rota.Icon}
            <p>{rota.title}{rota.new && (<NewResource><strong>Novo</strong></NewResource>)}</p>
          </CustomLink>
        ))}
      </nav>
      {showShoppingCart && (
        <DialogShoppingCart fechar={() => setShowShoppingCart(false)} />
      )}
    </Container>
  );
}
