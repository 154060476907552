import CompanyLogo from '../assets/images/logo/logo2.png';

const currency = function (number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(number);
};

export function printReceipt(data, company, user) {
  const printScreen = window.open('about:blank');
  printScreen.document.write(`
  <!DOCTYPE html>
<html lang="pt-BR">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
  <meta name="description" content="Impressão de recibo" />
  <title>Imprimir Recibo - ${company.social_reason}</title>

  <style type="text/css">

  @media print  
  {
    @page { margin: 0;  -webkit-print-color-adjust: exact;}

      
      div{
          page-break-inside: avoid;
      }
      
      -webkit-print-color-adjust: exact;
  }

    * {
      margin: 0;
      padding: 0;
    }

    body {
      font-family: Arial, Helvetica, sans-serif;
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }

    body img {
      max-width: 300px;
      margin: auto;
    }

    body .my__recibo .div__data_company {
      border-top: 1px dashed #000;
      padding: 1rem;
    }

    body .my__recibo::before {
      display: block;
      content: 'DADOS DO INTEGRADOR';
      font-size: .8rem;
      padding: 0rem 1rem;
      text-align: center;

    }

    body .my__recibo .div__recibo_head {
      border-top: 1px dashed #000;
      border-bottom: 1px dashed #000;
      padding: 1rem;
      text-align: center;
    }

    body .my__recibo .div__recibo_head h2 {
      margin-bottom: 0.25rem;
    }

    body .my__recibo .div__recibo_head .div__date_generated {
      display: flex;
      justify-content: space-between;
    }

    body .my__recibo .div__data_project {
      padding: 1rem;
    }

    body .my__recibo .div__data_project .div__date_generated {
      display: flex;
      justify-content: space-between;
      padding: 0.25rem 0rem;
    }

    body .my__recibo .div__final_price {
      border-top: 1px dashed #000;
      /* border-bottom: 1px dashed #000; */
      padding: 1rem;
    }

    body .my__recibo .div__final_price .div__date_generated {
      display: flex;
      justify-content: space-between;
      padding: 0.25rem 0rem;
    }
  </style>
</head>

<body>
  <img src=${CompanyLogo} />
  <div class="my__recibo">
    <div class="div__data_company">
 
     
          <p>
            <b>Nome:</b>
            ${company.social_reason}
          </p>
      
      
          <p>
            <b>${company.cnpj ? 'CNPJ' : 'CPF'}:</b> ${
    company.cnpj ? company.cnpj : user?.cpf
  }
          </p>
        
  
    </div>
    <div class="div__recibo_head">
      <h2>*** COMPROVANTE ***</h2>
      <span> Gerado ${new Date().toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })}</span>
    </div>
    <div class="div__data_project">
      <div class="div__date_generated">
        <span>- Projeto: ${
          data?.project ? data?.project?.client_name : ''
        }</span>
        <span>${currency(data?.original_price)}</span>
      </div>
      <div class="div__date_generated">
    <div class="div__final_price">
      <div class="div__date_generated">
        <span>Confirmação em <br />${new Date(data.paid_at).toLocaleDateString(
          'pt-BR',
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }
        )}</span>
        <span><b>Preço final: ${
          data?.final_price ? currency(data.final_price) : ' - '
        }</b></span>
      </div>
    </div>
  </div>

</body>

</html>`);

  setTimeout(() => printScreen.window.print(), 200);
  setTimeout(() => printScreen.window.close(), 400);
}
