import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { mask } from 'remask';
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import { Backdrop } from '../../../components'

import { Container, Form, Page } from './styles'

export default function GerarProcuracao() {
  const { user } = useAuth()

  const [pfisic, setPfisic] = useState(true);

  const isPj = useMemo(() => !!user?.company, [user?.company])

  const [projectAddress, setProjectAddress] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [companyCnpj, setCompanyCnpj] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [legalRepresentativeName, setLegalRepresentativeName] = useState('')
  const [legalRepresentativeRg, setLegalRepresentativeRg] = useState('')
  const [legalRepresentativeCpf, setLegalRepresentativeCpf] = useState('')
  const [legalRepresentativeAddress, setLegalRepresentativeAddress] =
    useState('')
  const [dealerName, setDealerName] = useState('')

  const handleChangeFantasyName = useCallback(
    (event) => {
      setFantasyName(event.target.value)
    },
    [setFantasyName]
  )

  const handleChangeCompanyCnpj = useCallback(
    (event) => {
      setCompanyCnpj(event.target.value)
    },
    [setCompanyCnpj]
  )

  const handleChangeCompanyAddress = useCallback(
    (event) => {
      setCompanyAddress(event.target.value)
    },
    [setCompanyAddress]
  )

  const handleChangeLegalRepresentativeName = useCallback(
    (event) => {
      setLegalRepresentativeName(event.target.value)
    },
    [setLegalRepresentativeName]
  )
  const handleChangeLegalRepresentativeRg = useCallback(
    (event) => {
      setLegalRepresentativeRg(event.target.value)
    },
    [setLegalRepresentativeRg]
  )
  const handleChangeLegalRepresentativeCpf = useCallback(
    (event) => {
      setLegalRepresentativeCpf(event.target.value)
    },
    [setLegalRepresentativeCpf]
  )
  const handleChangeLegalRepresentativeAddress = useCallback(
    (event) => {
      setLegalRepresentativeAddress(event.target.value)
    },
    [setLegalRepresentativeAddress]
  )

  const handleProjectAddress = useCallback(
    (event) => {
      setProjectAddress(event.target.value)
    },
    [setProjectAddress]
  )

  const handleChangeDealerName = useCallback(
    (event) => {
      setDealerName(event.target.value)
    },
    [setDealerName]
  )

  const [showBackdrop, setShowBackdrop] = useState(false)

  const onSubmitProcuracaoForm = useCallback(
    async (event) => {
      event.preventDefault()
      setShowBackdrop(true)
      if(!pfisic && !fantasyName) {
        setShowBackdrop(false)
        return toast.warn('Nome fantasia é obrigatório')
      }

      if(!pfisic && !companyCnpj) {
        setShowBackdrop(false)
        return toast.warn('Cnpj da Empresa é obrigatório')
      }

      if(!pfisic && !companyAddress) {
        setShowBackdrop(false)
        return toast.warn('Endereço da Empresa é obrigatório')
      }

      if (!legalRepresentativeName) {
        setShowBackdrop(false)
        return toast.warn('Nome do Representante Legal é obrigatório')
      }
      if (!legalRepresentativeRg) {
        setShowBackdrop(false)
        return toast.warn('RG do Representante Legal é obrigatório')
      }
      if (!legalRepresentativeCpf) {
        setShowBackdrop(false)
        return toast.warn('CPF do Representante Legal é obrigatório')
      }
      if (!legalRepresentativeAddress) {
        setShowBackdrop(false)
        return toast.warn(
          'Endereço completo do Representante Legal é obrigatório'
        )
      }
      if (!dealerName) {
        setShowBackdrop(false)
        return toast.warn('Nome da Empresa Concessionária é obrigatório')
      }

      if (!projectAddress) {
        setShowBackdrop(false)
        return toast.warn('Endereço do projeto é obrigatório')
      }

      const params = new URLSearchParams({
        is_pj: !pfisic,
        fantasy_name: fantasyName,
        company_cnpj: companyCnpj,
        company_address: companyAddress,
        legal_representative_name: legalRepresentativeName,
        legal_representative_rg: legalRepresentativeRg,
        legal_representative_cpf: legalRepresentativeCpf,
        legal_representative_address: legalRepresentativeAddress,
        dealer_name: dealerName,
        project_address: projectAddress
      })

      const url = `${api.defaults.baseURL}/procuracao/generate?${params}`
      await fetch(url, { headers: api.defaults.headers })
        .then(async (res) => {
          const filename = res.headers
            .get('content-disposition')
            ?.split(';')
            ?.find((n) => n.includes('filename='))
            ?.replace('filename=', '')
            ?.replaceAll('"', '')
            ?.trim()

          return {
            blob: new Blob([await res.blob()]),
            filename: filename ? decodeURI(filename) : 'procuracao.docx',
          }
        })
        .then(({ blob, filename }) => {
          const fileUrl = window.URL.createObjectURL(blob)
          const anchorTag = document.createElement('a')
          anchorTag.href = fileUrl
          anchorTag.download = filename
          document.body.appendChild(anchorTag)
          anchorTag.click()
          anchorTag.remove()
        })
        .catch((error) => {
          console.error(error)
          toast.error('Erro ao gerar procuração')
        })
        .finally(() => setShowBackdrop(false))
    },
    [
      setShowBackdrop,
      fantasyName,
      companyAddress,
      companyCnpj,
      legalRepresentativeName,
      legalRepresentativeRg,
      legalRepresentativeCpf,
      legalRepresentativeAddress,
      dealerName,
      pfisic
    ]
  )

  return (
    <Container>
      <Page>
        <header>
          <h1>Gerar Procuração</h1>
          <div className="div__divider" />
          <span>Preencha os dados abaixo para gerar a procuração.</span>
        </header>
        <Form onSubmit={onSubmitProcuracaoForm}>
          <div className="div__select">
            <span
              className={pfisic ? 's__select' : ''}
              onClick={() => {
                setPfisic(true)
              }}
            >
              Pessoa Física
            </span>
            <span
              className={!pfisic ? 's__select' : ''}
              onClick={() => {
                setPfisic(false)
              }}
            >
              Pessoa Jurídica
            </span>
          </div>
          {!pfisic && (
            <>

            <h4>Dados da Empresa</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                className="input-custom-style"
                type="text"
                name="fantasy_name"
                value={fantasyName}
                placeholder="Nome Fantasia"
                style={{ flex: 2 }}
                onChange={handleChangeFantasyName}
                required={isPj}
              />
              <input
                className="input-custom-style"
                type="text"
                name="company_cnpj"
                value={mask(companyCnpj, '99.999.999/9999-99')}
                placeholder="CNPJ da Empresa"
                style={{ flex: 2 }}
                onChange={handleChangeCompanyCnpj}
                required={isPj}
              />
              <textarea
                className="input-custom-style"
                name="company_address"
                value={companyAddress}
                placeholder="Endereço completo da Empresa"
                style={{ flex: 2 }}
                onChange={handleChangeCompanyAddress}
                required={isPj}
              />
            </div>
          </>
          )}

            <h4>Dados do Representante Legal</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                className="input-custom-style"
                type="text"
                name="legal_representative_name"
                value={legalRepresentativeName}
                placeholder="Nome do Representante Legal"
                style={{ flex: 2 }}
                onChange={handleChangeLegalRepresentativeName}
                required={isPj}
              />
              <input
                className="input-custom-style"
                type="text"
                name="legal_representative_rg"
                value={legalRepresentativeRg}
                placeholder="RG do Representante Legal"
                style={{ flex: 2 }}
                onChange={handleChangeLegalRepresentativeRg}
                required={isPj}
              />
              <input
                className="input-custom-style"
                type="text"
                name="legal_representative_cpf"
                value={mask(legalRepresentativeCpf, '999.999.999-99')}
                placeholder="CPF do Representante Legal"
                style={{ flex: 2 }}
                onChange={handleChangeLegalRepresentativeCpf}
                required={isPj}
              />
              <textarea
                className="input-custom-style"
                name="legal_representative_address"
                value={legalRepresentativeAddress}
                placeholder="Endereço completo do Representante Legal"
                style={{ flex: 2 }}
                onChange={handleChangeLegalRepresentativeAddress}
                required={isPj}
              />
            </div>

          <h4>Informações do Projeto</h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <input
              className="input-custom-style"
              type="text"
              name="dealer_name"
              value={projectAddress}
              placeholder="Endereço do Projeto Ex: Belém - PA"
              style={{ flex: 2 }}
              onChange={handleProjectAddress}
              required
            />
          </div>
          <h4>Informações da Concessionária</h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <input
              className="input-custom-style"
              type="text"
              name="dealer_name"
              value={dealerName}
              placeholder="Nome da Empresa Concessionária Ex: EQUATORIAL-PA"
              style={{ flex: 2 }}
              onChange={handleChangeDealerName}
              required
            />
          </div>
          <button
            type="submit"
            className="primary-button-style"
            style={{ margin: '1rem auto' }}
          >
            Gerar Procuração
          </button>
        </Form>
      </Page>
      {showBackdrop && (
        <Backdrop message="Gerando procuração, por favor aguarde." />
      )}
    </Container>
  )
}
