import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mask } from 'remask';

import Popover from '@material-ui/core/Popover';
import BackupIcon from '@material-ui/icons/Backup';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import LabelIcon from '@material-ui/icons/Label';
import SearchIcon from '@material-ui/icons/Search';

import Imgcoordinate from '../../../assets/images/prints/help_coodinate.png';
import Imgcoordinate2 from '../../../assets/images/prints/help_coodinate_2.png';
import Imgcoordinate3 from '../../../assets/images/prints/help_coodinate_3.png';

import API from '../../../services/api';
import { validateCpf } from '../../../utils';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Backdrop,
  DialogConfirmation,
  DialogCompleteData,
  DialogFindLocation,
  DialogProjectConfirmation,
} from '../../../components';
import { Container, Page, Containerdica } from './styles';

export default function NewProject() {
  const {
    user,
    getShoppingcart,
    refreshUser: getDadosUsuario,
    isRegistrationComplete: completedData,
  } = useAuth();
  const company = user?.company;

  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const [qstate] = useState(query.get('state'));
  const [qcons] = useState(query.get('concessionaria'));
  const [qpower] = useState(query.get('total_project_power'));

  const [showbackdrop, setShowbackdrop] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [listElectricCompanies, setListElectricCompanies] = useState([]);

  const [credits, setCredits] = useState(0);

  const [voltageLevel, setVoltageLevel] = useState('BT');

  const [finish, setFinish] = useState(false);
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente remover este arquivo?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
    temp: '',
  });

  const [descontos, setDescontos] = useState({
    credits_value: '',
    credits_used: '',
  });

  const [showcoord, setShowcoord] = useState(false);

  const [pfisic, setPfisic] = useState(true);
  const [listRateios, setListRateios] = useState([]);

  const [listAdditional, setListAdditional] = useState([]);

  const [listInverters, setListInverters] = useState([
    {
      project_id: '',
      power_kwp: '',
      model: '',
      amount: '',
    },
  ]);
  const [listDatasheetInvert, setListdatasheetInvert] = useState([]);

  const [dados, setDados] = useState({
    // person
    cpf: '',
    name: '',
    is_client: true,
    is_integrator: true,
    role: null,

    // company
    cnpj: '',
    social_reason: '',
    owner_id: null,
    fantasy_name: null,
    municipal_registration: null,
    state_registration: null,

    // person and company
    address_id: null,

    // new project
    dealer_id: '',
    client_person_id: '', //se for pessoa
    client_company_id: '', //se for empresa
    concessionaria: qcons ? qcons : '', //converter para dealer_id depois
    breaker_type: '',
    breaker_value: '', // que pode receber other_breaker_value caso valor seja 'other'
    other_breaker_value: '', //substituir breaker_value
    latitude: '',
    longitude: '',
    exchange_ownership: '',
    total_project_power: qpower ? qpower : '',
    comments: '',
    has_apportionment: listRateios.length > 0 ? true : false,
    deadline: null,
    suspended: false,
    finished_date: null,
    has_order_material: '',
    amount_panels: '',
    panel_power: '',
    panel_model: '',

    // inverter
    qtd_inverters: '',
    inverters_model: '',
    power_inverters: '',

    // arquivos
    has_document_energy: '',
    document_energy: '',
    document_energy_data: {},
    document_energy_consumer_unit_number: '',
    document_energy_full_address: '',
    document_energy_zip_code: '',
    document_energy_city: '',
    document_energy_number: '',

    document_archive: '',
    document_procuration: '',
    document_rateio: '',
    document_material: '',
    document_panel_datasheet: '',
    document_inverters_datasheet: [false],
    social_contract: '',

    state: qstate ? qstate : '',
  });

  const [checkeds, setCheckeds] = useState({
    check_panels: false,
    check_inverters: [false],
  });

  const [circuitBreakers] = useState([
    '2 A',
    '4 A',
    '6 A',
    '10 A',
    '16 A',
    '20 A',
    '25 A',
    '32 A',
    '40 A',
    '50 A',
    '63 A',
    '70 A',
    '80 A',
    '100 A',
    '125 A',
    'Outro',
  ]);
  const onInputChange = (e) => {
    if (e.target.name === 'cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-99'),
      });
    } else if (
      e.target.name === 'amount_panels' ||
      e.target.name === 'qtd_inverters'
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99999999'),
      });
    } else if (
      e.target.name === 'exchange_ownership' &&
      e.target.value === 'false'
    ) {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        document_procuration: '',
      });
    } else if (e.target.name === 'has_order_material') {
      if (e.target.value === 'true') {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_panel_datasheet: '',
          document_inverters_datasheet: '',
          amount_panels: '',
          qtd_inverters: '',
          panel_model: '',
          panel_power: '',
          inverters_model: '',
          document_material: '',
        });
        setListInverters([
          {
            project_id: '',
            power_kwp: '',
            model: '',
            amount: '',
          },
        ]);
        setListdatasheetInvert([]);
        setCheckeds({ check_panels: false, check_inverters: [false] });
      } else if (e.target.value === 'false') {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_materials: '',
        });
      }
    } else if (e.target.name === 'total_project_power') {
      if (e.target.value > 75) {
        return toast.warn(
          'Valor máximo de 75kWp. Para minigeração entre em contato com um de nossos atendentes via Whatsapp.'
        );
      }

      if (e.target.value <= 40) {
        setVoltageLevel('BT');
      }

      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'other_breaker_value') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999'),
      });
    } else if (
      e.target.name === 'panel_power' ||
      e.target.name === 'power_inverters'
    ) {
      if (e.target.value > 10000) {
        return toast.warn('Valor máximo de 10000kWp');
      }

      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'latitude' || e.target.name === 'longitude') {
      if (e.target.value > 99 || e.target.value < -99) return;
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'state') {
      getConcess(e.target.value);
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        concessionaria: '',
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const loadArchive = (event) => {
    if (event.target.files[0]) {
      if (
        event.target.files[0].type === 'application/pdf' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg'
      ) {
        setDados({ ...dados, [event.target.name]: event.target.files[0] });
      } else {
        toast.error('Tipo de arquivo inválido, insira .pdf, .png ou .jpeg');
      }
    }
  };

  const loaddatasheetinver = (event, idx) => {
    if (event.target.files[0]) {
      if (
        event.target.files[0].type === 'application/pdf' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg'
      ) {
        let aux = listDatasheetInvert;
        aux.push({
          id: idx,
          file: event.target.files[0],
        });
        setListdatasheetInvert([...aux]);
      } else {
        toast.error('Tipo de arquivo inválido, insira .pdf, .png ou .jpeg');
      }
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const submeterForm = (event) => {
    event.preventDefault();

    if (dados.cpf && !validateCpf(dados.cpf.replace(/[^0-9]/g, ''))) {
      return toast.warn('CPF inválido');
    }

    if (!dados.document_archive) {
      return toast.warn('Anexar documento com foto é obrigatório');
    } else if (dados.has_order_material === true && !dados.document_material) {
      return toast.warn(
        'Anexar documento referente ao pedido de materiais é obrigatório'
      );
    } else if (pfisic === false && !dados.social_contract) {
      return toast.warn('Anexar contrato social é obrigatório');
    } else if (
      dados.exchange_ownership === 'true' &&
      !dados.document_procuration
    ) {
      return toast.warn('Anexar procuração é obrigatório');
    }

    const has_document_energy = dados.has_document_energy === 'true';
    if (has_document_energy && !dados.document_energy) {
      return toast.warn('Anexar conta de energia da unidade consumidora é obrigatório');
    }

    const isMissingDocumentData =
      !dados.document_energy_consumer_unit_number ||
      !dados.document_energy_full_address ||
      !dados.document_energy_zip_code ||
      !dados.document_energy_city ||
      !dados.document_energy_number;

    if (!has_document_energy && isMissingDocumentData) {
      return toast.warn('Dados da conta de energia da unidade consumidora são obrigatórios');
    }

    if (!has_document_energy) {
      dados.document_energy_data = {
        type: 'document_energy',
        consumerUnitNumber: dados.document_energy_consumer_unit_number,
        fullAddress: dados.document_energy_full_address,
        zipCode: dados.document_energy_zip_code,
        city: dados.document_energy_city,
        number: dados.document_energy_number,
      };
    }

    setFinish(true);
  };

  const getConcess = async (value) => {
    setShowbackdrop(true);

    await API.get(`/dealers/state/${value}`)
      .then((res) => {
        setListElectricCompanies(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.error(error);
        setShowbackdrop(false);
      });
  };

  const enviarProjeto = async () => {
    setShowbackdrop(true);
    setFinish(false);

    const project = {
      client_name: dados.cpf ? dados.name : dados.social_reason,
      client_cpf: dados.cpf ? dados.cpf : null,
      client_cnpj: dados.cnpj ? dados.cnpj.replace('­', '') : null,
      dealer_id: listElectricCompanies.find(
        (a) => a.name === dados.concessionaria
      ).id,
      breaker_type: dados.breaker_type,
      breaker_value:
        dados.breaker_value === 'Outro'
          ? dados.other_breaker_value
          : dados.breaker_value,
      latitude: dados.latitude,
      longitude: dados.longitude,
      exchange_ownership: dados.exchange_ownership,
      total_project_power: dados.total_project_power,
      has_apportionment: dados.has_apportionment,
      deadline: null,
      suspended: false,
      finished_date: null,
      has_order_material: dados.has_order_material,
      amount_panels:
        dados.has_order_material === 'true' ? null : dados.amount_panels,
      panel_power:
        dados.has_order_material === 'true' ? null : dados.panel_power,
      panel_model:
        dados.has_order_material === 'true' ? null : dados.panel_model,
      comments: dados.comments,
      company_id: company?.id, ///lembrar de alterar para o contexto LUAN NUNES LINDO

      credits_discount: credits,
      note: '',
      voltage_level: voltageLevel,
    };

    let id_project;

    await API.post('/projects', project)
      .then((res) => {
        id_project = res.data.project.id;
      })
      .catch((error) => {
        console.error(error);
        setShowbackdrop(false);
        toast.warn(error.response.data.message);
      });

    dados.has_order_material === 'false' &&
      (await Promise.all(listInverters.map(async (inverter) => {
        let auxInvert = { ...inverter, project_id: id_project };

        await API.post('/inverters', auxInvert).catch((error) => {
          console.error('Erro: ', error.response.data.message);
          setShowbackdrop(false);
          toast.warn(error.response.data.message + ' - inversores');
        });
      })));

    (dados.document_archive) &&
      (await submitArchive(
        'Documento com foto',
        id_project,
        dados.document_archive,
      ));
    (dados.social_contract) &&
      (await submitArchive(
        'Contrato social',
        id_project,
        dados.social_contract,
      ));
    const has_document_energy = dados.has_document_energy === 'true';
    (has_document_energy && dados.document_energy) &&
      (await submitArchive(
        'Conta de energia da unidade geradora',
        id_project,
        dados.document_energy,
        null,
      ));
    (!has_document_energy && dados.document_energy_data) &&
      (await submitArchive(
        'Dados da conta de energia da unidade geradora',
        id_project,
        null,
        dados.document_energy_data,
      ));
    (dados.document_material) &&
      (await submitArchive(
        'Pedido de materiais',
        id_project,
        dados.document_material,
        null,
      ));
    (dados.document_procuration) &&
      (await submitArchive(
        'Procuração',
        id_project,
        dados.document_procuration,
        null,
      ));
    (dados.document_panel_datasheet) &&
      (await submitArchive(
        'Datasheet dos painéis',
        id_project,
        dados.document_panel_datasheet,
        null,
      ));

    listRateios.map((rateio, idx) =>
      submitArchive(
        `Rateio ${idx + 1}`,
        id_project,
        rateio.document_rateio,
        null,
      )
    );

    listAdditional.map((archiveAdit) =>
      submitArchive(
        archiveAdit.other_type ? archiveAdit.other_type : archiveAdit.type,
        id_project,
        archiveAdit.document_additional,
        null,
      )
    );

    (listDatasheetInvert.length) > 0 &&
      listDatasheetInvert.map(
        async (archiveAdit) =>
          await submitArchive(
            'Datasheet do inversor',
            id_project,
            archiveAdit.file,
            null,
          )
      );

    toast.success('Projeto adicionado ao carrinho com sucesso');
    getShoppingcart();

    getDadosUsuario();
    setTimeout(() => navigate('/meus-projetos'), 200);
  };

  const submitArchive = async (type, id_project, file, data) => {
    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user?.token,
        'Content-type': 'multipart/form-data',
      },
    };

    let formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    if (data) {
      formData.append('data', JSON.stringify(data));
    }
    formData.append('title', type);
    formData.append('project_id', id_project);

    await API.post('/documents', formData, config).catch((error) => {
      console.error('Erro: ', error.response.data.message);
      toast.warn(error.response.data.message + ' - ' + type);
      return true;
    });
  };

  const handleSetMiddlePower = (event) => {
    setVoltageLevel(event.target.value);
  };

  useEffect(() => {
    if (qstate) getConcess(qstate);
  }, [qstate]);

  return (
    <Container>
      <Page>
        <header>
          <h1>Novo projeto de energia solar</h1>
          <div className="div__divider" />
          <span>
            Adicione novos projetos com facilidade e agilidade, em apenas alguns
            cliques.
          </span>
        </header>
        <content>
          <form onSubmit={submeterForm}>
            <h4>1 - Informações da Concessionária</h4>
            <div className="div__multiColumn">
              <select
                className="input-custom-style "
                required
                name="state"
                style={{ flex: 1 }}
                value={dados.state}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
              <select
                className="input-custom-style "
                required
                disabled={dados.state ? false : true}
                name="concessionaria"
                style={{ flex: 3 }}
                value={dados.concessionaria}
                onChange={onInputChange}
              >
                {!dados.concessionaria && (
                  <option value="" disabled selected>
                    Nome da concessionária
                  </option>
                )}

                {dados.concessionaria && (
                  <option
                    value={dados.concessionaria}
                    disabled
                    selected
                    style={{ textTransform: 'cap' }}
                  >
                    {dados.concessionaria}
                  </option>
                )}
                {dados.state && listElectricCompanies.length === 0 && (
                  <option value={''} selected style={{ textTransform: 'cap' }}>
                    Nenhuma concessionária cadastrada
                  </option>
                )}
                {listElectricCompanies.length > 0 &&
                  listElectricCompanies.map((concess, idx) => (
                    <option key={idx + concess.name} value={concess.name}>
                      {concess.name}
                    </option>
                  ))}
              </select>
            </div>
            <h4>2 - Informações do Cliente</h4>
            <div className="div__select">
              <span
                className={pfisic ? 's__select' : ''}
                onClick={() => {
                  setPfisic(true);
                  setDados({
                    ...dados,
                    cpf: '',
                    cnpj: '',
                    name: '',
                    social_reason: '',
                    social_contract: '',
                  });
                }}
              >
                Pessoa Física
              </span>
              <span
                className={!pfisic ? 's__select' : ''}
                onClick={() => {
                  setPfisic(false);
                  setDados({
                    ...dados,
                    cpf: '',
                    cnpj: '',
                    name: '',
                    social_reason: '',
                    social_contract: '',
                  });
                }}
              >
                Pessoa Jurídica
              </span>
            </div>
            <div
              className="div__multiColumn"
              style={{ maxWidth: pfisic ? '' : ' ' }}
            >
              <input
                className="input-custom-style "
                type="text"
                placeholder={pfisic ? 'CPF' : 'CNPJ'}
                value={pfisic ? dados.cpf : dados.cnpj}
                onChange={onInputChange}
                name={pfisic ? 'cpf' : 'cnpj'}
                style={{ flex: 1 }}
                required
              />

              <input
                className="input-custom-style "
                type="text"
                placeholder={pfisic ? 'Nome completo' : 'Razão social'}
                value={pfisic ? dados.name : dados.social_reason}
                onChange={onInputChange}
                name={pfisic ? 'name' : 'social_reason'}
                style={{ flex: 3 }}
                required
              />
            </div>
            {!pfisic && (
              <>
                {!dados.social_contract && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id="social_contract"
                    name="social_contract"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.social_contract
                      ? 'div__newfieldarchive div__selectedFieldArchive'
                      : 'div__newfieldarchive'
                  }
                  id="social_contract"
                  name="social_contract"
                  htmlFor={dados.social_contract ? '' : 'social_contract'}
                  onClick={(event) => {
                    dados.social_contract &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.social_contract,
                        temp: 'social_contract',
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: '1rem',
                              marginBottom: '-0.2rem',
                            }}
                          />{' '}
                          ANEXAR CONTRATO SOCIAL
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.social_contract
                            ? dados.social_contract.name
                            : '...'}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.social_contract
                            ? (
                                dados.social_contract.size /
                                (1024 * 1024)
                              ).toFixed(2) + 'Mb'
                            : '...'}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.social_contract
                            ? dados.social_contract.type.split('/')[1]
                            : '...'}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.social_contract ? (
                        <div>
                          <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar o contrato social
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {!dados.document_archive && (
              <input
                className="input-custom-style "
                type="file"
                id="document_archive"
                name="document_archive"
                onChange={loadArchive}
              />
            )}
            <label
              className={
                dados.document_archive
                  ? 'div__newfieldarchive div__selectedFieldArchive'
                  : 'div__newfieldarchive'
              }
              htmlFor={dados.document_archive ? '' : 'document_archive'}
              onClick={(event) => {
                dados.document_archive &&
                  setShowconf({
                    ...showconf,
                    status: true,
                    dados: dados.document_archive,
                    temp: 'document_archive',
                  });
              }}
            >
              <div className="div__newfieldarchive_in">
                <div className="div__dataArchive">
                  <AttachFileIcon />
                  <div className="div__info">
                    <p>
                      <LabelIcon
                        style={{ fontSize: '1rem', marginBottom: '-0.2rem' }}
                      />{' '}
                      DOCUMENTO COM FOTO{' '}
                      {pfisic ? '' : 'DO REPRESENTANTE LEGAL'}
                    </p>
                    <p>
                      <b>Nome: </b>
                      {dados.document_archive
                        ? dados.document_archive.name
                        : '...'}
                    </p>
                    <p>
                      <b>Tamanho: </b>
                      {dados.document_archive
                        ? (dados.document_archive.size / (1024 * 1024)).toFixed(
                            2
                          ) + 'Mb'
                        : '...'}
                    </p>
                    <p>
                      <b>Tipo de arquivo: </b>
                      {dados.document_archive
                        ? dados.document_archive.type.split('/')[1]
                        : '...'}
                    </p>
                  </div>
                </div>

                <div className="div__env_archive">
                  {dados.document_archive ? (
                    <div>
                      <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                      arquivo
                      <br /> selecionado
                    </div>
                  ) : (
                    <div>
                      <BackupIcon /> Clique para anexar um documento com foto
                      <br />
                      (.png .jpeg .pdf)
                    </div>
                  )}
                </div>
              </div>
            </label>
            <h4>
              3 - Informações da instalação - Unidade geradora
              <div className="div__moreOptions">
                <button
                  type="button"
                  className="secondary-button-style"
                  onClick={() => {
                    let aux = listRateios;
                    aux.push({ document_rateio: '' });
                    setListRateios([...aux]);
                  }}
                >
                  + Documento de rateio
                </button>
              </div>
            </h4>
            <div className="div__multiColumn" style={{ maxWidth: '690px' }}>
              <select
                className="input-custom-style "
                required
                name="breaker_type"
                style={{ flex: 1 }}
                value={dados.breaker_type}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Tipo de disjuntor
                </option>
                <option value="monofásico">Monofásico</option>
                <option value="bifásico">Bifásico</option>
                <option value="trifásico">Trifásico</option>
              </select>

              <select
                className="input-custom-style "
                required
                name="breaker_value"
                style={{ flex: 2 }}
                value={dados.breaker_value}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Valor do disjuntor
                </option>
                {circuitBreakers.map((disj, idx) => (
                  <option value={disj} key={idx}>
                    {disj}
                  </option>
                ))}
              </select>

              <input
                className="input-custom-style "
                type="text"
                name="other_breaker_value"
                value={dados.other_breaker_value}
                placeholder="Valor do disjuntor (ex. 16 A)"
                style={{
                  flex: 2,
                  visibility:
                    dados.breaker_value === 'Outro' ? 'visible' : 'hidden',
                }}
                onChange={onInputChange}
                required={dados.breaker_value === 'Outro' ? true : false}
              />
              <div
                className="pos_field"
                style={{
                  visibility:
                    dados.breaker_value === 'Outro' ? 'visible' : 'hidden',
                }}
              >
                A
              </div>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: '530px' }}>
              <input
                className="input-custom-style "
                type="number"
                placeholder={'Latitude do projeto'}
                value={dados.latitude}
                onChange={onInputChange}
                name={'latitude'}
                style={{ flex: 1 }}
                required
              />
              <input
                className="input-custom-style "
                type="number"
                placeholder={'Longitude do projeto'}
                value={dados.longitude}
                onChange={onInputChange}
                name={'longitude'}
                style={{ flex: 1 }}
                required
              />

              <div className="div__moreOptions">
                <button
                  type="button"
                  className="secondary-button-style"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => setShowcoord(!showcoord)}
                >
                  <SearchIcon /> Buscar
                </button>
              </div>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: '505px' }}>
              <select
                className="input-custom-style "
                required
                name="exchange_ownership"
                style={{ flex: 1 }}
                value={dados.exchange_ownership}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Trocar titularidade?
                </option>
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: '505px' }}>
              <select
                className="input-custom-style"
                required
                name="has_document_energy"
                style={{ flex: 1 }}
                value={dados.has_document_energy}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Possui documento da conta de energia?
                </option>
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
            </div>
            {dados.exchange_ownership === 'true' && (
              <>
                {!dados.document_procuration && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id="document_procuration"
                    name="document_procuration"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_procuration
                      ? 'div__newfieldarchive div__selectedFieldArchive'
                      : 'div__newfieldarchive'
                  }
                  htmlFor={
                    dados.document_procuration ? '' : 'document_procuration'
                  }
                  onClick={(event) => {
                    dados.document_procuration &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_procuration,
                        temp: 'document_procuration',
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: '1rem',
                              marginBottom: '-0.2rem',
                            }}
                          />{' '}
                          ANEXAR PROCURAÇÃO PARA TROCA DE TITULARIDADE
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_procuration
                            ? dados.document_procuration.name
                            : '...'}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_procuration
                            ? (
                                dados.document_procuration.size /
                                (1024 * 1024)
                              ).toFixed(2) + 'Mb'
                            : '...'}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_procuration
                            ? dados.document_procuration.type.split('/')[1]
                            : '...'}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_procuration ? (
                        <div>
                          <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar a procuração
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {!dados.document_energy && (
              <input
                className="input-custom-style "
                type="file"
                id="document_energy"
                name="document_energy"
                onChange={loadArchive}
              />
            )}
            {dados.has_document_energy === 'true' ? (
              <label
                className={
                  dados.document_energy
                    ? 'div__newfieldarchive div__selectedFieldArchive'
                    : 'div__newfieldarchive'
                }
                htmlFor={dados.document_energy ? '' : 'document_energy'}
                onClick={(event) => {
                  dados.document_energy &&
                    setShowconf({
                      ...showconf,
                      status: true,
                      dados: dados.document_energy,
                      temp: 'document_energy',
                    });
                }}
              >
                <div className="div__newfieldarchive_in">
                  <div className="div__dataArchive">
                    <AttachFileIcon />
                    <div className="div__info">
                      <p>
                        <LabelIcon
                          style={{
                            fontSize: '1rem',
                            marginBottom: '-0.2rem',
                          }}
                        />{' '}
                        CONTA DE ENERGIA DA UNIDADE GERADORA
                      </p>
                      <p>
                        <b>Nome: </b>
                        {dados.document_energy
                          ? dados.document_energy.name
                          : '...'}
                      </p>
                      <p>
                        <b>Tamanho: </b>
                        {dados.document_energy
                          ? (dados.document_energy.size / (1024 * 1024)).toFixed(
                              2
                            ) + 'Mb'
                          : '...'}
                      </p>
                      <p>
                        <b>Tipo de arquivo: </b>
                        {dados.document_energy
                          ? dados.document_energy.type.split('/')[1]
                          : '...'}
                      </p>
                    </div>
                  </div>

                  <div className="div__env_archive">
                    {dados.document_energy ? (
                      <div>
                        <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                        arquivo
                        <br /> selecionado
                      </div>
                    ) : (
                      <div>
                        <BackupIcon /> Clique para anexar a conta de energia
                        <br />
                        (.png .jpeg .pdf)
                      </div>
                    )}
                  </div>
                </div>
              </label>
            ) : (
              dados.has_document_energy === 'false' ? (
                <div
                  className="div__multiColumn"
                  style={{
                    border: '2px dotted rgba(var(--black_rgb), 0.25)',
                    maxWidth: '530px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: '0.5rem',
                    margin: '1rem 2rem 0rem 2rem',
                    padding: '0.25rem',
                  }}
                >
                  <h5
                    style={{
                      margin: '0.5rem auto 0.5rem 1rem',
                    }}
                  >
                    Informe os dados abaixo
                  </h5>
                  <input
                    className="input-custom-style"
                    type="text"
                    placeholder='Número da Unidade Consumidora'
                    value={dados.document_energy_consumer_unit_number}
                    onChange={onInputChange}
                    name={'document_energy_consumer_unit_number'}
                    style={{ flex: 1, width: '94%' }}
                    required={dados.has_document_energy !== 'true'}
                  />
                  <input
                    className="input-custom-style"
                    type="text"
                    placeholder='Endereço Completo'
                    value={dados.document_energy_full_address}
                    onChange={onInputChange}
                    name='document_energy_full_address'
                    style={{ flex: 1, width: '94%' }}
                    required={dados.has_document_energy !== 'true'}
                  />
                  <input
                    className="input-custom-style"
                    type="text"
                    placeholder='CEP'
                    value={dados.document_energy_zip_code}
                    onChange={onInputChange}
                    name='document_energy_zip_code'
                    style={{ flex: 1, width: '94%' }}
                    required={dados.has_document_energy !== 'true'}
                  />
                  <input
                    className="input-custom-style"
                    type="text"
                    placeholder='Cidade'
                    value={dados.document_energy_city}
                    onChange={onInputChange}
                    name='document_energy_city'
                    style={{ flex: 1, width: '94%' }}
                    required={dados.has_document_energy !== 'true'}
                  />
                  <input
                    className="input-custom-style"
                    type="text"
                    placeholder='Número'
                    value={dados.document_energy_number}
                    onChange={onInputChange}
                    name='document_energy_number'
                    style={{ flex: 1, width: '94%' }}
                    required={dados.has_document_energy !== 'true'}
                  />
                </div>
              ) : (<></>)
            )}
            {listRateios.map((rateio, idx) => (
              <div
                key={idx}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {!rateio.document_rateio && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id={`document_rateio_${idx}`}
                    name={`document_rateio_${idx}`}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        if (
                          event.target.files[0].type === 'application/pdf' ||
                          event.target.files[0].type === 'image/png' ||
                          event.target.files[0].type === 'image/jpeg'
                        ) {
                          let aux = listRateios;
                          aux[idx].document_rateio = event.target.files[0];
                          setListRateios([...aux]);
                        } else {
                          toast.error(
                            'Tipo de arquivo inválido, insira .pdf, .png ou .jpeg'
                          );
                        }
                      }
                    }}
                  />
                )}

                {(idx !== 0 || true) && (
                  <div className="div__remove_field_arch">
                    <button
                      type="button"
                      className={
                        rateio.document_rateio
                          ? 'btn__remove_field btn__remove_field_disabled'
                          : 'btn__remove_field'
                      }
                      onClick={() => {
                        let aux = listRateios;
                        aux.splice(idx, 1);
                        setListRateios([...aux]);
                      }}
                    >
                      Remover campo
                    </button>
                  </div>
                )}

                <label
                  className={
                    rateio.document_rateio
                      ? 'div__newfieldarchive div__selectedFieldArchive'
                      : 'div__newfieldarchive'
                  }
                  htmlFor={
                    rateio.document_rateio ? '' : `document_rateio_${idx}`
                  }
                  onClick={(event) => {
                    listRateios[idx].document_rateio &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: listRateios[idx].document_rateio,
                        temp: `lista_${idx}`,
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: '1rem',
                              marginBottom: '-0.2rem',
                            }}
                          />{' '}
                          ANEXAR DOCUMENTO PARA RATEIO {idx != 0 ? idx + 1 : ''}
                        </p>
                        <p>
                          <b>Nome: </b>
                          {rateio.document_rateio
                            ? rateio.document_rateio.name
                            : '...'}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {rateio.document_rateio
                            ? (
                                rateio.document_rateio.size /
                                (1024 * 1024)
                              ).toFixed(2) + 'Mb'
                            : '...'}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {rateio.document_rateio
                            ? rateio.document_rateio.type.split('/')[1]
                            : '...'}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {rateio.document_rateio ? (
                        <div>
                          <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar os arquivos de
                          rateio
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
            ))}

            <h4>
              4 - Especificações do projeto
              {dados.has_order_material === 'false' && (
                <div className="div__moreOptions">
                  <button
                    type="button"
                    className="secondary-button-style"
                    onClick={() => {
                      let aux = listInverters;
                      aux.push({
                        project_id: '',
                        power_kwp: '',
                        model: '',
                        amount: '',
                      });

                      setListInverters([...aux]);

                      let aux2 = checkeds;
                      aux2.check_inverters.push(false);
                      setCheckeds({ ...aux2 });
                    }}
                  >
                    + Inversor
                  </button>
                </div>
              )}
            </h4>
            <div className="div__multiColumn" style={{ maxWidth: '700px' }}>
              <input
                className="input-custom-style "
                type="number"
                name="total_project_power"
                value={dados.total_project_power}
                min="0"
                step="0.01"
                placeholder="Potência total do projeto em kWp"
                style={{
                  maxWidth: window.innerWidth > 675 && '280px',
                }}
                onChange={onInputChange}
                required
              />
              <div className="pos_field">kWp</div>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: '505px' }}>
              <select
                className="input-custom-style "
                disabled={
                  dados.total_project_power < 41 ||
                  dados.total_project_power > 75
                }
                name="middlePower"
                value={voltageLevel}
                onChange={handleSetMiddlePower}
              >
                <option value={'BT'}>Baixa tensão</option>
                <option value={'MT'}>Média tensão</option>
              </select>
            </div>

            <div className="div__multiColumn" style={{ maxWidth: '505px' }}>
              <select
                className="input-custom-style "
                required
                name="has_order_material"
                style={{ flex: 1 }}
                value={dados.has_order_material}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Sobre os materiais ...
                </option>
                <option value={true}>Possuo pedido dos materiais</option>
                <option value={false}>
                  Não tenho pedido dos materiais realizado
                </option>
              </select>
            </div>
            {dados.has_order_material === 'false' && (
              <div className="div__multiColumn" style={{ maxWidth: '100%' }}>
                <input
                  className="input-custom-style "
                  type="text"
                  name="amount_panels"
                  value={dados.amount_panels}
                  placeholder="Qtd. de placas"
                  style={{ flex: 1.19 }}
                  onChange={onInputChange}
                  required
                />
                <div className="pos_field">Placa(s)</div>

                <input
                  className="input-custom-style "
                  type="number"
                  min="0"
                  step="0.01"
                  name="panel_power"
                  value={dados.panel_power}
                  placeholder="Potência"
                  style={{ flex: 1 }}
                  onChange={onInputChange}
                  required
                />

                <div className="pos_field">Wp</div>

                <input
                  className="input-custom-style "
                  type="text"
                  name="panel_model"
                  value={dados.panel_model}
                  placeholder="Modelo das placas"
                  style={{ flex: 2 }}
                  onChange={onInputChange}
                />

                <div className="div__field_check">
                  <input
                    className="input-custom-style check_panels"
                    type="checkbox"
                    id="check_panels"
                    name="check_panels"
                    checked={checkeds.check_panels}
                    onChange={() =>
                      setCheckeds({
                        ...checkeds,
                        check_panels: !checkeds.check_panels,
                      })
                    }
                  />
                  <label htmlFor="check_panels">Anexar datasheet</label>
                </div>
              </div>
            )}
            {checkeds.check_panels && (
              <>
                {!dados.document_panel_datasheet && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id="document_panel_datasheet"
                    name="document_panel_datasheet"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_panel_datasheet
                      ? 'div__newfieldarchive div__selectedFieldArchive'
                      : 'div__newfieldarchive'
                  }
                  htmlFor={
                    dados.document_panel_datasheet
                      ? ''
                      : 'document_panel_datasheet'
                  }
                  onClick={(event) => {
                    dados.document_panel_datasheet &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_panel_datasheet,
                        temp: 'document_panel_datasheet',
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: '1rem',
                              marginBottom: '-0.2rem',
                            }}
                          />{' '}
                          DATASHEET DAS PLACAS
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_panel_datasheet
                            ? dados.document_panel_datasheet.name
                            : '...'}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_panel_datasheet
                            ? (
                                dados.document_panel_datasheet.size /
                                (1024 * 1024)
                              ).toFixed(2) + 'Mb'
                            : '...'}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_panel_datasheet
                            ? dados.document_panel_datasheet.type.split('/')[1]
                            : '...'}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_panel_datasheet ? (
                        <div>
                          <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon />
                          Clique para anexar datasheet das placas
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {dados.has_order_material === 'false' &&
              listInverters.map((invert, idx) => (
                <>
                  <div
                    className="div__multiColumn"
                    style={{ maxWidth: '100%' }}
                  >
                    <input
                      className="input-custom-style "
                      type="text"
                      name="qtd_inverters"
                      value={invert.amount}
                      placeholder="Qtd. de inversores"
                      style={{
                        flex: 1,
                        maxWidth: window.innerWidth > 675 && '202px',
                      }}
                      onChange={(e) => {
                        let aux = listInverters;
                        aux[idx].amount = mask(e.target.value, '9999999');
                        setListInverters([...aux]);
                      }}
                      required
                    />
                    <div className="pos_field">Inversor(es)</div>

                    <input
                      className="input-custom-style "
                      type="number"
                      min="0"
                      step="0.01"
                      name="power_inverters"
                      value={invert.power_kwp}
                      placeholder="Potência"
                      style={{
                        flex: 1,
                        maxWidth: window.innerWidth > 675 && '202px',
                      }}
                      onChange={(e) => {
                        let aux = listInverters;
                        setListInverters([...aux]);
                        aux[idx].power_kwp = e.target.value;
                      }}
                      required
                    />
                    <div className="pos_field">kWp</div>

                    <input
                      className="input-custom-style "
                      type="text"
                      name="inverters_model"
                      value={invert.model}
                      placeholder="Modelo dos inversores"
                      style={{ flex: 2 }}
                      onChange={(e) => {
                        let aux = listInverters;
                        aux[idx].model = e.target.value;
                        setListInverters([...aux]);
                      }}
                    />

                    <div className="div__field_check">
                      <input
                        className="input-custom-style check_panels"
                        type="checkbox"
                        id={`check_inverters-${idx}`}
                        name={`check_inverters-${idx}`}
                        checked={checkeds.check_inverters[idx]}
                        onChange={() => {
                          let aux = checkeds;
                          aux.check_inverters[idx] =
                            !checkeds.check_inverters[idx];
                          setCheckeds({ ...aux });
                        }}
                      />
                      <label htmlFor={`check_inverters+${idx}`}>
                        Anexar datasheet
                      </label>
                    </div>
                  </div>
                  {checkeds.check_inverters[idx] && (
                    <>
                      {!listDatasheetInvert.find((a) => a.id === idx) && (
                        <input
                          className="input-custom-style "
                          type="file"
                          id={`document_inverters_datasheet-` + idx}
                          name={`document_inverters_datasheet-` + idx}
                          onChange={(event) => {
                            loaddatasheetinver(event, idx);
                          }}
                        />
                      )}

                      <label
                        className={
                          listDatasheetInvert.find((a) => a.id === idx)
                            ? 'div__newfieldarchive div__selectedFieldArchive'
                            : 'div__newfieldarchive '
                        }
                        htmlFor={
                          listDatasheetInvert.find((a) => a.id === idx)
                            ? ''
                            : `document_inverters_datasheet-` + idx
                        }
                        onClick={(event) => {
                          listDatasheetInvert.find((a) => a.id === idx) &&
                            setShowconf({
                              ...showconf,
                              status: true,
                              dados: listDatasheetInvert.find(
                                (a) => a.id === idx
                              ).file,
                              temp: `lista3_${idx}`,
                            });
                        }}
                      >
                        <div className="div__newfieldarchive_in">
                          <div className="div__dataArchive">
                            <AttachFileIcon />
                            <div className="div__info">
                              <p>
                                <LabelIcon
                                  style={{
                                    fontSize: '1rem',
                                    marginBottom: '-0.2rem',
                                  }}
                                />{' '}
                                DATASHEET DO INVERSOR
                              </p>
                              <p>
                                <b>Nome: </b>
                                {listDatasheetInvert.find((a) => a.id === idx)
                                  ? listDatasheetInvert.find(
                                      (a) => a.id === idx
                                    ).file.name
                                  : '...'}
                              </p>
                              <p>
                                <b>Tamanho: </b>
                                {listDatasheetInvert.find((a) => a.id === idx)
                                  ? (
                                      listDatasheetInvert.find(
                                        (a) => a.id === idx
                                      ).file.size /
                                      (1024 * 1024)
                                    ).toFixed(2) + 'Mb'
                                  : '...'}
                              </p>
                              <p>
                                <b>Tipo de arquivo: </b>
                                {listDatasheetInvert.find((a) => a.id === idx)
                                  ? listDatasheetInvert
                                      .find((a) => a.id === idx)
                                      .file.type.split('/')[1]
                                  : '...'}
                              </p>
                            </div>
                          </div>

                          <div className="div__env_archive">
                            {listDatasheetInvert.find((a) => a.id === idx) ? (
                              <div>
                                <DeleteIcon style={{ fontSize: '1.5rem' }} />{' '}
                                Remover arquivo
                                <br /> selecionado
                              </div>
                            ) : (
                              <div>
                                <BackupIcon />
                                Clique para anexar datasheet dos inversores
                                <br />
                                (.png .jpeg .pdf)
                              </div>
                            )}
                          </div>
                        </div>
                      </label>
                    </>
                  )}
                </>
              ))}

            {dados.has_order_material === 'true' && (
              <>
                {!dados.document_material && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id="document_material"
                    name="document_material"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_material
                      ? 'div__newfieldarchive div__selectedFieldArchive'
                      : 'div__newfieldarchive'
                  }
                  for="document_material"
                  onClick={(event) => {
                    dados.document_material &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_material,
                        temp: 'document_material',
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: '1rem',
                              marginBottom: '-0.2rem',
                            }}
                          />{' '}
                          ANEXAR PEDIDO DOS MATERIAIS
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_material
                            ? dados.document_material.name
                            : '...'}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_material
                            ? (
                                dados.document_material.size /
                                (1024 * 1024)
                              ).toFixed(2) + 'Mb'
                            : '...'}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_material
                            ? dados.document_material.type.split('/')[1]
                            : '...'}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_material ? (
                        <div>
                          <DeleteIcon style={{ fontSize: '1.5rem' }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon />
                          Clique para anexar o pedido dos materiais
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            <br />
            <h4>
              5 - Documentos complementares (opcional){' '}
              <div className="div__moreOptions">
                <button
                  type="button"
                  className="secondary-button-style"
                  onClick={() => {
                    let aux = listAdditional;
                    aux.push({
                      type: '',
                      other_type: '',
                      document_additional: '',
                    });

                    setListAdditional([...aux]);
                  }}
                >
                  + Documento
                </button>
              </div>
            </h4>

            {listAdditional.map((document, idx) => (
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={idx}
              >
                <div
                  style={{
                    textAlign: 'left',
                    margin: '0rem 2rem',
                  }}
                >
                  5.{idx + 1} - Documento {idx + 1}
                </div>
                <div className="div__multiColumn" style={{ maxWidth: '505px' }}>
                  <select
                    className="input-custom-style "
                    name="type_additional"
                    style={{ flex: 1 }}
                    value={document.type}
                    onChange={(a) => {
                      let aux = listAdditional;
                      aux[idx].type = a.target.value;
                      if (a.target.value !== 'outro') aux[idx].other_type = '';
                      setListAdditional([...aux]);
                    }}
                    required
                  >
                    <option value="" disabled selected>
                      Tipo de documento
                    </option>
                    <option value="procuração">Procuração</option>
                    <option value="documento com foto">
                      Documento com foto
                    </option>
                    <option value="outro">Outro</option>
                  </select>

                  <input
                    className="input-custom-style "
                    type="text"
                    name="other_type"
                    value={document.other_type}
                    placeholder="Qual?"
                    style={{
                      flex: 1,
                      maxWidth: window.innerWidth > 675 && '202px',
                      visibility:
                        document.type === 'outro' ? 'visible' : 'hidden',
                    }}
                    onChange={(a) => {
                      let aux = listAdditional;
                      aux[idx].other_type = a.target.value;
                      setListAdditional([...aux]);
                    }}
                    required={document.type === 'outro' ? true : false}
                  />
                </div>

                {!document.document_additional && (
                  <input
                    className="input-custom-style "
                    type="file"
                    id={`document_additional-${idx}`}
                    name={`document_additional-${idx}`}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        if (
                          event.target.files[0].type === 'application/pdf' ||
                          event.target.files[0].type === 'image/png' ||
                          event.target.files[0].type === 'image/jpeg'
                        ) {
                          let aux = listAdditional;
                          aux[idx].document_additional = event.target.files[0];
                          setListAdditional([...listAdditional]);
                        } else {
                          toast.error(
                            'Tipo de arquivo inválido, insira .pdf, .png ou .jpeg'
                          );
                        }
                      }
                    }}
                  />
                )}
                {document.type && (
                  <div className="div__remove_field_arch">
                    <button
                      type="button"
                      className={
                        document.document_additional
                          ? 'btn__remove_field btn__remove_field_disabled'
                          : 'btn__remove_field'
                      }
                      onClick={() => {
                        let aux = listAdditional;
                        aux.splice(idx, 1);
                        setListAdditional([...aux]);
                      }}
                    >
                      Remover campo
                    </button>
                  </div>
                )}
                {document.type && (
                  <label
                    className={
                      document.document_additional
                        ? 'div__newfieldarchive div__selectedFieldArchive'
                        : 'div__newfieldarchive'
                    }
                    htmlFor={
                      document.document_additional
                        ? ''
                        : `document_additional-${idx}`
                    }
                    onClick={(event) => {
                      document.document_additional &&
                        setShowconf({
                          ...showconf,
                          status: true,
                          dados: listAdditional[idx].document_additional,
                          temp: `lista2_${idx}`,
                        });
                    }}
                  >
                    <div className="div__newfieldarchive_in">
                      <div className="div__dataArchive">
                        <AttachFileIcon />
                        <div className="div__info">
                          <p style={{ textTransform: 'uppercase' }}>
                            <LabelIcon
                              style={{
                                fontSize: '1rem',
                                marginBottom: '-0.2rem',
                              }}
                            />{' '}
                            {document.type ? document.type : ' . . . '}{' '}
                            {document.other_type
                              ? `(${document.other_type})`
                              : ''}
                          </p>
                          <p>
                            <b>Nome: </b>
                            {document.document_additional
                              ? document.document_additional.name
                              : '...'}
                          </p>
                          <p>
                            <b>Tamanho: </b>
                            {document.document_additional
                              ? (
                                  document.document_additional.size /
                                  (1024 * 1024)
                                ).toFixed(2) + 'Mb'
                              : '...'}
                          </p>
                          <p>
                            <b>Tipo de arquivo: </b>
                            {document.document_additional
                              ? document.document_additional.type.split('/')[1]
                              : '...'}
                          </p>
                        </div>
                      </div>

                      <div className="div__env_archive">
                        {document.document_additional ? (
                          <div>
                            <DeleteIcon style={{ fontSize: '1.5rem' }} />{' '}
                            Remover arquivo
                            <br /> selecionado
                          </div>
                        ) : (
                          <div>
                            <BackupIcon /> Clique para anexar a conta de energia
                            <br />
                            (.png .jpeg .pdf)
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                )}
              </div>
            ))}

            <h4>6 - Observações (opcional)</h4>
            <div className="div__multiColumn" style={{ maxWidth: '700px' }}>
              <textarea
                rows={8}
                cols={50}
                className="textarea-custom-style"
                name="comments"
                value={dados.comments}
                placeholder="Observações"
                onChange={onInputChange}
              />
            </div>

            <button
              type="submit"
              className="primary-button-style"
              style={{ margin: '1rem auto' }}
            >
              Prosseguir
            </button>
            <br />
          </form>
        </content>
      </Page>

      {/* campos de dicas */}
      <Popover
        id={'dica__coordenada'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '2rem', maxWidth: '450px' }}>
          <Containerdica>
            <h3>
              Como encontrar a coordenada do projeto <EmojiObjectsIcon />
            </h3>
            <h4>1º - Acesso o site do Google Maps</h4>
            <span>
              Link:{' '}
              <a
                href="https://www.google.com.br/maps"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com.br/maps
              </a>
            </span>
            <h4>2º Busque pela localização desejada no campo abaixo</h4>
            <img src={Imgcoordinate} alt={'Coordinate'} />
            <h4>
              3º Demarque o ponto no mapa cliando com botão esquerdo do{' '}
              <i>mouse</i>
            </h4>
            <img src={Imgcoordinate2} alt={'Coordinate 2'} />
            <h4>
              4º Clique com botão direito do <i>mouse</i> e em seguida nas{' '}
              coordenadas para serem automaticamente copiadas
            </h4>
            <img src={Imgcoordinate3} alt={'Coordinate 3'} />
          </Containerdica>
        </div>
      </Popover>

      {showconf.status && (
        <DialogConfirmation
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({ ...showconf, dados: '', status: false, temp: '' })
          }
          confirmar={() => {
            let aux = showconf.temp.split('_');

            if (aux[0] === 'lista') {
              if (listRateios[aux[1]].document_rateio) {
                toast.success(
                  `Arquivo ${showconf.dados.name} removido com sucesso`
                );
                setTimeout(() => {
                  let aux2 = listRateios;
                  aux2[aux[1]].document_rateio = '';
                  setListRateios([...aux2]);
                }, 100);
                setShowconf({
                  ...showconf,
                  dados: '',
                  status: false,
                  temp: '',
                });
              }
            } else if (aux[0] === 'lista2') {
              if (listAdditional[aux[1]].document_additional) {
                toast.success(
                  `Arquivo ${showconf.dados.name} removido com sucesso`
                );

                setTimeout(() => {
                  let aux2 = listAdditional;
                  aux2[aux[1]].document_additional = '';
                  setListAdditional([...aux2]);
                }, 100);

                setShowconf({
                  ...showconf,
                  dados: '',
                  status: false,
                  temp: '',
                });
              }
            } else if (aux[0] === 'lista3') {
              let indice = listDatasheetInvert.indexOf(
                listDatasheetInvert.find((a) => a.id === aux[1])
              );

              if (
                listDatasheetInvert.find(
                  (valor) => '' + valor.id === '' + aux[1]
                )
              ) {
                toast.success(
                  `Arquivo ${showconf.dados.name} removido com sucesso`
                );

                setTimeout(() => {
                  let aux2 = listDatasheetInvert;
                  aux2.splice(indice, 1);
                  setListdatasheetInvert([...aux2]);
                }, 100);

                setShowconf({
                  ...showconf,
                  dados: '',
                  status: false,
                  temp: '',
                });
              }
            } else {
              if (showconf.dados) {
                setTimeout(() => {
                  toast.success(
                    `Arquivo ${showconf.dados.name} removido com sucesso`
                  );
                  setDados({ ...dados, [showconf.temp]: '' });
                  setShowconf({
                    ...showconf,
                    dados: '',
                    status: false,
                    temp: '',
                  });
                }, 100);
              }
            }
          }}
        />
      )}
      {finish && (
        <DialogProjectConfirmation
          dados={{ ...dados, voltageLevel }}
          credits={credits}
          setCredits={(credit_erd) => setCredits(credit_erd)}
          listinverters={listInverters}
          fechar={() => setFinish(false)}
          listRateios={listRateios}
          listAdditional={listAdditional}
          setDescontos={setDescontos}
          confirmar={() => enviarProjeto()}
        />
      )}
      {showcoord && (
        <DialogFindLocation
          fechar={() => setShowcoord(false)}
          dados={dados}
          confirmar={(a) =>
            setDados({
              ...dados,
              longitude: a.lng,
              latitude: a.lat,
            })
          }
        />
      )}

      {!completedData && <DialogCompleteData />}
      {showbackdrop && <Backdrop />}
    </Container>
  );
}
