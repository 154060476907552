import { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Imgtrocarsenha from '../../../assets/images/icons/trocar_senha.png';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';

import { Content } from './styles';

export default function DialogChangePassword({ negar, confirmar }) {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState({
    old_password: false,
    new_password: false,
    password_confirmation: false,
  });

  const [data, setData] = useState({
    old_password: '',
    new_password: '',
    password_confirmation: '',
  });

  const handleClose = () => {
    setOpen(false);
    negar();
  };

  const validatePassword = () => {
    if (data.new_password.length > 0) {
      if (data.new_password.length > 5) {
        if (data.new_password === data.password_confirmation) {
          confirmar(data);
        } else {
          toast.warn('Suas senhas não correspondem.');
          return false;
        }
      } else {
        toast.warn('Sua senha deve conter, no mínimo, 6 caractesres.');
        return false;
      }
    } else {
      toast.warn('Preencha corretamente o campo indicado com sua nova senha.');
      return false;
    }
  };

  return (
    <Dialog
      fullWidth={window.innerWidth < 675 ? true : false}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      style={{
        zIndex: 9999,
      }}
    >
      <DialogTitle
        id="max-width-dialog-title"
        style={{ fontFamily: "'Poppins', sans-serif !important" }}
      >
        Trocar Senha {data.name ? data.name : data.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Content>
            <img src={Imgtrocarsenha} alt="Trocar senha" />
            <div className="div__one_row">
              <input
                autoFocus
                type={show.old_password ? 'text' : 'password'}
                placeholder="Digite a nova senha"
                value={data.old_password}
                onChange={(a) =>
                  setData({ ...data, old_password: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    old_password: !prev.old_password,
                  }))
                }
              >
                {!show.old_password ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </button>
            </div>

            <div className="div__one_row">
              <input
                autoFocus
                type={show.new_password ? 'text' : 'password'}
                placeholder="Digite a nova senha"
                value={data.new_password}
                onChange={(a) =>
                  setData({ ...data, new_password: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    new_password: !prev.new_password,
                  }))
                }
              >
                {!show.new_password ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </button>
            </div>

            <div className="div__one_row">
              <input
                type={show.password_confirmation ? 'text' : 'password'}
                placeholder="Confirme a senha"
                value={data.password_confirmation}
                onChange={(a) =>
                  setData({ ...data, password_confirmation: a.target.value })
                }
              />{' '}
              <button
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    password_confirmation: !prev.password_confirmation,
                  }))
                }
              >
                {!show.password_confirmation ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </button>
            </div>
            <ul>
              <li>Senha deve conter no mínimo 6 caracteres</li>
              <li>
                Para uma senha forte, é aconselhável que contenha números,
                letras e caracteres especiais
              </li>
              <li>
                Sua senha e credenciais de acesso são de uso pessoal, evite
                informar a terceiros
              </li>
            </ul>
          </Content>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => negar()} color="" variant="contained">
          Cancelar
        </Button>
        <Button
          onClick={() => validatePassword()}
          variant="contained"
          style={{
            backgroundColor: 'var(--primary2)',
            color: '#fff',
          }}
        >
          Substituir senha
        </Button>
      </DialogActions>
    </Dialog>
  );
}
