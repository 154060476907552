export const API_URL = process.env.REACT_APP_API_URL;

export const SITE_NAME = process.env.REACT_APP_SITE_NAME;
export const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;

export const listetapas = [
  '1 - Análise de documentos pela equipe',
  '2 - Projeto em elaboração',
  '3 - Em análise na concessionária de energia',
  '4 - Impedimentos',
  '5 - Projeto aprovado',
  '6 - Vistoria solicitada',
  // '7 - Projeto reprovado',
];
