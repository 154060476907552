import React from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Container, FielButtons } from './styles';

export default function DialogCompleteData(props) {
  let navigate = useNavigate();

  return (
    <div>
      <Dialog
        open
        maxWidth={'sm'}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: 'var(--primary2)',
          }}
        >
          Completar cadastro
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            <Container>
              <div className="div__msgs">
                <h1>Ops!</h1>
                <p>
                  Finalize seu cadastro preenchendo as informaçoes que faltam
                  para poder solicitar seu primeiro projeto.
                </p>
              </div>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons delete={true}>
            <button
              onClick={() => {
                navigate('/configuracoes');
                props.fechar && props.fechar();
              }}
              className="btn__register"
            >
              Completar cadastro
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
