import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  /* background: red; */
  /* background-image: url(${(props) => props.bg1}); */
  background-size: cover;
  background-position: center;

  .div__gradient {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    /* background-image: linear-gradient(
      to right bottom,
      rgba(var(--primary2_rgb), 1) 40%,
      rgba(var(--primary2_rgb), 0.6)
    ); */
    background-color: var(--primary);
    width: 100%;
    height: 100%;
  }

  .div__corpo {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    max-width: 1920px;
    margin: auto;
    padding: 2rem 0rem;

    .div__content-wraper {
      width: 0;
      margin-inline: auto;
      padding: 4rem 0rem;
      height: 100%;
      background-color: var(--white);
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.1);

      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 5rem;
      overflow: hidden;

      animation: initialAnimation ease-in-out 1s 2s forwards;
    }

    .div__part1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      opacity: 0;

      animation: fadeIn 1s 3s forwards;

      h3 {
        color: var(--primary);
        width: min(100%, 450px);
        font-weight: bold;
        font-size: 2rem;
        font-style: italic;
      }
      img {
        width: 100%;
        max-width: 450px;
        margin: 1rem auto;
      }
      p {
        color: var(--white);
        max-width: 450px;
        padding: 1rem;
      }
    }
    .div__part2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      opacity: 0;
      animation: fadeIn 1s 3s forwards;
    }

    form {
      /* background-color: var(--white);
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.1);
       */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      width: 100%;
      min-height: 50%;
      padding: 2rem 1rem;

      .img__logo_mobile {
        display: none;
      }

      h1 {
        /* text-transform: uppercase; */
        font-weight: bold;
        margin: 0rem;
        color: var(--primary2);
      }
      span {
        font-size: 0.8rem;
        max-width: 280px;
        color: rgba(var(--black_rgb), 0.7);
        margin-bottom: 1rem;
        margin-top: 0.5rem;
      }

      .div__functions {
        display: flex;
        width: 100%;
        max-width: 320px;
        justify-content: space-between;

        label,
        button {
          display: flex;
          font-size: 0.8rem;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);
          outline: none;
          border: none;

          input {
            width: 15px;
            margin: 0rem;
            margin-right: 0.2rem;
          }
        }

        a {
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }

      .btn__login {
      }
      .div__subtext {
        display: flex;
        width: 100%;
        max-width: 320px;
        label {
          display: flex;
          font-size: 0.8rem;
          text-align: center;
          margin: 0.5rem auto;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);
        }
      }
      .div__line {
        width: 100%;
        border-bottom: 2px dotted rgba(var(--black_rgb), 0.3);
        max-width: 320px;
        margin: 0.5rem;
      }
    }
  }

  @media only screen and (max-width: 57.25rem) {
    button {
      font-size: 1rem;
    }

    .div__corpo {
      flex-direction: column;
      flex: 1;
      padding: 0rem 0rem;

      .div__content-wraper {
        flex-direction: column;
        width: min(100%, 100%);
        padding: 1rem;
        height: 100vh;
        /* margin: 0; */
      }

      .div__part1 {
        display: none;
      }
      .div__part2 {
        flex: 1;
        form {
          flex: 1;
          margin: 0rem;
          max-width: none;

          .img__logo_mobile {
            display: block;
            max-width: 250px;
            height: auto;
            padding: 1rem 0rem;
          }
          input[type="email"],
          input[type="password"] {
            padding: 1rem;
            font-size: 1rem;
          }
        }
      }
    }
  }

  @keyframes initialAnimation {
    0% {
      width: 0rem;
    }
    100% {
      width: min(100% - 2rem, 66.75rem);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
