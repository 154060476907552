import React from 'react';
import BackdropComp from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 999995,
    color: '#fff',
  },
}));

export default function Backdrop(props) {
  const classes = useStyles();

  return (
    <div style={{ zIndex: 999999 }}>
      <BackdropComp className={classes.backdrop} open>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          {props.message && (
            <h1
              style={{ color: 'white', maxWidth: '500px', textAlign: 'center' }}
            >
              {props.message}
            </h1>
          )}
        </div>
      </BackdropComp>
    </div>
  );
}
