import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { generatePrice } from '../../../utils';
import Image03 from "../../../assets/images/prints/money_03.png";
import solarImage from "../../../assets/images/prints/solar-image.png";
import api from "../../../services/api";
import { Backdrop } from "../../../components";
import { useAuth } from "../../../contexts/AuthContext";

import { Container, Page, FieldButtons } from "./styles";

export default function Users(props) {
  const navigate = useNavigate();
  let { user } = useAuth();

  const [listElectricCompanies, setListElectricCompanies] = useState([]);
  const [showPrice, setshowPrice] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [voltageLevel, setVoltageLevel] = useState("BT");

  const [data, setData] = useState({
    state: "",
    concessionaria: "",
    total_project_power: "",
    price: "",
    descont: 0,
  });

  const getElectricCompanies = async (value) => {
    setShowBackdrop(true);

    await api
      .get(`/dealers/state/${value}`)
      .then((res) => {
        setListElectricCompanies(res.data);
        setShowBackdrop(false);
      })
      .catch((error) => {
        console.error("Error: ", error.response.data);
        setShowBackdrop(false);
      });
  };

  const submitInfor = (event) => {
    event.preventDefault();
    if (data.total_project_power === 0)
      return toast.warn("Potência total do projeto não pode ser 0kWp");

    let valor = getValuePrice(data.total_project_power);

    let budget = {
      dealer_id: listElectricCompanies.find(
        (a) => a.name === data.concessionaria,
      ).id,
      power: data.total_project_power,
      price: parseFloat(valor),
      user_id: user?.id,
      name: null,
      email: null,
      voltage_level: voltageLevel,
    };

    api
      .post("budgeting", budget)
      .then((res) => {})
      .catch((error) => console.error(error.response.data));
  };

  const getValuePrice = (total_project_power) => {
    const price = generatePrice(total_project_power);
    if (!price) {
      return toast.warn(
        `Valor "${total_project_power}" não tabelado. Entre em contato.`
      );
    }

    setData({ ...data, price: !!price ? price : 0 });
    setshowPrice(true);
    return price;
  };
  const onInputChange = (e) => {
    if (e.target.name === "total_project_power") {
      if (e.target.value >= 0) {
        if (e.target.value > 1000) {
          return toast.warn("Valor máximo de 1000kWp");
        }
        if (e.target.value <= 40) {
          setVoltageLevel("BT");
        }

        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "state") {
      getElectricCompanies(e.target.value);
      setData({ ...data, [e.target.name]: e.target.value });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleSetMiddlePower = (event) => {
    setVoltageLevel(event.target.value);
  };

  return (
    <Container>
      <Page>
        <header>
          <h1>Orçamento</h1>
          <div className='div__divider' />
          <span>
            Faça um orçamento online instantaneamente e solicite seu projeto.
          </span>
        </header>
        <content>
          <div
            className='div__multiColumn'
            style={{ margin: "auto", maxWidth: "1100px", flexWrap: "wrap" }}
          >
            <div className='div__multiColumn'>
              <img
                src={Image03}
                alt='img_orcamento'
                className='img_orcamento'
              />
            </div>
            <div className='div__oneRow' style={{ flex: 1 }}>
              <form onSubmit={submitInfor}>
                <div style={{ maxWidth: "400px" }}>
                  <div className='div__multiColumn'>
                    <select
                      className='input-custom-style'
                      required
                      name='state'
                      style={{ flex: 1 }}
                      value={data.state}
                      onChange={onInputChange}
                      disabled={showPrice ? true : false}
                    >
                      <option value='' disabled selected>
                        Estado
                      </option>
                      <option value='AC'>AC</option>
                      <option value='AL'>AL</option>
                      <option value='AP'>AP</option>
                      <option value='AM'>AM</option>
                      <option value='BA'>BA</option>
                      <option value='CE'>CE</option>
                      <option value='DF'>DF</option>
                      <option value='ES'>ES</option>
                      <option value='GO'>GO</option>
                      <option value='MA'>MA</option>
                      <option value='MT'>MT</option>
                      <option value='MS'>MS</option>
                      <option value='MG'>MG</option>
                      <option value='PA'>PA</option>
                      <option value='PB'>PB</option>
                      <option value='PR'>PR</option>
                      <option value='PE'>PE</option>
                      <option value='PI'>PI</option>
                      <option value='RJ'>RJ</option>
                      <option value='RN'>RN</option>
                      <option value='RS'>RS</option>
                      <option value='RO'>RO</option>
                      <option value='RR'>RR</option>
                      <option value='SC'>SC</option>
                      <option value='SP'>SP</option>
                      <option value='SE'>SE</option>
                      <option value='TO'>TO</option>
                    </select>
                  </div>
                  <div className='div__multiColumn'>
                    <select
                      className='input-custom-style'
                      required
                      disabled={showPrice ? true : false}
                      name='concessionaria'
                      style={{ flex: 2 }}
                      value={data.concessionaria}
                      onChange={onInputChange}
                    >
                      {!data.concessionaria && (
                        <option value='' disabled selected>
                          Nome da concessionária
                        </option>
                      )}
                      {data.concessionaria && (
                        <option
                          value={data.concessionaria}
                          disabled
                          selected
                          style={{ textTransform: "cap" }}
                        >
                          {data.concessionaria}
                        </option>
                      )}
                      {listElectricCompanies.length > 0 &&
                        listElectricCompanies.map((concess, idx) => (
                          <option key={idx} value={concess.name}>
                            {concess.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='div__multiColumn'>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        type='number'
                        min='0'
                        name='total_project_power'
                        value={data.total_project_power}
                        placeholder='Potência total do projeto'
                        className='input__number input-custom-style'
                        step='0.01'
                        style={{
                          flex: 1,
                          width: "100%",
                        }}
                        onChange={onInputChange}
                        disabled={showPrice ? true : false}
                        required
                      />
                      <div className='pos_field'>kWp</div>
                    </div>
                  </div>
                  <div className='div__multiColumn'>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <select
                        className='input-custom-style'
                        disabled={
                          data.total_project_power < 41 ||
                          data.total_project_power > 75 ||
                          showPrice
                        }
                        name='middlePower'
                        value={voltageLevel}
                        onChange={handleSetMiddlePower}
                      >
                        <option value={"BT"}>Baixa tensão</option>
                        <option value={"MT"}>Média tensão</option>
                      </select>
                    </div>
                  </div>
                  <button
                    type='submit'
                    className={
                      showPrice
                        ? "btn__finish_disabled primary-button-style"
                        : " primary-button-style "
                    }
                  >
                    Realizar Orçamento
                  </button>
                </div>
              </form>
            </div>
          </div>
          {showPrice && (
            <div className='div__image_solar'>
              <img src={solarImage} alt='panel_solar_image' />
            </div>
          )}
          {showPrice &&
            (data.total_project_power > 75 ? (
              <div className='div__orcamento_field'>
                <p>
                  Nosso sistema fornece orçamento online para projetos de até
                  75kWp, para potências superiores sugerimos que{" "}
                  <b>entre em contato</b> e solicite seu orçamento
                  personalizado.
                </p>
              </div>
            ) : (
              <div className='div__orcamento_field'>
                <p>Seu projeto custará</p>
                <h2>R$ {data.price}</h2>

                <p>Garanta seu projeto hoje mesmo!</p>
                <br />

                <FieldButtons delete={props.delete}>
                  <button
                    onClick={() => setshowPrice(false)}
                    className='secondary-button-style'
                    color='primary'
                  >
                    Refazer orçamento
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/solicitar-projeto?state=${data.state}&concessionaria=${data.concessionaria}&total_project_power=${data.total_project_power}`,
                      )
                    }
                    className='primary-button-style'
                  >
                    Solicitar projeto
                  </button>
                </FieldButtons>
              </div>
            ))}
        </content>
      </Page>
      {showBackdrop && <Backdrop />}
    </Container>
  );
}
