import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-right: 2rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: var(--paper-color);
  box-shadow: var(--wrapper-box-shadow);
  border-radius: var(--border-radius);
  border: var(--paper-border);
  min-height: 12.5rem;

  header {
    padding: 2rem;
    text-align: left;

    h1 {
      font-weight: 600;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
      margin: 0;
    }
    .div__divider {
      width: 4rem;
      height: 0.25rem;
      border-radius: var(--border-radius);
      margin-top: 1rem;
      margin-bottom: 0.5rem;

      background: linear-gradient(to right, var(--primary2), var(--primary));
    }
    span {
      opacity: 0.7;
      font-size: 14px;
    }

    margin-bottom: 2rem;
  }

  .div__conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;

    .div__alertas {
      font-family: "Poppins", sans-serif;
      margin-bottom: 1rem;
      h3 {
        margin: 0rem;
        text-align: left;
        font-weight: 600;
      }
    }

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      padding: 0.25rem 0rem;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        font-size: 2rem;
        margin: auto 0;
        margin-left: 0.25rem;
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary2)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: "Poppins", sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: var(--border-radius);

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary),
          var(--primary)
        );
      }
    }

    @media screen and (max-width: 675px) {
      .div__oneRow {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
