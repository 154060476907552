import Tooltip from '@material-ui/core/Tooltip';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

export default function Copy(props) {
  const { name, data } = props;

  return (
    <Tooltip title="Copiar" placement="right-start">
      <FileCopyIcon
        name="copy"
        onClick={() => {
          copy(data);
          toast.info(`"${name}" copiado para a área de transferência`);
        }}
      />
    </Tooltip>
  );
}
