import { useState, useMemo } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { BsFillPinFill, BsPinAngleFill } from "react-icons/bs";

import DisplayPosition from './DisplayPosition';
import { Container } from "./styles";

export default function Map(props) {
  const [map, setMap] = useState(null);

  const [provider] = useState({
    id: 1,
    latitude: props.dados.latitude ? props.dados.latitude : -1.45502,
    longitude: props.dados.longitude
      ? props.dados.longitude
      : -48.5024,
    zoom: props.dados.longitude && props.dados.latitude ? 18 : 3,
    state: 'PA',
    city: 'Belém',
  });

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[provider.latitude, provider.longitude]}
        zoom={provider.zoom}
        scrollWheelZoom={true}
        whenCreated={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
      </MapContainer>
    ),
    [], // eslint-disable-line
  );

  return (
    <Container BsPin={BsFillPinFill}>
      {map ? (
        <DisplayPosition
          map={map}
          onChangeLocation={props.onChangeLocation}
        />
      ) : null}
      {displayMap}
      <div className='div__mapa'>
        <BsFillPinFill className='icon__marker' />
        <BsPinAngleFill className='icon__shadow' />
      </div>
    </Container>
  );
}
