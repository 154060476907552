import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Logo2 from "../../../assets/images/logo/LOGO - LUMEN PRO - AZUL.png";

import { useAuth } from "../../../contexts/AuthContext";
import { DialogRecoverPassword } from "../../../components";
import { SITE_NAME } from "../../../config";
import { Container } from "./styles";

export default function Login() {
  const [recoverPassword, setRecoverPassword] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const [qmessage] = useState(query.get("message"));

  const { signIn } = useAuth();

  const [dados, setDados] = useState({
    email: "",
    password: "",
    remember: true,
  });

  function sendLogin(event) {
    event.preventDefault();

    const requestBody = {
      email: dados.email.trim(),
      password: dados.password,
    };

    signIn(requestBody, dados.remember);
  }

  useEffect(() => {
    toast.success(qmessage);
  }, [qmessage]);

  return (
    <Container>
      <div className="div__gradient">
        <div className="div__corpo">
          <div className="div__content-wraper">
            <div className="div__part1">
              <img
                src={Logo2}
                title={`Logo ${SITE_NAME}`}
                className="img__logo_mobile"
                alt={`Logo ${SITE_NAME}`}
              />
            </div>
            <div className="div__part2">
              <form onSubmit={sendLogin}>
                <img
                  src={Logo2}
                  title={`Logo ${SITE_NAME}`}
                  className="img__logo_mobile"
                  alt={`Logo ${SITE_NAME}`}
                />

                <h1>Painel Lumen Pro</h1>
                <span>
                  Faça login para acompanhar o andamento dos seus projetos
                  registrados.
                </span>
                <input
                  className="input-custom-style"
                  type="email"
                  placeholder="E-mail"
                  value={dados.email}
                  autoFocus
                  onChange={(e) =>
                    setDados({ ...dados, email: e.target.value.toLowerCase() })
                  }
                />
                <input
                  className="input-custom-style"
                  type="password"
                  placeholder="Senha"
                  value={dados.password}
                  onChange={(e) =>
                    setDados({ ...dados, password: e.target.value })
                  }
                />
                <div className="div__functions">
                  <label>
                    <input
                      type="checkbox"
                      checked={dados.remember}
                      onChange={() =>
                        setDados({ ...dados, remember: !dados.remember })
                      }
                    />
                    Lembrar-me
                  </label>
                  <button
                    style={{ cursor: "pointer" }}
                    type="button"
                    onClick={() => setRecoverPassword(true)}
                  >
                    Recuperar senha
                  </button>
                </div>
                <button
                  className="btn__login primary-button-style"
                  type="submit"
                >
                  Login
                </button>
                <div className="div__line" />
                <div className="div__subtext">
                  <label>Novo usuário? Realize seu cadastro</label>
                </div>

                <Link to="/cadastro" style={{ width: "100%" }}>
                  <button
                    className="btn__register secondary-button-style"
                    type="button"
                  >
                    Cadastrar
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      {recoverPassword && (
        <DialogRecoverPassword fechar={() => setRecoverPassword(false)} />
      )}
    </Container>
  );
}
