import { useCallback, useMemo, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import {
  Container,
  FieldButtons,
  DocumentDataWrapper,
  DocumentDataTitle,
} from './styles';

export default function DialogDocumentData(props) {
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  }, [props.fechar]);

  const rawDocumentData = useMemo(() => {
    const isObject = typeof props.data === 'object';
    if (isObject) {
      return props.data;
    }

    const isString = typeof props.data === 'string';
    if (isString) {
      return JSON.parse(props.data);
    }
  }, [props.data]);

  const DocumentData = useMemo(() => {
    switch (rawDocumentData.type) {
      case 'document_energy':
        return () => (
          <DocumentDataWrapper>
            <div>
              <DocumentDataTitle>Número da Unidade Consumidora</DocumentDataTitle>
              <span>{rawDocumentData.consumerUnitNumber}</span>
            </div>
            <div>
              <DocumentDataTitle>Endereço Completo</DocumentDataTitle>
              <span>{rawDocumentData.fullAddress}</span>
            </div>
            <div>
              <DocumentDataTitle>CEP</DocumentDataTitle>
              <span>{rawDocumentData.zipCode}</span>
            </div>
            <div>
              <DocumentDataTitle>Cidade</DocumentDataTitle>
              <span>{rawDocumentData.city}</span>
            </div>
            <div>
              <DocumentDataTitle>Número</DocumentDataTitle>
              <span>{rawDocumentData.number}</span>
            </div>
          </DocumentDataWrapper>
        )
      default:
        return () => (<></>);
    }
  }, [rawDocumentData]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Visualizar dados do documento</h1>
              </header>

              <DocumentData />
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FieldButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FieldButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
