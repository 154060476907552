import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Container, FielButtons } from './styles';

export default function DialogTermsOfUse(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Visualizar Termos de Política de Privacidade</h1>
                <span>Confira abaixo o arquivo de termo de uso.</span>
              </header>

              <div
                style={{
                  height: '70vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <iframe
                  title="terms-of-use"
                  src="/terms.pdf"
                  width="640"
                  height="480"
                  style={{ width: '100%', border: 'none' }}
                  allow="autoplay"
                ></iframe>
              </div>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
