import styled from "styled-components";

export const Container = styled.div`
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */

  form {
    /* border: 2px solid var(--primary2); */
    display: flex;
    flex-direction: column;
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    input {
      width: auto;
      border-radius: 0;
    }
    select {
      width: auto;
      height: 40px;
      border-radius: 0;
    }

    label {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: auto;
      font-weight: 400;
    }
    .label__validate_code {
      max-width: 150px;
    }
    .div__contains_card_code_image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .img__security_code {
      max-width: 100px;
      height: auto;
    }

    .div__important_notes {
      padding: 1rem;
      p {
        font-size: 0.75rem;
      }
    }

    .btn__confirm_payment {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      margin: auto;
      width: 250px;
      height: 45px;
      background-color: var(--primary2);
      border: 0px;
      border-radius: var(--border-radius);
      color: var(--white);
      /* margin-top: 1rem; */
      /* margin-bottom: 0.75rem; */
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
        filter: brightness(1.4);
      }
    }
  }

  @media screen and (max-width: 675px) {
    input,
    select {
      font-size: 1rem;
    }
    .div__contains_card_code_image {
      img {
        margin: 2rem auto;
      }
    }
    .label__validate_code {
      max-width: 100% !important;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
