import styled from "styled-components";

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100vh;
  max-width: ${(props) => (props.open ? "240px" : "72px")};
  background: var(--primary2);
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  transition: all 0.5s;

  .contentTexts {
    display: none !important;
  }

  .seta {
    z-index: 1100000;
    display: flex;
    justify-content: ${(props) => (props.open ? "flex-end" : "center")};
    align-items: center;
    min-height: 68px;
    width: 100%;
    border: 0;

    background: ${(props) =>
      props.open ? "var(--primary2)" : "var(--primary)"};

    border-bottom: ${(props) =>
      props.open ? "none" : "1px dashed rgba(0, 0, 0, 0.12)"};

    svg {
      cursor: pointer;

      color: var(--white);
      border-radius: ${(props) => (props.open ? "0" : "var(--border-radius)")};
      padding: 0.75rem;

      :hover {
        background: ${(props) => (props.open ? "var(--gray)" : "normal")};
        color: ${(props) => (props.open ? "var(--primary2)" : "var(--gray)")};
        border-radius: var(--border-radius);
      }
    }

    transition: width 1s;
    /* 
    ::after {
      content: '';
      
      position: absolute;
      left: 72px;
      width: ${(props) => (props.open ? "0px" : "1px")};
      height: 70px;
      background: rgb(241, 241 ,241);
    } */

    .containerImg2 {
      display: none;
    }
  }

  .containerImg {
    display: ${(props) => (props.open ? "block" : "none")};
    padding: 1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 16px;
    text-align: left;
    color: var(--white);
    font-weight: 600;
    margin-left: 1rem;
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
  }

  .p__description {
    font-size: 0.75rem;
    color: var(--white);
    width: min(100% - 2rem, 20rem);

    font-family: var(--font-family) !important;
    /* display: ${(props) => (props.open ? "auto" : "none")}; */
    overflow: hidden;
    max-height: ${(props) => (props.open ? "3rem" : "0rem")};
    margin-left: ${(props) => (props.open ? "1rem" : "-100%")};
    transition: margin-left 1s;
    transition: max-height 1.25s 0.3s;
  }

  nav {
    padding: 0;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin: 0.25rem;

      p {
        display: ${(props) => (props.open ? "flex" : "none")};
        flex-direction: column;
        gap: 4px;
        font-size: 16px;
        font-family: var(--font-family) !important;
        color: var(--white);
        font-weight: 500;

        transition: display 2s;

        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }

      svg {
        padding: 0.75rem 1rem;
        margin: 0 0.75rem 0 0.5rem;
        font-size: 1.4rem;
        color: var(--white);
      }

      transition: all 0.2s;

      &.active {
        color: var(--primary2);
        background: rgba(0, 0, 0, 0.1);
        /* border-left: 3px solid var(--primary2); */
        border-radius: var(--border-radius);
        margin: 0.25rem;

        svg {
          color: var(--white);
        }
      }

      :hover {
        color: var(--primary2);
        background: rgba(0, 0, 0, 0.1);
        /* border-left: 3px solid var(--primary2); */
        border-radius: var(--border-radius);
        margin: 0.25rem;

        svg {
          color: var(--white);
        }
      }
    }
  }

  /* QUERY MEDIA */
  @media screen and (max-width: 675px) {
    position: fixed !important;
    z-index: 99999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: ${(props) => (props.open ? "scroll !important" : "hidden")};

    width: ${(props) => (props.open ? "100vw" : "0px")};
    max-width: 100vw;
    margin-left: ${(props) => (props.open ? "0rem" : "-110vw")};
    /* height: ${(props) => (props.open ? "100vh" : "70px")}; */

    transition: all 1s !important;

    .p__description {
      margin-inline: auto;
    }

    .seta {
      transition: 1s;
      position: ${(props) => (props.open ? "absolute" : "fixed")};
      left: 0;
      top: 0;
      max-width: ${(props) => (props.open ? "100vw" : "5rem")};
      height: 3.5rem;

      svg {
        color: ${(props) => (!props.open ? "var(--primary2)" : "var(--white)")};
      }
      .containerImg2 {
        display: ${(props) => (props.open ? "block !important" : "none")};
        width: 100%;
        margin-top: 5rem;

        img {
          max-width: 300px;
        }
      }

      ::after {
        display: none;
      }
    }

    .containerImg {
      display: none !important;
    }

    .contentTexts {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      margin-top: 10rem;

      p {
        font-size: 1rem !important;
        font-weight: 500;
        margin: 0;
        color: var(--white);
      }

      strong {
        font-size: 1rem;
        font-family: var(--font-family);
        color: var(--white);
      }

      .btn__logout {
        padding: 0.5rem 3rem;
        font-size: 1rem;
        border: 0;
        outline: none;
        margin-top: 1rem;
        border-radius: var(--border-radius);
        color: var(--brown);
      }
    }

    h4 {
      display: none;
    }

    nav {
      margin-top: 2rem;
      padding-bottom: 2rem !important;

      a {
        padding: 1rem 0;
      }
    }
  }
`;

export const Conteudo = styled.div`
  /* background-color: red !important; */
`;

export const Divdesconto = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  margin: 0rem;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin: auto;
  small {
    margin-bottom: -0.4rem;
  }
`;

export const NewResource = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  background-color: var(--warn);
  border-radius: 2px;
  width: fit-content;
  padding: 1px 2px;
  color: var(--information);
`;
