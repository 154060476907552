import { useState } from "react";
import { BsCart4 } from "react-icons/bs";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Backdrop from "../../Backdrop";
import DialogShoppingCartPayment from "../DialogShoppingCartPayment";
import { Container, FielButtons, CardProjects } from "./styles";

export default function DialogShoppingCart(props) {
  const [open, setOpen] = useState(true);
  const [totalProjectPrice, setTotalProjectPrice] = useState(0);
  const [showBackdrop] = useState(false);

  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const [projectsAwaitingPayment] = useState(
    props.listshopping,
  );
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const currency = function (number) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(number);
  };

  // select and deselect project
  const handleSelectProject = (project_id) => {
    setTotalProjectPrice(
      totalProjectPrice +
        Number(
          projectsAwaitingPayment.find((proj) => proj.id === project_id)
            .original_price,
        ),
    );
    let aux = selectedProjects;
    aux.push(project_id);
    setSelectedProjects([...aux]);
  };
  const handleDeselectProject = (project_id) => {
    setTotalProjectPrice(
      totalProjectPrice -
        Number(
          projectsAwaitingPayment.find((proj) => proj.id === project_id)
            .original_price,
        ),
    );
    let aux = selectedProjects;
    aux.splice(aux.indexOf(project_id), 1);
    setSelectedProjects([...aux]);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        scroll='body'
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Container>
              <header>
                <h1>
                  <BsCart4 /> Carrinho de projetos
                </h1>
                <span>
                  Verifique atentamente seus projetos solicitados e selecione os
                  quais deseja prosseguir para o pagamento.
                </span>
                <content>
                  <h4>Meus projetos aguardando pagamento</h4>
                  <div className='div__oneRow'>
                    <div style={{ flex: 1, flexDirection: "column" }}>
                      <small>
                        Selecione os projetos abaixo: <br /> <br />
                      </small>
                      <div className='div__deck'>
                        {projectsAwaitingPayment
                          .sort(
                            (a, b) =>
                              new Date(b.created_at) - new Date(a.created_at),
                          )
                          .map((project, idx) => (
                            <CardProjects
                              selected={selectedProjects.includes(project.id)}
                            >
                              <label htmlFor={idx}>
                                <div className='div__oneRow'>
                                  <div
                                    className='div__oneColumn'
                                    style={{ flex: 1 }}
                                  >
                                    <input
                                      className='input-custom-style'
                                      type='checkbox'
                                      id={idx}
                                      name='selected_project'
                                      checked={selectedProjects.includes(
                                        project.id,
                                      )}
                                      onChange={() => {
                                        if (
                                          selectedProjects.includes(project.id)
                                        ) {
                                          handleDeselectProject(project.id);
                                        } else {
                                          handleSelectProject(project.id);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div
                                    className='div__oneColumn'
                                    style={{ flex: 10 }}
                                  >
                                    <h4>
                                      Projeto Fotovoltáico{" "}
                                      {project?.project?.total_project_power}{" "}
                                      kWp
                                    </h4>

                                    <div>
                                      <div className='div__text_line'>
                                        {project?.project?.client_name}
                                      </div>
                                      <div className='div__text_line'>
                                        {project.project &&
                                          (project?.project?.client_cpf
                                            ? "CPF:"
                                            : "CNPJ:")}{" "}
                                        {project?.project &&
                                        project?.project?.client_cpf
                                          ? project?.project?.client_cpf
                                          : project?.project?.client_cnpj}
                                      </div>

                                      {project?.project && (
                                        <div className='div__text_line'>
                                          {project?.project?.dealer?.name} -{" "}
                                          {project?.project?.dealer?.state}
                                        </div>
                                      )}
                                      <div className='div__created_at'>
                                        Solicitado em{" "}
                                        {new Date(
                                          project.created_at,
                                        ).toLocaleString("pt-BR", {
                                          day: "2-digit",
                                          month: "long",
                                          year: "numeric",
                                        })}
                                      </div>
                                      <div className='div__price_line'>
                                        Valor:{" "}
                                        <b>
                                          {currency(project.original_price)}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </CardProjects>
                          ))}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        padding: "0.5rem 1.25rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className='div__oneColumn'>
                        <div
                          className='div__table_price'
                          style={{ marginTop: "1rem" }}
                        >
                          <b>Item</b>
                          <b>Preço</b>
                        </div>

                        {selectedProjects.length === 0 ? (
                          <div style={{ textAlign: "center", width: "100%" }}>
                            <small>Selecione o(s) projeto(s)</small>
                          </div>
                        ) : (
                          selectedProjects.map((id_project) => (
                            <div className='div__table_price'>
                              <span>
                                - Projeto fotovoltáico{" "}
                                {
                                  projectsAwaitingPayment.find(
                                    (proj) => proj.id === id_project,
                                  ).project.total_project_power
                                }{" "}
                                kWp
                              </span>
                              <span>
                                {currency(
                                  Number(
                                    projectsAwaitingPayment.find(
                                      (proj) => proj.id === id_project,
                                    ).original_price,
                                  ),
                                )}
                              </span>
                            </div>
                          ))
                        )}

                        <div className='div__table_price'>
                          <div className='div__table_sub_total'>
                            Total:{" "}
                            <b>
                              {currency(totalProjectPrice)}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className='secondary-button-style'
              color='primary'
            >
              Cancelar
            </button>
            <button
              onClick={() => setShowPaymentMethods(true)}
              className='primary-button-style'
              disabled={!totalProjectPrice}
            >
              Seguir para pagamento
            </button>
          </FielButtons>
        </DialogActions>

        {showBackdrop && <Backdrop />}
        {showPaymentMethods && (
          <DialogShoppingCartPayment
            setClose={() => setShowPaymentMethods(false)}
            data={{
              selectedProjects: selectedProjects,
              projectsAwaitingPayment: projectsAwaitingPayment,
              totalProjectPrice: totalProjectPrice,
            }}
          />
        )}
      </Dialog>
    </div>
  );
}
