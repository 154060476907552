import styled from 'styled-components';

export const Pagetimeline = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.09rem;
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: ${(props) =>
      props.delete ? '1px solid var(--delete)' : '1px solid var(--primary2)'};
    border-radius: 1.4rem;
    color: ${(props) => (props.delete ? 'var(--delete)' : 'var(--primary2)')};
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: ${(props) =>
      props.delete ? 'var(--delete)' : 'var(--primary2)'};
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;

export const Sessionline = styled.div`
  display: flex;

  .div__line_not_ok {
    display: flex;
    flex: 1;
    svg {
      display: block;
      width: 30px;
      height: 30px;
      position: relative;
      left: -0.2%;
      content: '';
      background: white;
      /* background: green;   */
      margin: 1.3rem auto;
      margin-top: 1rem;
      color: var(--delete);
      z-index: 1;
      border-radius: 50%;

      /* border: 2px solid gray; */
    }
    ::after {
      z-index: 0;
      display: block;
      /* width: 3px; */
      position: relative;
      left: -50%;
      content: '';

      border-right: 2px dotted gray;
      height: 100%;
      /* margin: auto; */
    }
  }

  .div__line_ok {
    display: flex;
    flex: 1;
    svg {
      display: block;
      width: 30px;
      height: 30px;
      position: relative;
      left: -0.2%;
      content: '';
      background: white;
      /* background: green;   */
      margin: 1.3rem auto;
      margin-top: 0.7rem;
      color: var(--primary2);
      z-index: 1;
      border-radius: 50%;

      /* border: 2px solid gray; */
    }
    ::after {
      z-index: 0;
      display: block;
      /* width: 3px; */
      position: relative;
      left: -50%;
      content: '';

      border-right: 2px dotted gray;
      height: 100%;
      /* margin: auto; */
    }
  }

  .div__line {
    display: flex;
    flex: 1;
    /* background: red; */
    ::before {
      display: block;
      width: 15px;
      height: 15px;
      position: relative;
      left: -0.2%;
      content: '';
      background: ${(props) => (props.idx != props.max ? 'green' : 'white')};
      /* background: green;   */
      margin: 1.3rem auto;
      z-index: 1;
      border-radius: 50%;
      border: 2px solid
        ${(props) => (props.idx != props.max ? 'green' : 'gray')};
      /* border: 2px solid gray; */
    }

    ::after {
      z-index: 0;
      display: block;
      /* width: 3px; */
      position: relative;
      left: -50%;
      content: '';

      border-right: 2px dotted gray;
      height: 100%;
      /* margin: auto; */
    }
  }
  .div__description {
    flex: 6;
    padding: 1rem 0rem;

    label {
      color: ${(props) =>
        props.title === '7 - Projeto Reprovado'
          ? 'var(--delete)'
          : 'var(--primary2)'};
      font-size: 0.9rem;
      font-weight: 600;
    }
    p {
      margin: 0rem;
      font-size: 0.9rem;
    }
    .small_marker {
      ::before {
        display: block;
        content: '';
        border-top: 1px dotted gray;
        max-width: 180px;
        margin: 0.1rem 0rem;
      }

      color: gray;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
`;
