import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../services/api';
import { TOKEN_KEY } from '../services/auth';

export const AuthContext = createContext({});

const customerRoles = ['INTEGRADOR'];

export function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [listshopping, setListshopping] = useState([]);
  const isAuthenticated = !!user?.email;
  const isRegistrationComplete = useMemo(
    () => {
      const complete = !!user?.address &&
        !!user?.company &&
        !!user?.company?.address &&
        !!user?.phone_numbers?.length;

      return complete;
    }, [user]);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post('session', {
          email,
          password,
        });

        const { token, user } = response.data;

        const isCustomer = customerRoles.includes(user?.role);
        if (!isCustomer) {
          toast.warn('Usuário não tem permissão para acessar essa página');
          throw new Error('User has no permission to access this page');
        }

        localStorage.setItem(TOKEN_KEY, token);

        setUser(user);

        api.defaults.headers['Authorization'] = `Bearer ${token}`;

        navigate('/integradores');
      } catch (err) {
        console.error(err);
        let error = err.response.data;

        let defaultError = 'Email ou senha inválidos';
        let errorMessage =
          error?.message === 'Para acessar a conta você deve validar o e-mail.'
            ? error.message
            : defaultError;
        toast.warn(errorMessage);
      }
    },
    [navigate]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    setUser(undefined);
    navigate('/login');
  }, [navigate]);

  const refreshUser = useCallback(() => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      api
        .get('/users/profile')
        .then((response) => {
          const user = response.data;
          setUser(user);
        })
        .catch(() => {
          signOut();
        });
    }
  }, [signOut]);

  const getShoppingcart = useCallback(async () => {
    if (user) {
      await api
        .get(`receipts/unpaid/${user?.company_id}?page=0&rows=10`)
        .then((res) => {
          setListshopping(res.data.receipts);
        })
        .catch((error) => {
          console.error(error.response?.data?.error);
        });
    }
  }, [user]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthenticated,
        user,
        refreshUser,
        getShoppingcart,
        listshopping,
        isRegistrationComplete,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
