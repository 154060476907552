import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';

import SmartphoneIcon from '@material-ui/icons/Smartphone';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CheckIcon from '@material-ui/icons/Check';

import { useAuth } from '../../contexts/AuthContext';
import Backdrop from '../Backdrop';
import Copy from '../Copy';
import api from '../../services/api';
import { Container } from './styles';

export default function PixPaymentMethod(props) {
  const idInterval = useRef();
  const { user, getShoppingcart } = useAuth();

  const company = user?.company;
  const addressPerson = user?.address;
  const numbersContact = user?.phone_numbers;

  const [dataPix, setDataPix] = useState({
    imageUrl: '',
    idPayment: '',
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleSubmitPayment = async () => {
    setShowBackdrop(true);

    const paymentData = {
      receipts: props.selectedProjects,
      payment_type: 'pix',
      installments: '1',
      customer_name: user?.name,
      customer_email: user?.email,
      customer_mobile: !!numbersContact?.length
        ? numbersContact[0].number
        : undefined,
      customer_document: company.cnpj ? company.cnpj : user?.cpf,
      billing_address: addressPerson.street,
      billing_number: addressPerson.number,
      billing_neighborhood: addressPerson.district,
      billing_state: addressPerson.state,
      billing_city: addressPerson.city,
      billing_zip_code: addressPerson.zip_code,
      credits_discount: 0,
    };

    await api
      .post('/transactions', paymentData)
      .then(async (res) => {
        let auxData = JSON.parse(res.data.processor_response);
        setDataPix({
          ...dataPix,
          imageUrl:
            auxData?.charges?.length > 0
              ? auxData?.charges[0]?.lastTransaction?.qrCode
              : null,
          idPayment: res.data.id,
        });

        props.setPaymentInitiated(true);
        getDataEventSync(res.data.id);
      })
      .catch((error) => {
        console.error(error);
        toast.warn(error.response.data.message);
      })
      .finally(() => setShowBackdrop(false));
  };

  const getDataEventSync = (paymentID) => {
    var aux = setInterval(async () => {
      await api
        .get(`/transactions/${paymentID}`)
        .then((res) => {
          if (res.data.status === 'approved') {
            cancelDataEventSync();

            getShoppingcart();
            props.setPaymentSuccess(true);
            setTimeout(() => (window.location = '/meus-projetos'), 5000);
          } else if (
            res.data.status === 'refused' ||
            res.data.status === 'refunded'
          ) {
            cancelDataEventSync();

            getShoppingcart();
            toast.error('Pagamento recusado, tente novamente');
            setTimeout(() => (window.location = '/comprovantes'), 10 * 1000);
          }
        })
        .catch((error) => {
          console.error('erro recibos:', error.response);
        });
    }, 30 * 1000);

    idInterval.current = aux;
  };
  // cancela busca periódica
  const cancelDataEventSync = () => {
    clearInterval(idInterval.current);
  };

  return (
    <Container>
      <div className="div__page_body_method_slip">
        <label
          style={{ textAlign: 'justify' }}
          onClick={() => cancelDataEventSync()}
        >
          {' '}
          Pague com pix a qualquer momento e lugar utilizando apenas o seu
          celular. Escaneie o QR Code e efetue o a transação em poucos segundos.
        </label>
        <div className="div__field_qrcode_image">
          {dataPix.imageUrl ? (
            <div className="div__oneColumn">
              <QRCode
                value={dataPix.imageUrl}
                renderAs="canvas"
                imageSettings={{
                  height: 100,
                  width: 100,
                }}
              />
              <div className="div__data_copy">
                <label>
                  <Copy name="código PIX" data={dataPix.imageUrl} />
                </label>
                <input type="text" value={dataPix.imageUrl} disabled />
              </div>
            </div>
          ) : (
            <div className="div__await_qrcode">
              Feche a compra para gerar QR Code
            </div>
          )}
        </div>
        <label>
          <SmartphoneIcon /> Pegue o celular e abra o aplicativo de sua
          preferência para efetuar o pagamento
        </label>
        <label>
          <CropFreeIcon /> Abra o ambiente PIX, selecione a opção de efetuar
          pagamento com QR Code e escaneie o código apresentado acima
        </label>
        <label>
          <CheckIcon /> Confirme as informações e prossiga para finalizar o
          pagamento
        </label>

        <button
          type="submit"
          className={
            dataPix.imageUrl
              ? 'primary-button-style btn__confirm_payment_disabled'
              : 'primary-button-style'
          }
          style={{ marginInline: 'auto' }}
          onClick={() => handleSubmitPayment()}
          disabled={dataPix.imageUrl ? true : false}
        >
          Fechar compra
        </button>
      </div>
      {showBackdrop && (
        <Backdrop
          message={'Sua solicitação está sendo processada, por favor aguarde.'}
        />
      )}
    </Container>
  );
}
