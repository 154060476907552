import React from "react";
import { FaCopy } from "react-icons/fa";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {QRCodeSVG} from 'qrcode.react';

import { Container, FielButtons } from "./styles";
import { toast } from "react-toastify";

export default function DialogPixCode(props) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    props.handleViewSetPixCode(false)
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(props.code)
      .then(() => {
        toast.success(`Código copiado com sucesso`)
      })
      .catch((err) => {
        console.error("Erro ao copiar texto: ", err);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth='sm'
        scroll='body'
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Container suspended={false}>
              <header>
                <h1>Pix de Pagamento do Projeto {props?.dados_proj?.client_name}</h1>
                <span>
                  Escaneie ou copie o Qr Code Abaixo para realizar o Pagamento
                </span>
                <div className='div__listpendencias'>
                  <QRCodeSVG value={props?.code}/>
                  <button className="button_copy" onClick={handleCopy}>
                    {props.code.length > 15 ? `${props.code.slice(0, 15)}...` : props.code} <FaCopy />
                  </button>
                </div>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className='secondary-button-style'
              color='primary'
              style={{
                minWidth: "100px",
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}