import React, { useEffect, useState, useCallback } from "react";
import { DivButtons } from "./styles";

import { geosearch, arcgisOnlineProvider } from "esri-leaflet-geocoder";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

export default function DisplayPosition({ map, onChangeLocation }) {
  const [position, setPosition] = useState(map.getCenter());

  const onMove = useCallback(() => {
    setPosition(map.getCenter());
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);

    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    onChangeLocation(map.getCenter());
  }, [position]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;

    const control = geosearch({
      position: "topright",
      placeholder: "Buscar localização",
      useMapBounds: false,
      providers: [
        arcgisOnlineProvider({
          apikey:
            "AAPK64e86b8a7b0345da8f94014704c5bddb4kWKfa94A0zSZhJxzOImphcHIjnmSOjcWttyYZ_jH5EjUvoz9NgxB3IhXduA7B2Q", // replace with your api key - https://developers.arcgis.com
        }),
      ],
    });
    control.addTo(map);
  }, []); // eslint-disable-line

  return <DivButtons></DivButtons>;
}
