import styled from 'styled-components';

export const DivButtons = styled.div`
  /* height: 3rem; */

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    /* height: 6rem; */
    /* margin-bottom: 1rem; */
  }
`;

