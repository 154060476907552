import React, { useState, useMemo, useEffect } from 'react';

import { Container } from './styles';

import { CircularProgress } from '@material-ui/core';

// api
import api from '../../../services/api';

import { useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function ConfirmacaoEmail(props) {
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');

  const [counter, setCounter] = useState(5);
  const [errorMessage, setErrorMessage] = useState(
    'Link para validação inválido!'
  );
  const [confirmValidation, setConfirmValidation] = useState(false);
  const [responseAPI, setResponseAPI] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      validateEmail();
    }, 2000);
  }, []);

  useEffect(() => {
    if (confirmValidation) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      setTimeout(() => {
        navigate('/login?message=Conta confirmada com sucesso!');
      }, 5000);
    }
  }, [confirmValidation]);

  useEffect(() => {
    if (counter !== 0 && confirmValidation) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  const validateEmail = async () => {
    await api
      .post('/users/validation/validate', { token })
      .then((response) => {
        setResponseAPI(true);
        setConfirmValidation(true);
      })
      .catch((error) => {
        console.error(error.data);
        setResponseAPI(true);
        setConfirmValidation(false);
        // setErrorMessage(error.data.error);
      });
  };

  return (
    <Container>
      {!responseAPI && (
        <>
          <h1>Estamos validando o seu email</h1>
          <CircularProgress />
        </>
      )}
      {responseAPI && confirmValidation ? (
        <>
          <h1>Email verificado com sucesso</h1>
          <p>você será redirecionado para fazer login em {counter} segundos.</p>
        </>
      ) : (
        responseAPI && (
          <>
            <h1>{errorMessage}</h1>
            <p>
              <a href="#">Clique aqui</a> para solicitar um novo link.
            </p>
          </>
        )
      )}
    </Container>
  );
}

export default ConfirmacaoEmail;
