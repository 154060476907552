export function generatePrice(
  total_project_power,
) {
  let power = total_project_power;
  let price = 0;

  if (power >= 0 && power <= 5) {
    price = 500.0;
  } else if (power > 5 && power <= 10) {
    price = 650.0;
  } else if (power > 10 && power <= 20) {
    price = 850.0;
  } else if (power > 20 && power <= 30) {
    price = 1050.0;
  } else if (power > 30 && power <= 40) {
    price = 1300.0;
  } else if (power > 40 && power <= 50) {
    price = 1600.0;
  } else {
    return null;
  }

  return price;
}
