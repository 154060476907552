import { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Map from './Map';
import { FieldButtons } from "./styles";

export default function DialogFindLocation(props) {
  const [open, setOpen] = useState(props.status ? props.status : true);
  const [location, setLocation] = useState();

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={props.width ? props.width : "sm"}
        fullWidth
        // fullScreen={window.innerWidth > 675 ? false : true}
        scroll='body'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: "var(--primary2)",
          }}
        >
          Selecionar localização do projeto
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            <Map
              dados={props.dados}
              onChangeLocation={(newLocation) => setLocation(newLocation)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FieldButtons delete={props.delete}>
            <button
              onClick={handleClose}
              className='secondary-button-style'
              color='primary'
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                props.confirmar(location);
                handleClose();
              }}
              className='primary-button-style'
            >
              Selecionar
            </button>
          </FieldButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
