import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { mask } from 'remask';

import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import { Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PrintIcon from '@material-ui/icons/Print';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api';
import { printReceipt } from '../../../utils';
import {
  Backdrop,
  Copy,
  DialogChangeData,
  DialogConfirmation,
  DialogNewUser,
}  from '../../../components';

import { Container, Page, Card } from './styles';

export default function Receipts() {
  const { user, getShoppingcart } = useAuth();
  const company = user?.company;

  const [showbackdrop, setShowbackdrop] = useState(false);
  const [newuser, setNewuser] = useState({
    open: false,
    edit: false,
    dados: '',
  });
  const [showaltdados, setShowaltdados] = useState(false);
  const [page] = useState(0);
  const [rows, setRows] = useState(5);
  const [order, setOrder] = useState('primeiros');
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este usuário?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [maxrows, setMaxrows] = useState(0);

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });

  const [listreceipts, setListreceipts] = useState([]);

  const handleFilter = (dado) => {
    if (filter.type === 'project') {
      if (
        dado?.project?.client_name &&
        dado?.project?.client_name
          .toLowerCase()
          .indexOf(filter.value.toLowerCase()) > -1
      )
        return true;
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else if (filter.type === 'cnpj') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '99.999.999/9999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  const listusers2 = listreceipts.filter(handleFilter).sort((a, b) => {
    if (order === 'AZ' && a.project && b.project) {
      return a.project?.client_name > b.project?.client_name
        ? 1
        : b.project?.client_name > a.project?.client_name
        ? -1
        : 0;
    } else if (order === 'ZA' && a.project && b.project) {
      return a.project?.client_name < b.project?.client_name
        ? 1
        : b.project?.client_name < a.project?.client_name
        ? -1
        : 0;
    } else if (order === 'primeiros') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === 'ultimos') {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const getReceiptslist = (page, rows) => {
    setShowbackdrop(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    api
      .get(`/receipts/company/${company?.id}?page=${page}&rows=${rows}`, config)
      .then((res) => {
        setListreceipts(res.data.receipts);
        setMaxrows(res.data.itens.total);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.error('Error: ', error.response.data);
        toast.success(error.response.data.message);

        setShowbackdrop(false);
      });
  };

  function setNewReceipt(recibo) {
    const dataNewreceipt = {
      company_id: recibo.company_id,
      project_id: recibo.project_id,
    };

    api
      .post(`/receipts`, dataNewreceipt)
      .then(() => {
        getReceiptslist(page, rows);
        getShoppingcart();
        toast.success('Novo recibo adicionado ao carrinho');
      })
      .catch((error) => {
        console.error('Error: ', error.response.data);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  }

  function getPaymentStatus(status, recibo) {
    if (
      recibo?.transaction?.processor_response &&
      JSON.parse(recibo?.transaction?.processor_response)
        ?.boleto_expiration_date &&
      new Date(
        JSON.parse(
          recibo?.transaction?.processor_response
        )?.boleto_expiration_date
      ) < new Date()
    )
      return <div className="is_refused">Cancelado</div>;

    switch (status) {
      case 'approved':
        return recibo.is_paid ? (
          <div className="is_paid">Pago</div>
        ) : (
          <div className="is_refused">Cancelado</div>
        );
      case 'pending':
        return <div className="is_pending">Pendente</div>;
      case 'refused':
        return <div className="is_refused">Cancelado </div>;
      case 'refunded':
        return <div className="is_refused">Cancelado</div>;
      case 'chageback':
        return <div className="is_refused">Extornado</div>;
      case 'error':
        return <div className="is_refused">Cancelado</div>;

      default:
        return <div className="is_waiting">Aguardando pagamento</div>;
    }
  }

  function getPaymentOption(status, recibo) {
    switch (status) {
      case 'approved':
        return recibo.is_paid ? (
          <IconButton onClick={() => printReceipt(recibo, company, user)}>
            <GetAppIcon />
          </IconButton>
        ) : (
          <IconButton
            disabled={recibo.project_id !== null ? false : true}
            onClick={() => recibo.project_id !== null && setNewReceipt(recibo)}
          >
            <AddShoppingCartIcon className="icon__cart" />
          </IconButton>
        );
      case 'pending':
        return JSON.parse(recibo?.transaction?.processor_response)
          ?.boleto_url ? (
          new Date(
            JSON.parse(
              recibo?.transaction?.processor_response
            )?.boleto_expiration_date
          ) > new Date() ? (
            <IconButton
              onClick={() =>
                window.open(
                  JSON.parse(recibo?.transaction?.processor_response)
                    ?.boleto_url,
                  '_blank'
                )
              }
            >
              <PrintIcon />
            </IconButton>
          ) : (
            <IconButton
              disabled={recibo.project_id !== null ? false : true}
              onClick={() =>
                recibo.project_id !== null && setNewReceipt(recibo)
              }
            >
              <AddShoppingCartIcon />
            </IconButton>
          )
        ) : (
          <IconButton>
            <Copy
              name="código PIX"
              data={
                JSON.parse(recibo?.transaction?.processor_response)?.pix_qr_code
              }
            />
          </IconButton>
        );
      case 'refused':
        return (
          <IconButton
            disabled={recibo.project_id !== null ? false : true}
            onClick={() => recibo.project_id !== null && setNewReceipt(recibo)}
          >
            <AddShoppingCartIcon />
          </IconButton>
        );
      case 'refunded':
        return (
          <IconButton
            disabled={recibo.project_id !== null ? false : true}
            onClick={() => recibo.project_id !== null && setNewReceipt(recibo)}
          >
            <AddShoppingCartIcon className="icon__cart" />
          </IconButton>
        );
      case 'chageback':
        return (
          <IconButton
            disabled={recibo.project_id !== null ? false : true}
            onClick={() => recibo.project_id !== null && setNewReceipt(recibo)}
          >
            <AddShoppingCartIcon className="icon__cart" />
          </IconButton>
        );
      case 'error':
        return (
          <IconButton
            disabled={recibo.project_id !== null ? false : true}
            onClick={() => recibo.project_id !== null && setNewReceipt(recibo)}
          >
            <AddShoppingCartIcon className="icon__cart" />
          </IconButton>
        );

      default:
        return (
          <IconButton
            disabled={!recibo.is_paid}
            onClick={() => printReceipt(recibo, company, user)}
          >
            <GetAppIcon />
          </IconButton>
        );
    }
  }

  // popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [datapop, setDatapop] = useState();
  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const idPop = openPop ? 'simple-popover' : undefined;

  useEffect(() => {
    if (company) getReceiptslist(page, rows);
    if (user) getShoppingcart();
  }, [company, user]); // eslint-disable-line

  return (
    <Container>
      <Page>
        <header>
          <h1>Comprovantes de pagamento</h1>
          <div className="div__divider" />
          <span>Tenha acesso a lista de suas compras dentro da plataforma</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                className="input-custom-style "
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="project">Projeto</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'project'
                    ? 'Digite o nome do projeto'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                className="input-custom-style "
                style={{
                  display:
                    filter.type === 'project' ||
                    filter.type === 'cpf' ||
                    filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              <select
                className="input-custom-style "
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__field_actions">
            <div className="div__fiel_filter"></div>
            <div className="containerButton"></div>
          </div>

          <div className="div__deck">
            <div className="head_list">
              <div className="div__created_at" style={{ flex: 0.5 }}>
                Data
              </div>
              <div className="div__original_price">Preço original</div>

              <div className="div__final_price">Preço final</div>
              <div className="div__client_name" style={{ flex: 2 }}>
                Projeto
              </div>
              <div className="div__is_paid">Situação</div>
              <div className="div__options_list" style={{ flex: 0.1 }}>
                {' '}
                Opções{' '}
              </div>
            </div>

            {listusers2.map((recibo, idx) => (
              <Card key={idx}>
                <div className="div__created_at" style={{ flex: 0.5 }}>
                  {new Date(recibo.created_at).toLocaleDateString('pt-BR')}
                </div>
                <div className="div__original_price">
                  {currency(recibo.original_price)}
                </div>
                <div className="div__final_price">
                  {currency(recibo?.transaction?.total || recibo.final_price)}{' '}
                  <InfoOutlinedIcon
                    className="icon__info"
                    aria-describedby={idPop}
                    onClick={(event) => {
                      handleClickPop(event);
                      setDatapop(recibo);
                    }}
                  />
                </div>
                <div className="div__client_name" style={{ flex: 2 }}>
                  {recibo.project?.client_name
                    ? recibo?.project.client_name
                    : ''}
                </div>
                <div className="div__is_paid">
                  {recibo.is_paid ? (
                    <div className="is_paid">Pago</div>
                  ) : (
                    getPaymentStatus(recibo?.transaction?.status, recibo)
                  )}
                </div>
                <div className="div__options_list" style={{ flex: 0.1 }}>
                  {recibo.is_paid ? (
                    <IconButton
                      disabled={!recibo.is_paid}
                      onClick={() => printReceipt(recibo, company, user)}
                    >
                      <GetAppIcon />
                    </IconButton>
                  ) : (
                    getPaymentOption(recibo?.transaction?.status, recibo)
                  )}
                </div>
              </Card>
            ))}

            {maxrows > rows ? (
              <div
                className="div__viewMore"
                onClick={() => {
                  let aux = rows + 3;
                  getReceiptslist(page, aux);
                  setRows(aux);
                }}
              >
                Ver mais
              </div>
            ) : listusers2.length === 0 && filter.type ? (
              <div style={{ padding: '1rem' }}>
                Nenhum comprovante encontrado
              </div>
            ) : listusers2.length === 0 && !filter.type ? (
              <div style={{ padding: '1rem' }}>
                Nenhum comprovante cadastrado
              </div>
            ) : (
              <div style={{ margin: '2rem 1rem' }}>Sem mais comprovantes</div>
            )}
          </div>
        </content>
      </Page>
      {showaltdados && (
        <DialogChangeData fechar={() => setShowaltdados(false)} />
      )}

      {showconf.status && (
        <DialogConfirmation
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            toast.success(
              `Usuário ${showconf.dados.name} deletado com sucesso`
            );
          }}
        />
      )}
      {newuser?.open && (
        <DialogNewUser
          fechar={() => setNewuser({ open: false, edit: false, dados: '' })}
          edit={newuser?.edit}
          dados={newuser?.dados}
          editar={() => true}
        />
      )}

      {/* popover  */}
      <Popover
        id={idPop}
        open={openPop}
        anchorEl={anchorEl}
        onClose={(event) => {
          handleClosePop(event);
          setTimeout(() => {
            setDatapop();
          }, 200);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span>
            + {currency(datapop?.original_price)} <small>(projeto)</small>
          </span>

          {datapop?.transaction?.flat_fee > 0 ? (
            <span>
              {' '}
              + {currency(datapop?.transaction?.flat_fee)} <small>(taxa)</small>
            </span>
          ) : (
            ''
          )}
          <span
            style={{
              borderTop: '1px dotted rgba(var(--black_rgb), 0.5)',
              color: 'var(--primary2)',
            }}
          >
            • {currency(datapop?.transaction?.total || datapop?.final_price)}
          </span>
        </div>
      </Popover>

      {showbackdrop && <Backdrop />}
    </Container>
  );
}
