import { Routes, Route, Navigate } from 'react-router-dom';

//contexts
import { useAuth } from './contexts/AuthContext';

// Layout Page System
import { Layout } from './pages/Layout';

// public page
import Login from './pages/public/Login';
import Cadastro from './pages/public/Cadastro';
import ConfirmacaoEmail from './pages/public/ConfirmacaoEmail';
import Recuperarsenha from './pages/public/Recuperarsenha';

// private page
import Newproject from './pages/private/Newproject';
import Projects from './pages/private/Projects';
import Receipts from './pages/private/Receipts';
import Users from './pages/private/Users';
import Orcamento from './pages/private/Orcamento';
import GerarProcuracao from './pages/private/GerarProcuracao';
import Settings from './pages/private/Settings';

function PublicPage({ component: Component }) {
  const { user } = useAuth();

  return !!user ? <Navigate to="/meus-projetos" /> : <Component />;
}

const RoutesApp = () => (
  <Routes>
    <Route exact path="/" element={<Navigate to="/login" replace />} />
    <Route exact path="/login" element={<PublicPage component={Login} />} />
    <Route
      exact
      path="/cadastro"
      element={<PublicPage component={Cadastro} />}
    />
    <Route exact path="/validar-conta" element={<ConfirmacaoEmail />} />
    <Route exact path="/redefinir-senha" element={<Recuperarsenha />} />

    <Route element={<Layout />}>
      <Route exact path="/meus-projetos" element={<Projects />} />
      <Route path="/orcamento" element={<Orcamento />} />
      <Route path="/solicitar-projeto" element={<Newproject />} />
      <Route path="/comprovantes" element={<Receipts />} />
      <Route path="/usuarios" element={<Users />} />
      <Route path="/gerar-procuracao" element={<GerarProcuracao />} />
      <Route path="/configuracoes" element={<Settings />} />
    </Route>
  </Routes>
);

export { RoutesApp };
