import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Pagetimeline, FielButtons, Sessionline } from "./styles";

import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";

export default function TimelineProject(props) {
  const [open, setOpen] = React.useState(true);
  const [dataline] = React.useState(props.dados.stages);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={props.width ? props.width : "sm"}
        scroll='body'
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: "var(--primary2)",
          }}
        >
          Timeline projeto de {props.dados?.client_name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            {dataline.length === 0 ? (
              <span>Nada a ser apresentado no momento</span>
            ) : (
              <Pagetimeline>
                {dataline
                  .sort((a, b) => {
                    return (
                      new Date(a.created_at).getTime() -
                      new Date(b.created_at).getTime()
                    );
                  })
                  .map((dados, idx) => (
                    <Sessionline
                      max={dataline.length}
                      idx={idx + 1}
                      title={dados.title}
                    >
                      {dados.title === "5 - Projeto aprovado" ? (
                        <div className='div__line_ok'>
                          <DoneAllIcon />
                        </div>
                      ) : dados.title === "7 - Projeto reprovado" ? (
                        <div className='div__line_not_ok'>
                          <ClearIcon />
                        </div>
                      ) : (
                        <div className='div__line' />
                      )}

                      <div className='div__description'>
                        <label>{dados.title}</label>
                        <small className='small_marker'>
                          {new Date(dados.created_at).toLocaleString("pt-BR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                          {" as "}
                          {new Date(dados.created_at).toLocaleString("pt-BR", {
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </small>

                        {dados.status.map((datastatus) => (
                          <>
                            <p>
                              - {datastatus.description}{" "}
                              <small>
                                (
                                {new Date(datastatus.created_at).toLocaleString(
                                  "pt-BR",
                                  {
                                    month: "short",
                                    day: "2-digit",
                                  },
                                )}
                                {" as "}
                                {new Date(datastatus.created_at).toLocaleString(
                                  "pt-BR",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  },
                                )}
                                )
                              </small>
                            </p>
                          </>
                        ))}
                      </div>
                    </Sessionline>
                  ))}
              </Pagetimeline>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons delete={props.delete}>
            <button
              onClick={handleClose}
              className='secondary-button-style'
              color='primary'
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
