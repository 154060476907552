import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* max-width: 500px; */
  /* height: 500px; */

  .div__mapa {
    position: absolute;
    /* top: -35%; */
    top: 42%;
    left: 46%;
    /* background: red; */
    width: 60px;
    margin: auto;

    padding-right: 1rem;
    svg {
      font-size: 30px;
    }
    .icon__marker {
      position: relative;
      color: var(--primary2);
      z-index: 10 !important;
      left: 0.9rem;
      bottom: 0.1rem;
    }
    .icon__shadow {
      color: black;
      margin-left: -0.2rem;
      opacity: 0.2;
      z-index: 0 !important;
    }
  }

  .descriptionEndereco {
    width: 100%;
    max-width: 800px;
    margin-top: 2rem;
    color: blue;

    color: rgb(244, 245, 250);
    font-weight: 500;
    font-size: 1.2rem;
    background-image: linear-gradient(
      to right,
      rgba(10, 64, 99, 0.5),
      rgba(0, 0, 0, 0) 90%
    );
    padding: 1rem;
  }

  .leaflet-container {
    z-index: 0 !important;
    width: 100% !important;
    height: 300px !important;
  }

  .map-popup .leaflet-popup-content-wrapper {
    background: rgba(255, 255, 255, 0.8) !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }

  .map-popup .leaflet-popup-content h3 {
    color: #0089a5 !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin: 8px 12px !important;
  }

  .map-popup .leaflet-popup-content p {
    color: #042f38 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    margin: 8px 12px !important;
    line-height: 15px !important;
  }

  .map-popup .leaflet-popup-tip-container {
    display: none !important;
  }

  /* @media (max-width: 1200px) {
    width: 85%;
    max-width: 800px;

    display: flex;
    flex-direction: column;

    align-items: center;

    .leaflet-container {
      z-index: 0 !important;
      width: 100% !important;
      height: 400px !important;
    }
  } */

  /* @media (max-width: 700px) {
    .leaflet-container {
      z-index: 0 !important;
      width: 100% !important;
      height: 300px !important;
    }
  } */
`;
