import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { RoutesApp } from "./routes";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <RoutesApp />
      </AuthProvider>

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          zIndex: 999999,
        }}
      />
    </div>
  );
}

export default App;
