import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins', sans-serif;

  h1 {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
  }
  span {
    font-size: 0.9rem;
  }
  h4 {
    /* background-color: red; */
    text-transform: uppercase;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    color: rgba(var(--black_rgb), 0.5);
    font-weight: 400;
    width: 100%;
    /* padding: 0rem 2rem; */
    flex: 1;
    /* margin-left: 0rem; */
    text-align: left;

    ::after {
      display: block;
      content: '';
      margin: 0.5rem 0rem;
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
    }
  }

  p {
    flex: 1;
    margin: 0rem;
    font-size: 0.9rem;
  }
  label {
    font-weight: 600;
  }
  .div__oneRow {
    display: flex;
  }

  @media screen and (max-width: 675px) {
    .div__oneRow {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: 1.4rem;
    color: var(--primary2);
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: var(--primary2);
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;

export const ConfirmacaoCadastro = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 2rem;

  h2 {
    font-size: 2rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    
    .containerImg {
      max-width: 200px;
      animation: movimento-diagonal 1s infinite ease alternate;

      @keyframes movimento-diagonal {
        from {
          transform: translate(20px, -20px);
        }
        to {
          transform: translate(0px, 0px);
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    p {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  a {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 0.5rem 2rem;
    border-radius: 2rem;

    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;

    background-color: var(--primary2);
    border: 0px;
    
    color: var(--white);
    
    :hover {
      filter: brightness(1.2);
    }
  }
`;