import { useEffect, useState } from 'react';
import { BsCart4 } from 'react-icons/bs';
import { IoMdLogOut } from 'react-icons/io';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import {
  DialogShoppingCart,
  DialogCompleteData,
} from '../dialogs';
import { useAuth } from '../../contexts/AuthContext';
import { SITE_NAME } from '../../config';
import { Container } from './styles';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 15,
    top: 20,
    backgroundColor: 'var(--white)',
    color: 'var(--delete)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    margin: '0px',
  },
}))(Badge);

export default function Header({ open }) {
  const {
    user,
    getShoppingcart,
    listshopping,
    isRegistrationComplete,
    signOut,
  } = useAuth();

  const [showShoppingCart, setShowShoppingCart] = useState(false);

  useEffect(() => {
    if (user) getShoppingcart();
  }, [user, getShoppingcart]);

  return (
    <Container open={open}>
      <div className="texts">
        <h2>{SITE_NAME}</h2>
        <div className="data">
          {user && user?.name}
          <br />
          {user && user?.email}
        </div>
      </div>
      <div className="div__payment_field">
        <IconButton aria-label="cart" onClick={() => setShowShoppingCart(true)}>
          <StyledBadge badgeContent={listshopping?.length} color="default">
            <BsCart4 />
          </StyledBadge>
        </IconButton>
      </div>
      <button className="buttonSignout" onClick={() => signOut()}>
        <IoMdLogOut />
      </button>
      {showShoppingCart &&
        (isRegistrationComplete ? (
          <DialogShoppingCart
            listshopping={listshopping}
            fechar={() => setShowShoppingCart(false)}
          />
        ) : (
          <DialogCompleteData fechar={() => setShowShoppingCart(false)} />
        ))}
    </Container>
  );
}
