import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import api from '../../../../services/api';
import { Backdrop } from '../../../../components';
import imgMessage from '../../../../assets/images/message.png';
import { Container, FielButtons, ConfirmacaoCadastro } from './styles';

export default function DialogConfirm({ handleCloseDialog, data }) {
  const [open, setOpen] = useState(true);
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => handleCloseDialog(), 100);
  };

  const handleRegisterUser = async () => {
    setShowBackdrop(true);
    data.company_cnpj = data.company_cnpj ? data.company_cnpj : null;

    let requestData = {
      ...data,
    };

    await api
      .post('/users/integrators/create', requestData)
      .then((res) => {
        setShowBackdrop(false);
        setSuccessfulRegistration(true);
      })
      .catch((error) => {
        setShowBackdrop(false);
        return toast.warn(error.response.data.error);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!successfulRegistration ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Container>
                  <header>
                    <h1>Confirme seus dados</h1>
                    <span>
                      Confira atentamente os dados abaixo e finalize sua
                      inscrição.
                    </span>
                    <content>
                      <h4>Dados pessoais</h4>
                      <div className="div__oneRow">
                        <p>
                          <label>Nome: </label> {data.name}
                        </p>
                        <p>
                          <label>CPF: </label> {data.cpf}
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>E-mail: </label> {data.email}
                        </p>
                      </div>

                      <h4>Endereço</h4>
                      {data?.user_address_zip_code ? (
                        <>
                          <div className="div__oneRow">
                            <p>
                              <label>Estado: </label> {data.user_address_state}
                            </p>
                            <p>
                              <label>Cidade: </label> {data.user_address_city}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Rua: </label> {data.user_address_street}
                            </p>
                            <p>
                              <label>Nº: </label> {data.user_address_number}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Bairro: </label>{' '}
                              {data.user_address_district}
                            </p>
                            <p>
                              <label>Complemento: </label>{' '}
                              {data.user_address_complement
                                ? data.user_address_complement
                                : ' - '}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="div__oneRow">
                          <p>Dados de endereço não informado.</p>
                        </div>
                      )}

                      <h4>Dados da empresa</h4>
                      {data?.company_cnpj && (
                        <div className="div__oneRow">
                          <p>
                            <label>CNPJ: </label> {data.company_cnpj}
                          </p>
                        </div>
                      )}
                      {data?.company_fantasy_name && (
                        <div className="div__oneRow">
                          <p>
                            <label>Nome fantasia: </label>{' '}
                            {data.company_fantasy_name}
                          </p>
                        </div>
                      )}
                      {data?.company_social_reason && (
                        <div className="div__oneRow">
                          <p>
                            <label>Razão social: </label>{' '}
                            {data.company_social_reason}
                          </p>
                        </div>
                      )}
                      {/* <div className="div__oneRow">
                        <p>
                          <label>Cargo: </label> {data?.role}
                        </p>
                      </div> */}
                      {data?.company_cnpj && (
                        <div className="div__oneRow">
                          <p>
                            <label>Registro Estadual: </label>{' '}
                            {data.company_state_registration
                              ? data.company_state_registration
                              : ' - '}
                          </p>
                          <p>
                            <label>Registro Municipal: </label>{' '}
                            {data.company_municipal_registration
                              ? data.company_municipal_registration
                              : ' - '}
                          </p>
                        </div>
                      )}

                      <h4>Endereço da empresa</h4>
                      {data?.company_address_zip_code ? (
                        <>
                          <div className="div__oneRow">
                            <p>
                              <label>Estado: </label>{' '}
                              {data.company_address_state}
                            </p>
                            <p>
                              <label>Cidade: </label>{' '}
                              {data.company_address_city}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Rua: </label> {data.company_address_street}
                            </p>
                            <p>
                              <label>Nº: </label> {data.company_address_number}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Bairro: </label>{' '}
                              {data.company_address_district}
                            </p>
                            <p>
                              <label>Complemento: </label>{' '}
                              {data.company_address_complement
                                ? data.company_address_complement
                                : ' - '}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="div__oneRow">
                          <p>Dados de endereço não informado.</p>
                        </div>
                      )}
                    </content>
                  </header>
                </Container>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <FielButtons>
                <button
                  onClick={handleClose}
                  className="btn__retornar"
                  color="primary"
                >
                  Retornar
                </button>
                <button onClick={handleRegisterUser} className="btn__register">
                  Efetuar cadastro
                </button>
              </FielButtons>
            </DialogActions>
          </>
        ) : (
          <ConfirmacaoCadastro>
            <h2>Confirme sua conta atráves do email!</h2>
            <div className="content">
              <div className="containerImg">
                <img src={imgMessage} alt="Icone de mensagem" />
              </div>

              <p>
                Recebemos o seu cadastro,
                <br />
                agora você precisa confirmar sua conta <br />
                atráves do link que enviamos para seu email!
              </p>
            </div>

            <Link to="/login">Ir para login</Link>
          </ConfirmacaoCadastro>
        )}
        {showBackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
