export default function PixIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      version="1.1"
      viewBox="0 0 315.63 338.7"
    >
      <path
        fill={props.color}
        d="M246.13 264.53A46.07 46.07 0 01213.35 251L166 203.62a9 9 0 00-12.44 0l-47.51 47.51a46.09 46.09 0 01-32.78 13.57H64l60 60a48 48 0 0067.81 0l60.12-60.13z"
      ></path>
      <path
        fill={props.color}
        d="M73.28 97.09a46.08 46.08 0 0132.78 13.57l47.51 47.52a8.81 8.81 0 0012.44 0l47.34-47.34a46 46 0 0132.78-13.58h5.7l-60.12-60.12a47.94 47.94 0 00-67.81 0L64 97.09z"
      ></path>
      <path
        fill={props.color}
        d="M301.56 147l-36.33-36.33a7 7 0 01-2.58.52h-16.52a32.62 32.62 0 00-22.93 9.5L175.86 168a22.74 22.74 0 01-32.13 0l-47.52-47.49A32.62 32.62 0 0073.28 111H53a7.12 7.12 0 01-2.44-.49L14 147a48 48 0 000 67.81l36.48 36.48a6.85 6.85 0 012.44-.49h20.36a32.63 32.63 0 0022.93-9.51l47.51-47.51c8.59-8.58 23.56-8.58 32.14 0l47.34 47.33a32.62 32.62 0 0022.93 9.5h16.52a6.9 6.9 0 012.58.52l36.33-36.33a47.94 47.94 0 000-67.81"
      ></path>
    </svg>
  );
}
