import axios from 'axios';
import { API_URL } from '../config';
import { TOKEN_KEY } from './auth';

const api = axios.create({
  baseURL: API_URL,
});

const token = localStorage.getItem(TOKEN_KEY);
if (token) api.defaults.headers['Authorization'] = `Bearer ${token}`;

export default api;
