import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;

  .conteudo {
    overflow-y: scroll;
    width: 100%;

    padding: 0;
    margin: 0;
    padding-top: 4.25rem;
  }
`;
