import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins', sans-serif;

  h1 {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
    display:flex;
    align-items:center;

    svg{
      margin-right: 0.5rem;
    }
  }
  span {
    font-size: 0.9rem;
  }
  h4 {
    /* background-color: red; */
    text-transform: uppercase;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    /* color: rgba(var(--black_rgb), 0.5); */
    color: var(--primary2);
    font-weight: 400;
    width: 100%;
    /* padding: 0rem 2rem; */
    flex: 1;
    /* margin-left: 0rem; */
    text-align: left;

    ::after {
      display: block;
      content: '';
      margin: 0.5rem 0rem;
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
    }
  }

  p {
    flex: 1;
    margin: 0rem;
    font-size: 0.9rem;
    
    img{
        max-width: 250px;
        margin: 1rem auto;
      }
  }
  label {
    font-weight: 600;
  }

  
  .div__deck {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: ${window.innerHeight * 0.465}px;
    overflow-y: scroll;
    padding: 1rem 0rem;
  }
  
  .div__deck::-webkit-scrollbar {
    width: 5px;
  }

  .div__deck::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.1);        /* color of the tracking area */
  }
  .div__deck::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: var(--primary2);    /* color of the scroll thumb */
    border-radius: 1rem;       /* roundness of the scroll thumb */
    /* border: 3px solid orange;  creates padding around scroll thumb */
  }

  .div__oneRow {
    display: flex;

    .div__table_price{
      display: flex;
      justify-content: space-between;

      .div__table_sub_total{
        margin-top: 1rem;
        border-top: 1px dashed rgba(var(--black_rgb), 0.3);
        flex: 1;
        text-align: right;
        font-size: 1.25rem;
        /* background: red; */
      }
    }
  }

  input,
    select {
      outline-color: var(--primary2);
      padding: 0.75rem 0.5rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
      border-radius: 0.5rem;
      margin: 0.5rem 0.25rem;
      width: 100%;
      z-index: 0;
    }
  }

  .div__list_archive{
    display: flex;  
    
    p{
      flex: 2;
     
    }
    
  }

  .div__paymentMethodBody{
    display:flex;
    flex-direction: column;

  

    .div__methodPaymentSelect{
      display:flex;
      gap: 0.5rem;
      button{
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        flex:1;
        padding: 0.5rem;
        cursor: pointer;
        background: transparent;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        border: 0rem; 
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        color: black;

        svg{
          font-size: 2rem;
        }
        .icon_boleto{
          transform: rotate(90deg);
          margin: auto;
          font-size: 1.5rem;
        }
        .icon_boleto2{
          font-size: 1.5rem;
          margin-left: -0.38rem;
          transform: rotate(90deg);
        }

        :hover{
          color : var(--primary2);
        }
      }

      button[disabled=true]{
        pointer-events: none ;
      }
      
      .selected{
        background: var(--primary2);
        color:#fff;
        :hover{
          color: #fff;
          opacity: 0.9;
        }
      }
    }
  }

  .content__success{
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-items:center;
    height: 100%;
    padding: 2rem 1rem;

    h2{
      font-weight: 400;
    }
  }

  @media screen and (max-width: 675px) {

    .div__deck {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: initial;
    overflow-y: auto;
    padding: 1rem 0rem;
  }

    .div__oneRow {
      display: flex;
      flex-direction: column;
    }

    .div__list_archive{
      flex-direction: column;
      border-bottom: 1px dashed rgba(var(--black_rgb), 0.4);
    }
  
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: var(--border-radius);
    color: var(--primary2);
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: var(--primary2);
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;

export const CardProjects = styled.div`
  border: ${(props) =>
    props.selected
      ? "1px dashed var(--primary2)"
      : "1px dashed var(--darkGray)"};
  margin: 0rem 1rem;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.75rem;
  border-radius: 1rem;

  h4 {
    margin: 0rem;
    font-size: 0.9rem;
    color: ${(props) =>
      props.selected ? "var(--primary2)" : "var(--grayDark)"};

    ::after {
      display: none;
    }
  }
  .div__created_at {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: -0.25rem !important;
  }
  input[type="checkbox"] {
    margin: 0rem;
    background-color: ${(props) =>
      props.selected ? "var(--primary2)" : "var(--grayDark)"};
  }
  .div__oneRow {
    display: flex;
    flex-direction: row;
  }

  .div__text_line {
    font-size: 0.9rem;
  }
  .div__price_line {
    font-weight: 400;
    font-size: 1rem;
    b {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${(props) =>
        props.selected ? "var(--primary2)" : "var(--grayDark)"};
    }
  }
  .selectedIcon {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    visibility: ${(props) => (props.selected ? "visible" : "hidden")};
    color: ${(props) =>
      props.selected ? "var(--primary2)" : "var(--grayDark)"};
    svg {
      transform: scale(2);
    }
  }
  :hover {
    transform: scale(1.01);
  }
`;
