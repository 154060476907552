import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { Header, Menu } from "../../components";

import { Container } from "./styles";

export function Layout() {
  const [open, setOpen] = useState(true);

  const handleChangeMenu = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Menu open={open} handleChangeMenu={handleChangeMenu} />
      <div className='conteudo'>
        <Header open={open} />
        <Outlet />
      </div>
    </Container>
  );
}
