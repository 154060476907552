import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { mask } from "remask";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { Container } from "./styles";
import Logo1 from "../../../assets/images/logo/logo1.png";
import Logo2 from "../../../assets/images/logo/logo2.png";

import apiIBGE from "../../../services/apiIBGECidades";
import apiCNPJ from "../../../services/apiCNPJ";
import apiCEP from "../../../services/apiCEP";
import DialogConfirm from "./DialogConfirm";
import { DialogTermsOfUse } from "../../../components";
import { SITE_NAME } from "../../../config";
import { startWithUpperCase } from "../../../utils";

export default function Login() {
  const [data, setData] = useState({
    name: "",
    email: "",
    cpf: "",
    password: "",
    password_confirmation: "",

    user_address_city: "",
    user_address_complement: "",
    user_address_district: "",
    user_address_number: "",
    user_address_state: "",
    user_address_street: "",
    user_address_zip_code: "",

    company_social_reason: "",
    company_cnpj: "",
    company_fantasy_name: "",
    company_municipal_registration: "",
    company_state_registration: "",

    company_address_city: "",
    company_address_complement: "",
    company_address_district: "",
    company_address_number: "",
    company_address_state: "",
    company_address_street: "",
    company_address_zip_code: "",
  });

  const handleChangeFormData = (e) => {
    if (e.target.name === "email") {
      setData({
        ...data,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else if (e.target.name === "cpf") {
      setData({
        ...data,
        cpf: mask(e.target.value, "999.999.999-99"),
      });
    } else if (e.target.name === "user_address_zip_code") {
      setData({
        ...data,
        user_address_zip_code: mask(e.target.value, "99999-999"),
      });
    } else if (e.target.name === "company_address_zip_code") {
      setData({
        ...data,
        company_address_zip_code: mask(e.target.value, "99999-999"),
      });
    } else if (e.target.name === "company_cnpj") {
      setData({
        ...data,
        [e.target.name]: mask(e.target.value, "99.999.999/9999-99"),
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeHasCompany = () => {
    const oldValue = hasCompany;

    if (oldValue === true) {
      setData({
        ...data,
        company_social_reason: "",
        company_fantasy_name: "",
        company_cnpj: "",
        company_municipal_registration: "",
        company_state_registration: "",

        company_address_city: data.user_address_city,
        company_address_complement: data.user_address_complement,
        company_address_district: data.user_address_district,
        company_address_number: data.user_address_number,
        company_address_state: data.user_address_state,
        company_address_street: data.user_address_street,
        company_address_zip_code: data.user_address_zip_code,
      });

      setHasCompany(false);
      setFindCNPJ(false);
    } else {
      setHasCompany(true);

      // company and user will have equals addresses
      setData({
        ...data,
        company_address_city: "",
        company_address_complement: "",
        company_address_district: "",
        company_address_number: "",
        company_address_state: "",
        company_address_street: "",
        company_address_zip_code: "",
      });
    }
  };

  const [citiesList, setListcities] = useState([]);
  const [citiesCompanyList, setcitiesCompanyList] = useState([]);
  const [hasCompany, setHasCompany] = useState(true);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [findCNPJ, setFindCNPJ] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);

  const [step, setStep] = useState(1);

  const getCitiesPerson = async (e) => {
    await apiIBGE
      .get(`${data.state}/distritos`)
      .then((response) => {
        setListcities(
          response.data.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          }),
        );
      })
      .catch((error) => {
        console.error("Erro: ", error);
      });
  };

  const getCitiesCompany = async (e) => {
    await apiIBGE
      .get(`${data.state}/municipios`)
      .then((response) => {
        setcitiesCompanyList(
          response.data.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          }),
        );
      })
      .catch((error) => {
        console.error("Erro: ", error);
      });
  };

  useEffect(() => {
    if (data.user_address_state) {
      getCitiesPerson();
    }
  }, [data?.user_address_state]);

  useEffect(() => {
    if (data.company_address_state) {
      getCitiesCompany();
    }
  }, [data?.company_address_state]);

  const getCnpjData = async (e) => {
    let aux = window
      .encodeURIComponent(
        e.target.value
          ?.replace(".", "")
          ?.replace(".", "")
          ?.replace("/", "")
          ?.replace("-", ""),
      )
      .replace("%C2%AD", "");

    if (aux.length > 13) {
      await apiCNPJ
        .get(aux)
        .then((response) => {
          setData({
            ...data,
            company_fantasy_name: response.data.estabelecimento.nome_fantasia,
            company_social_reason: response.data.razao_social,

            company_address_street: response.data.estabelecimento.logradouro,
            company_address_number: response.data.estabelecimento.numero,
            company_address_district: response.data.estabelecimento.bairro,
            company_address_city: response.data.estabelecimento.cidade.nome,
            company_address_state: response.data.estabelecimento.estado.sigla,
            company_address_zip_code: mask(
              response.data.estabelecimento.cep,
              "99999-999",
            ),
            company_address_complement:
              response.data.estabelecimento.complemento,
          });

          setFindCNPJ(true);
        })
        .catch((error) => {
          console.error("Erro: ", error);
          toast.error(
            "Falha ao validar CNPJ. Mas você pode preencher os dados abaixo normalmente",
          );
          setFindCNPJ(true);
        });
    }
  };

  const validateSteps = (e) => {
    if (step === 1) {
      if (data.name) {
        if (data.cpf && data.cpf.length === 14) {
          setStep(step + 1);
        } else {
          return toast.error(
            "Preencha corretamente o seu CPF no campo indicado.",
          );
        }
      } else {
        return toast.error(
          "Preencha corretamente o seu nome no campo indicado.",
        );
      }
    }
    if (step === 2) {
      if (
        data.user_address_zip_code ||
        data.user_address_street ||
        data.user_address_number ||
        data.user_address_district ||
        data.user_address_city ||
        data.user_address_state ||
        data.user_address_complement
      ) {
        if (data.user_address_state) {
          if (data.user_address_city) {
            if (data.user_address_street) {
              if (data.user_address_number) {
                if (data.user_address_district) {
                  setStep(step + 1);
                } else {
                  return toast.error(
                    "Preencha corretamente o bairro no campo indicado.",
                  );
                }
              } else {
                return toast.error(
                  "Preencha corretamente o número no campo indicado.",
                );
              }
            } else {
              return toast.error(
                "Preencha corretamente a sua rua no campo indicado.",
              );
            }
          } else {
            return toast.error(
              "Preencha corretamente a sua cidade no campo indicado.",
            );
          }
        } else {
          return toast.error(
            "Preencha corretamente o seu estado no campo indicado.",
          );
        }
      }
      setStep(step + 1);
    }
    if (step === 3) {
      if (hasCompany) {
        if (data.company_cnpj && data.company_cnpj.length === 18) {
          if (data.company_fantasy_name) {
            if (data.company_social_reason) {
              if (data.company_address_state) {
                if (data.company_address_city) {
                  if (data.company_address_street) {
                    if (data.company_address_number) {
                      if (data.company_address_district) {
                        setStep(step + 1);
                      } else {
                        return toast.error(
                          "Preencha corretamente o bairro no campo indicado.",
                        );
                      }
                    } else {
                      return toast.error(
                        "Preencha corretamente o número no campo indicado.",
                      );
                    }
                  } else {
                    return toast.error(
                      "Preencha corretamente a rua no campo indicado.",
                    );
                  }
                } else {
                  return toast.error(
                    "Preencha corretamente a cidade no campo indicado.",
                  );
                }
              } else {
                return toast.error(
                  "Preencha corretamente o estado no campo indicado.",
                );
              }
            } else {
              return toast.error(
                "Preencha corretamente a razão social no campo indicado.",
              );
            }
          } else {
            return toast.error(
              "Preencha corretamente o nome fantasia no campo indicado.",
            );
          }
        } else {
          return toast.error("Preencha corretamente o CNPJ no campo indicado.");
        }
      } else {
        if (data.company_social_reason) {
          setStep(step + 1);
        } else {
          return toast.error("Preencha o nome da empresa corretamente.");
        }
      }
    }
    if (step === 4) {
      if (
        data.email &&
        (data.email.indexOf("@") > -1 ? true : false) &&
        (data.email.indexOf(".") > -1 ? true : false)
      ) {
        if (data.password) {
          if (data.password.length > 5) {
            if (data.password_confirmation) {
              if (data.password === data.password_confirmation) {
                setShowDialog(true);
              } else {
                return toast.error("Senhas não correspondem");
              }
            } else {
              return toast.error(
                "Confirme sua senha preenchendo corretamente o local indicado",
              );
            }
          } else {
            return toast.error("Senha deve conter no mínimo 6 caracteres");
          }
        } else {
          return toast.error(
            "Preencha corretamente sua senha no campo indicado",
          );
        }
      } else {
        return toast.error(
          "Preencha corretamente o seu e-mail no campo indicado",
        );
      }
    }
  };

  const getZipCode = async (e, type) => {
    if (e.target.value.length <= 8) {
      return;
    }

    await apiCEP
      .get(`/${e.target.value.replace(/\D/g, "")}/json`)
      .then((res) => {
        if (type === "company") {
          setData({
            ...data,
            company_address_street: startWithUpperCase(res.data.logradouro),
            company_address_district: startWithUpperCase(res.data.bairro),
            company_address_city: startWithUpperCase(res.data.localidade),
            company_address_state: res?.data?.uf?.toUpperCase(),
            company_address_complement: startWithUpperCase(
              res.data.complemento,
            ),
          });
        } else {
          setData({
            ...data,
            user_address_street: startWithUpperCase(res.data.logradouro),
            user_address_district: startWithUpperCase(res.data.bairro),
            user_address_city: startWithUpperCase(res.data.localidade),
            user_address_state: res?.data?.uf?.toUpperCase(),
            user_address_complement: startWithUpperCase(res.data.complemento),
          });
        }
      })
      .catch((error) => {
        if (type === "company") {
          setData({
            ...data,
            user_address_street: "",
            user_address_district: "",
            user_address_city: "",
            user_address_state: "",
            user_address_complement: "",
          });
        } else {
          setData({
            ...data,
            company_address_street: "",
            company_address_district: "",
            company_address_city: "",
            company_address_state: "",
            company_address_complement: "",
          });
        }
        return toast.warn("CEP inválido!");
      });
  };

  return (
    <Container>
      <div className='div__gradient'>
        <div className='div__corpo'>
          <div className='div__part1'>
            <img
              src={Logo1}
              title={`Logo ${SITE_NAME}`}
              className='img__logo_mobile'
              alt={`Logo ${SITE_NAME}`}
            />
            <h3>Seu escritório de engenharia</h3>
          </div>
          <div className='div__part2'>
            <form>
              <img
                src={Logo2}
                title={`Logo ${SITE_NAME}`}
                className='img__logo_mobile'
                alt=''
              />
              <h1>Novo Usuário</h1>
              <span>
                Preencha corretamente os campos abaixo para efetuar seu cadastro
              </span>
              <div className='div__etapas'>
                <span className={step === 1 ? "etp_atual" : "etp_pass"}>1</span>
                <span
                  className={
                    step > 2 ? "etp_pass" : step === 2 ? "etp_atual" : "etp_fut"
                  }
                >
                  2
                </span>
                <span
                  className={
                    step > 3 ? "etp_pass" : step === 3 ? "etp_atual" : "etp_fut"
                  }
                >
                  3
                </span>
                <span
                  className={
                    step > 4 ? "etp_pass" : step === 4 ? "etp_atual" : "etp_fut"
                  }
                >
                  4
                </span>
              </div>

              {step === 1 && (
                <div className='div__session1'>
                  <h4>Dados Pessoais</h4>
                  <input
                    className='input-custom-style'
                    type='text'
                    placeholder='Nome completo*'
                    autoFocus
                    name='name'
                    value={data.name}
                    onChange={handleChangeFormData}
                  />
                  <input
                    type='text'
                    className='input-custom-style'
                    placeholder='CPF*'
                    name='cpf'
                    value={data.cpf}
                    onChange={handleChangeFormData}
                  />

                  <div className='div__navigators' style={{ margin: "auto" }}>
                    <button
                      className={
                        step === 1
                          ? "secondary-button-style btn__disable"
                          : "secondary-button-style"
                      }
                      type='button'
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        step === 4
                          ? "primary-button-style btn__avancar btn__disable"
                          : "primary-button-style btn__avancar"
                      }
                      onClick={() => validateSteps()}
                      type='button'
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className='div__session1'>
                  <h4>Endereço</h4>

                  <input
                    required
                    className='input-custom-style'
                    type='text'
                    placeholder='CEP*'
                    name='user_address_zip_code'
                    value={data.user_address_zip_code}
                    onChange={handleChangeFormData}
                    onKeyUp={(e) => getZipCode(e, "person")}
                  />

                  <select
                    className='input-custom-style'
                    required
                    name='user_address_state'
                    style={{ flex: 1 }}
                    value={data.user_address_state}
                    onChange={(e) => {
                      handleChangeFormData(e);
                    }}
                    autoFocus
                  >
                    <option value='' disabled selected>
                      Estado*
                    </option>
                    <option value='AC'>AC</option>
                    <option value='AL'>AL</option>
                    <option value='AP'>AP</option>
                    <option value='AM'>AM</option>
                    <option value='BA'>BA</option>
                    <option value='CE'>CE</option>
                    <option value='DF'>DF</option>
                    <option value='ES'>ES</option>
                    <option value='GO'>GO</option>
                    <option value='MA'>MA</option>
                    <option value='MT'>MT</option>
                    <option value='MS'>MS</option>
                    <option value='MG'>MG</option>
                    <option value='PA'>PA</option>
                    <option value='PB'>PB</option>
                    <option value='PR'>PR</option>
                    <option value='PE'>PE</option>
                    <option value='PI'>PI</option>
                    <option value='RJ'>RJ</option>
                    <option value='RN'>RN</option>
                    <option value='RS'>RS</option>
                    <option value='RO'>RO</option>
                    <option value='RR'>RR</option>
                    <option value='SC'>SC</option>
                    <option value='SP'>SP</option>
                    <option value='SE'>SE</option>
                    <option value='TO'>TO</option>
                  </select>

                  <select
                    className='input-custom-style'
                    required
                    name='city'
                    style={{ flex: 1 }}
                    value={data.user_address_city}
                    onChange={handleChangeFormData}
                  >
                    <option value='' disabled selected>
                      Cidade*
                    </option>
                    {data.user_address_city && (
                      <option value={data.user_address_city} disabled>
                        {data.user_address_city}
                      </option>
                    )}
                    {citiesList.map((city, idx) => (
                      <option value={city.nome} key={idx}>
                        {city.nome}
                      </option>
                    ))}
                  </select>

                  <input
                    type='text'
                    className='input-custom-style'
                    placeholder='Rua*'
                    name='user_address_street'
                    onChange={handleChangeFormData}
                    value={data.user_address_street}
                  />
                  <input
                    className='input-custom-style'
                    type='text'
                    placeholder='Número*'
                    name='user_address_number'
                    value={data.user_address_number}
                    onChange={handleChangeFormData}
                  />

                  <input
                    type='text'
                    className='input-custom-style'
                    placeholder='Bairro*'
                    name='user_address_district'
                    value={data.user_address_district}
                    onChange={handleChangeFormData}
                  />
                  <input
                    type='text'
                    className='input-custom-style'
                    placeholder='Complemento (opcional)'
                    name='user_address_complement'
                    value={data.user_address_complement}
                    onChange={handleChangeFormData}
                  />

                  <div className='div__navigators' style={{ margin: "auto" }}>
                    <button
                      className={
                        step === 1
                          ? "secondary-button-style btn__disable"
                          : "secondary-button-style"
                      }
                      type='button'
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        step === 4
                          ? "primary-button-style btn__avancar btn__disable"
                          : "primary-button-style btn__avancar"
                      }
                      onClick={() => validateSteps()}
                      type='button'
                    >
                      {data.user_address_zip_code ||
                      data.user_address_street ||
                      data.user_address_number ||
                      data.user_address_district ||
                      data.user_address_city ||
                      data.user_address_state ||
                      data.user_address_complement
                        ? "Avançar"
                        : "Pular"}
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className='div__session1'>
                  <h4>Dados da Empresa</h4>
                  <label>
                    <input
                      type='checkbox'
                      checked={!hasCompany}
                      onChange={handleChangeHasCompany}
                    />{" "}
                    Não tenho CNPJ
                  </label>
                  {hasCompany ? (
                    <>
                      <input
                        className='input-custom-style'
                        type='text'
                        placeholder='CNPJ*'
                        autoFocus
                        name='company_cnpj'
                        value={data.company_cnpj}
                        onChange={handleChangeFormData}
                        onKeyUp={getCnpjData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Nome Fantasia*'
                        name='company_fantasy_name'
                        value={data.company_fantasy_name}
                        onChange={handleChangeFormData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Razão Social*'
                        name='company_social_reason'
                        value={data.company_social_reason}
                        onChange={handleChangeFormData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Registro Municipal (opcional)'
                        name='company_municipal_registration'
                        value={data.company_municipal_registration}
                        onChange={handleChangeFormData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Registro Estadual (opcional)'
                        name='company_state_registration'
                        value={data.company_state_registration}
                        onChange={handleChangeFormData}
                      />

                      <input
                        type='text'
                        className='input-custom-style'
                        placeholder='CEP*'
                        name='company_address_zip_code'
                        disabled={!findCNPJ}
                        value={data.company_address_zip_code}
                        onChange={handleChangeFormData}
                        onKeyUp={(e) => getZipCode(e, "company")}
                      />

                      <select
                        className='input-custom-style'
                        required
                        name='company_address_state'
                        style={{ flex: 1 }}
                        value={data.company_address_state}
                        onChange={handleChangeFormData}
                        disabled={!findCNPJ}
                      >
                        <option value='' disabled selected>
                          Estado*
                        </option>
                        <option value='AC'>AC</option>
                        <option value='AL'>AL</option>
                        <option value='AP'>AP</option>
                        <option value='AM'>AM</option>
                        <option value='BA'>BA</option>
                        <option value='CE'>CE</option>
                        <option value='DF'>DF</option>
                        <option value='ES'>ES</option>
                        <option value='GO'>GO</option>
                        <option value='MA'>MA</option>
                        <option value='MT'>MT</option>
                        <option value='MS'>MS</option>
                        <option value='MG'>MG</option>
                        <option value='PA'>PA</option>
                        <option value='PB'>PB</option>
                        <option value='PR'>PR</option>
                        <option value='PE'>PE</option>
                        <option value='PI'>PI</option>
                        <option value='RJ'>RJ</option>
                        <option value='RN'>RN</option>
                        <option value='RS'>RS</option>
                        <option value='RO'>RO</option>
                        <option value='RR'>RR</option>
                        <option value='SC'>SC</option>
                        <option value='SP'>SP</option>
                        <option value='SE'>SE</option>
                        <option value='TO'>TO</option>
                      </select>
                      <select
                        className='input-custom-style'
                        required
                        name='company_address_city'
                        disabled={!findCNPJ}
                        style={{ flex: 1 }}
                        value={data.company_address_city}
                        onChange={handleChangeFormData}
                      >
                        <option value='' disabled selected>
                          Cidade*
                        </option>
                        {data.company_address_city && (
                          <option value={data.company_address_city} disabled>
                            {data?.city?.toUpperCase()}
                          </option>
                        )}
                        {citiesCompanyList.map((city, idx) => (
                          <option value={city.nome} key={idx}>
                            {city.nome}
                          </option>
                        ))}
                      </select>

                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Rua*'
                        name='company_address_street'
                        value={data.company_address_street}
                        onChange={handleChangeFormData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Número*'
                        name='company_address_number'
                        value={data.company_address_number}
                        onChange={handleChangeFormData}
                      />
                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Bairro*'
                        name='company_address_district'
                        value={data.company_address_district}
                        onChange={handleChangeFormData}
                      />

                      <input
                        type='text'
                        className='input-custom-style'
                        disabled={!findCNPJ}
                        placeholder='Complemento (opcional)'
                        name='company_address_complement'
                        value={data.company_address_complement}
                        onChange={handleChangeFormData}
                      />
                      {/* <input
                        type="text"
                        disabled={!findCNPJ}
                        placeholder="Cargo na empresa*"
                        name="role"
                        value={personData.role}
                        onChange={handleChangeFormData}
                      /> */}
                    </>
                  ) : (
                    <>
                      <input
                        type='text'
                        placeholder='Nome da empresa'
                        name='company_social_reason'
                        className='input-custom-style'
                        value={data.company_social_reason}
                        onChange={handleChangeFormData}
                      />{" "}
                      {/* <input
                        type="text"
                        placeholder="Cargo na empresa*"
                        name="role"
                        value={data.role}
                        onChange={handleChangeFormData}
                      /> */}
                    </>
                  )}

                  <div className='div__navigators' style={{ margin: "auto" }}>
                    <button
                      className={
                        step === 1
                          ? "secondary-button-style  btn__disable"
                          : "secondary-button-style "
                      }
                      type='button'
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        step === 4
                          ? "primary-button-style btn__avancar btn__disable"
                          : "primary-button-style btn__avancar"
                      }
                      onClick={() => validateSteps()}
                      type='button'
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              )}

              {step === 4 && (
                <div className='div__session1'>
                  <h4>Informações de acesso</h4>

                  <input
                    className='input-custom-style'
                    type='email'
                    placeholder='E-mail*'
                    autoFocus
                    name='email'
                    value={data.email}
                    onChange={handleChangeFormData}
                  />

                  <div className='div__one_row'>
                    <input
                      className='input-custom-style'
                      autoFocus
                      type={show ? "text" : "password"}
                      placeholder='Senha*'
                      name='password'
                      value={data.password}
                      onChange={handleChangeFormData}
                    />{" "}
                    <button onClick={() => setShow(!show)} type='button'>
                      {!show ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </button>
                  </div>

                  <input
                    type='password'
                    placeholder='Confirmar senha*'
                    className='input-custom-style'
                    name='password_confirmation'
                    value={data.password_confirmation}
                    onChange={handleChangeFormData}
                  />

                  <ul>
                    <li>Senha deve conter no mínimo 6 caracteres;</li>
                    <li>
                      Para uma senha forte, é aconselhável que contenha números,
                      letras e caracteres especiais;
                    </li>
                    <li>
                      Sua senha e credenciais de acesso são de uso pessoal,
                      evite informar a terceiros.
                    </li>
                  </ul>

                  <div style={{ display: "flex", fontSize: "0.8rem" }}>
                    <input
                      className='input-custom-style'
                      id='terms'
                      name='terms'
                      type='checkbox'
                      checked={confirmTerms}
                      onChange={() => {
                        setConfirmTerms((confirm) => !confirm);
                      }}
                      style={{
                        width: "1.2rem",
                        margin: 0,
                        marginLeft: "2rem",
                      }}
                    />
                    <span
                      htmlFor='terms'
                      style={{
                        marginBottom: "0",
                        paddingLeft: "1rem",
                        textAlign: "left",
                      }}
                    >
                      DECLARO QUE LI E CONCORDO COM AS{" "}
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowTermsOfUse(true)}
                      >
                        POLÍTICAS DE PRIVACIDADE
                      </a>
                    </span>
                  </div>

                  <div className='div__navigators' style={{ margin: "auto" }}>
                    <button
                      className={
                        step === 1
                          ? "secondary-button-style btn__disable"
                          : "secondary-button-style"
                      }
                      type='button'
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        step === 4 && confirmTerms
                          ? "primary-button-style  btn__avancar"
                          : "primary-button-style  btn__avancar btn__disable"
                      }
                      onClick={() => validateSteps()}
                      type='button'
                      // disabled={!confirmTerms}
                    >
                      Finalizar
                    </button>
                  </div>
                </div>
              )}

              <div className='div__line' />
              <div className='div__subtext'>
                <label>Já possui conta? Realize login</label>
              </div>
              <Link to='/login' style={{ width: "100%" }}>
                <button className='secondary-button-style ' type='button'>
                  Login
                </button>
              </Link>
            </form>
          </div>
        </div>
      </div>
      {showDialog && (
        <DialogConfirm
          data={data}
          handleCloseDialog={() => setShowDialog(false)}
        />
      )}

      {showTermsOfUse && <DialogTermsOfUse fechar={() => setShowTermsOfUse(false)} />}
    </Container>
  );
}
