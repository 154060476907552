import React, { useState } from "react";
import { toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import { generatePrice } from '../../../utils/generate-price';
import Backdrop from "../../Backdrop";
import Money01 from "../../../assets/images/prints/solar-image.png";
import { Container, FielButtons } from "./styles";

export default function DialogProjectConfirmation(props) {
  const [open, setOpen] = React.useState(true);
  const [dados] = React.useState(props.dados);
  const [showBackdrop] = useState(false);

  const [credits] = React.useState({
    value: props.credits ? props.credits : 0,
    show: props.credits ? false : true,
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const getValuePrice = (power) => {
    const price = generatePrice(power);
    if (!price) {
      return toast.warn(
        `Valor "${power}" não tabelado. Entre em contato.`
      );
    }

    return !!price ? price : 0;
  };

  const currency = function (number) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        scroll='body'
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999,
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Container>
              <header>
                <h1>Confirmar dados de projeto</h1>
                <span>
                  Verifique atentamente as informações abaixo e confirme os
                  dados de projeto.
                </span>
                <content>
                  <h4>Informações da Concessionária</h4>
                  <div className='div__oneRow'>
                    <p>
                      <label>Estado: </label> {dados.state}
                    </p>
                    <p>
                      <label>Concessionária: </label> {dados.concessionaria}
                    </p>
                  </div>

                  <h4>Informações do Cliente</h4>
                  <div className='div__oneRow'>
                    <p>
                      <label>{dados.cpf ? "CPF" : "CNPJ"}:</label>{" "}
                      {dados.cpf ? dados.cpf : dados.cnpj}
                    </p>
                    <p>
                      <label>{dados.name ? "Nome" : "Razão Social"}:</label>{" "}
                      {dados.name ? dados.name : dados.social_reason}
                    </p>
                  </div>
                  <h4>Informações da Instalação - Unidade Geradora</h4>
                  <div className='div__oneRow'>
                    <p>
                      <label>Tipo de disjuntor:</label> {dados.breaker_type}
                    </p>
                    <p>
                      <label>Valor do disjuntor:</label> {dados.breaker_value}
                    </p>
                  </div>
                  <div className='div__oneRow'>
                    <p>
                      <label>Latitude:</label>{" "}
                      {("" + dados.latitude).slice(0, 12)}
                    </p>
                    <p>
                      <label>Longitude:</label>{" "}
                      {("" + dados.longitude).slice(0, 12)}
                    </p>
                  </div>
                  <div className='div__oneRow'>
                    <p>
                      <label>Troca de titularidade:</label>{" "}
                      {dados?.exchange_ownership === "false" ? "Não" : "Sim"}
                    </p>
                  </div>
                  <h4>Especificações do Projeto</h4>
                  <div className='div__oneRow'>
                    <p>
                      <label>Pot. total do projeto:</label>{" "}
                      {dados.total_project_power} kWp
                    </p>
                    <p>
                      <label>Nível de Tensão:</label> {dados?.voltageLevel}{" "}
                    </p>
                    <p>
                      <label>Lista de materiais:</label>{" "}
                      {dados.has_order_material === "true" ? "Sim" : "Não"}
                    </p>
                  </div>

                  {dados.amount_panels && dados.panel_power && (
                    <div className='div__oneRow'>
                      <p>
                        <label>Qtd. de Placas:</label> {dados.amount_panels}{" "}
                        unid.
                      </p>
                      <p>
                        <label>Potência:</label> {dados.panel_power} Wp
                      </p>
                      <p>
                        <label>Modelo:</label> {dados.panel_model}
                      </p>
                    </div>
                  )}
                  {props.listinverters.map((invert) => (
                    <div className='div__oneRow'>
                      <p>
                        <label>Qtd. de Inversores:</label> {invert.amount} unid.
                      </p>
                      <p>
                        <label>Potência:</label> {invert.power_kwp} kWp
                      </p>
                      <p>
                        <label>Modelo:</label> {invert.model}
                      </p>
                    </div>
                  ))}

                  <div className='div__oneRow'></div>

                  <h4>Documentos</h4>

                  {dados.document_archive && (
                    <div className='div__list_archive'>
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Documento com foto
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_archive.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_archive.type}
                      </p>
                    </div>
                  )}

                  {dados.social_contract && (
                    <div className='div__list_archive'>
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Contrato social
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.social_contract.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.social_contract.type}
                      </p>
                    </div>
                  )}

                  {dados.document_energy && (
                    <div className='div__list_archive'>
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Documento de energia
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_energy.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_energy.type}
                      </p>
                    </div>
                  )}

                  {dados.document_procuration && (
                    <div className='div__list_archive'>
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Procuração para troca de titularidade
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_procuration.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_procuration.type}
                      </p>
                    </div>
                  )}

                  {dados.document_material && (
                    <div className='div__list_archive'>
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Pedido dos materiais
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_material.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_material.type}
                      </p>
                    </div>
                  )}

                  {props.listRateios.length > 0 &&
                    props.listRateios.map((rateio, idx) => (
                      <div className='div__list_archive'>
                        <p style={{ flex: 2 }}>
                          <label>- Documento:</label> <br />
                          Documento de rateio {idx + 1}
                        </p>
                        <p>
                          <label>Nome:</label> <br />
                          {rateio.document_rateio.name}
                        </p>
                        <p style={{ flex: 1 }}>
                          <label>Tipo:</label> <br />
                          {rateio.document_rateio.type}
                        </p>
                      </div>
                    ))}

                  {props?.listAdditional?.length > 0 &&
                    props.listAdditional.map((additional, idx) => (
                      <div className='div__list_archive'>
                        <p style={{ flex: 2 }}>
                          <label>- Documento:</label> <br />
                          Adicional {idx + 1} : {additional.type} <br />{" "}
                          {additional.other_type &&
                            `(${additional.other_type})`}
                        </p>
                        <p>
                          <label>Nome:</label> <br />
                          {additional.document_additional.name}
                        </p>
                        <p style={{ flex: 1 }}>
                          <label>Tipo:</label> <br />
                          {additional.document_additional.type}
                        </p>
                      </div>
                    ))}

                  <h4>Orçamento</h4>
                  <div className='div__oneRow'>
                    <div style={{ flex: 1, flexDirection: "column" }}>
                      <div className='div__table_price'>
                        <b>Item</b>
                        <b>Preço</b>
                      </div>
                      <div className='div__table_price'>
                        <span>
                          - Projeto fotovoltáico {dados.total_project_power} kWp
                        </span>
                        <span>
                          {currency(
                            getValuePrice(props.dados.total_project_power),
                          )}
                        </span>
                      </div>

                      <div className='div__table_price'>
                        <div className='div__table_sub_total'>
                          <b>Total:</b>
                          {currency(
                            getValuePrice(props.dados.total_project_power) -
                              (credits.show === false
                                ? parseFloat(credits.value)
                                : 0) -
                                (getValuePrice(
                                  props.dados.total_project_power,
                                ) -
                                  (credits.show === false
                                    ? parseFloat(credits.value)
                                    : 0)),
                          )}
                        </div>
                      </div>
                    </div>
                    <p style={{ display: "flex", flexDirection: "column" }}>
                      <img src={Money01} alt='Money' />
                      <p
                        style={{
                          margin:
                            window.innerWidth > 675 ? "0rem 1rem" : "0rem",
                        }}
                      >
                        Invista em sustentabilidade e economia com energia
                        solar! Aproveite nosso projeto completo e garanta sua
                        transição para uma fonte renovável de energia.
                      </p>
                    </p>
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className='secondary-button-style'
              color='primary'
            >
              Cancelar
            </button>
            <button
              onClick={() => props.confirmar()}
              className='primary-button-style'
            >
              <AddShoppingCartIcon /> Adicionar ao carrinho
            </button>
          </FielButtons>
        </DialogActions>
        {showBackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
